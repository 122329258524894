<template>
  <div>
    <h2 class="view-header">Worked Shifts Report</h2>
    <div class="content-view">
      <div class="content-view-block">
        <h3 class="content-subheader">Date Range</h3>
        <p>
          <b>Date From</b>
          <a-date-picker
            v-model="dateFrom"
            format="MM/DD/YYYY"
            placeholder="Date"
            :disabled-date="disabledStartDate"
          />
        </p>
        <p>
          <b>Date To</b>
          <a-date-picker
            v-model="dateTo"
            format="MM/DD/YYYY"
            placeholder="Date"
            :disabled-date="disabledEndDate"
          />
        </p>

        <h3 class="content-subheader">Personal Info</h3>
          <p>
            <b>Job Classification</b>
            <a-select v-model="selectedRole" class="role-select" placeholder="Job Classification">
              <a-select-option v-for="role in roles" :key="role.id" :value="role.id">
                {{ role.name }}
              </a-select-option>
            </a-select>
          </p>

          <p>
            <b>Status</b>
            <a-radio-group v-model="selectedStatus">
              <a-radio :value="activityStatusType.ALL">All</a-radio>
              <a-radio :value="activityStatusType.ACTIVE">Active</a-radio>
              <a-radio :value="activityStatusType.INACTIVE">Inactive</a-radio>
            </a-radio-group>
          </p>

          <p class="mt-4">
            <a-button
              type="primary"
              :disabled="downloadDisabled"
              :loading="loading"
              @click="downloadReport"
            >Download Report</a-button>
          </p>
      </div>
    </div>
  </div>
</template>
<script>
import {DatePicker, Select, Radio, notification, Button} from 'ant-design-vue';
import api from "@/api";

export default {
  data() {
    return {
      dateFrom: undefined,
      dateTo: undefined,
      selectedRole: undefined,
      selectedStatus: 0,
      loading: false,

      groupByValue: 0,
      filterValue: 0,

      activityStatusType: {
        ALL: 0,
        ACTIVE: 1,
        INACTIVE: 2
      },
    }
  },
  mixins: [api],
  components: {
    'a-date-picker': DatePicker,

    'a-select': Select,
    'a-select-option': Select.Option,
    'a-radio': Radio,
    'a-radio-group': Radio.Group,
    'a-button': Button
  },
  computed: {
    roles() {
      return this.$store.state.applicationState.projectRoles;
    },
    serverDateFormat() {
      return this.$store.state.applicationState.serverDateFormat;
    },

    serverDateFrom() {
      if (!this.dateFrom) {
        return null;
      }

      return this.dateFrom.format(this.serverDateFormat);
    },
    serverDateTo() {
      if (!this.dateTo) {
        return null;
      }

      return this.dateTo.format(this.serverDateFormat);
    },

    downloadDisabled() {
      return !this.dateFrom || !this.dateTo;
    }
  },
  methods: {
    disabledStartDate(startValue) {
      const endValue = this.dateTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.dateFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    downloadReport() {
      this.loading = true;
      this.apiDownloadWorkedShiftsReport(
        this.serverDateFrom,
        this.serverDateTo,
        this.selectedRole,
        this.selectedStatus
      )
        .then((response) => {
          if (response.body.error_code !== 0 || !response.body.report_url) {
            notification['warning']({
              message: 'Worked Shifts Report Load Error',
              description: response.body.msg ?? 'Try again later'
            })
            return;
          }

          window.open(response.body.report_url, '_blank');
        })
        .catch(() => {
          notification['warning']({
            message: 'Worked Shifts Report Load Error',
            description: 'Try again later'
          })
        })
        .finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>
.role-select {
  width: 150px;
}
</style>
