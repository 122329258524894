<template>
	<transition name="modal"  v-if="isVisible" ref="popup"  @close="_cancel()">
		<div class="modal-mask">
          <div class="modal-wrapper" >
			<div class="modal-container" style="width:460px;">
			  <div>
                <h2>Select employee</h2>
			  </div>

			  <div class="modal-body" style="height: 150px; overflow: auto">
                    <table id="table" >
                        <thead>
                            <tr>
                                <th>Associate #</th>
                                <th>Last Name</th>
                                <th>First Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in users" :key="user.id" @click="selectRow(user)" :class="{'highlight': (user == selectedUser)}">
                                <td>{{user.employee_id}}</td>
                                <td>{{user.last_name}}</td>
                                <td>{{user.first_name}}</td>
                            </tr>
                        </tbody>
                    </table>
			  </div>

			  <div class="modal-footer">
                <button class="ui button" @click="_confirm()">{{this.customOkButton ? this.customOkButton : 'Ok'}}</button>
                <button class="ui button" @click="_cancel()">{{this.customCancelButton ? this.customCancelButton: 'Cancel'}}</button>
			  </div>
			</div>
		  </div>
		</div>
	  </transition>
</template>



<script>

    export default {

        data: function() {
            return {
              isVisible: false,
              resolvePromise: undefined,
              rejectPromise: undefined,
              customClose: false,
              customOkButton: undefined,
              customCancelButton: undefined,
              selectedUser: null,
              users: null
            };
        },
        methods: {
          show(opts = {}) {
            this.customOkButton = opts.okText
            this.customCancelButton = opts.cancelText
            this.isVisible = true;

            this.users = opts.data.associate_list
			if (this.users !== undefined)
				this.users.sort((a, b) => a.last_name.toLowerCase() > b.last_name.toLowerCase() ? 1 : -1);

            return new Promise((resolve, reject) => {
              this.resolvePromise = resolve
              this.rejectPromise = reject
            })
          },

          _confirm() {
            this.isVisible = false;
            this.customOkButton = undefined
            this.customCancelButton = undefined
            this.resolvePromise(this.selectedUser)
          },

          _cancel() {
            this.isVisible = false;
            this.customOkButton = undefined;
            this.customCancelButton = undefined;
            this.resolvePromise(null)
          },

        selectRow(user){
            this.selectedUser = user;
            //Do other things
        }


        }
    }
</script>

<style>
	.modal-mask {
	  position: fixed;
	  z-index: 9998;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: rgba(0, 0, 0, .5);
	  display: table;
	  transition: opacity .3s ease;
	}

	.modal-mask.scrolable .modal-container {
		overflow-y: auto;
	}

	.modal-header {
		padding: 0;
		border: 0;
	}

	.modal-wrapper {
	  display: table-cell;
	  vertical-align: middle;
      overflow-y: initial !important;
	}

	.modal-container {
      max-height: 80vh;
      overflow-y: auto;
	  margin: 0px auto;
	  padding: 2px 2px;
	  background-color: #fff;
	  border-radius: 4px;
	  box-shadow: 0px 10px 20px 0px rgba(21, 24, 31, 0.2);
	  transition: all .3s ease;
	}

	.modal-header h3 {
	  margin-top: 0;
	  color: #222;
	  font-size: 20px;
	}

	.modal-body {
	  margin: 2px 0;
	  padding: 0;
	}

	.modal-default-button {
	  float: right;
	}

	.modal-footer {
		border: 0;
		padding: 0;
		text-align: left;
	}

	.modal-enter {
	  opacity: 0;
	}

	.modal-leave-active {
	  opacity: 0;
	}

	.modal-enter .modal-container,
	.modal-leave-active .modal-container {
	  -webkit-transform: scale(1.1);
	  transform: scale(1.1);
	}

	.modal-container .close {
		color: #cbcbcb;
		font-size: 26px;
		font-weight: 100;
	}

	.modal-container .close:hover {
		opacity: 0.8;
		color: #cbcbcb;
	}

	.modal-container .close:focus {
		outline: none;
	}

	.modal-container i.remove.icon {
		float: right;
		display: block;
		font-size: 20px;
		color: #b5b5b5;
		font-weight: 100;
		margin: -23px -35px 0 0;
		cursor: pointer;
	}

    .table {
  		display:block;
        height: calc(100vh - 118px);
        overflow-y: scroll;
        /* overflow-x: hidden; */
    }

    .highlight {
     background-color: #98e93b;
    }
    tr:hover{
        cursor: pointer;
    }


</style>
