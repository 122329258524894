<template>
  <transition name="modal" v-if="isVisible" ref="popup" @close="_close()">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" style="width: 50vw">
          <div>
            <h2>{{ getTitle() }}</h2>
          </div>

          <div class="modal-body" style="overflow: auto">
            <table id="table">
              <thead>
              <tr v-if="listType == 'not_in_wa'">
                <th>ID</th>
                <th>Name</th>
                <th>Address</th>
                <th>Radius</th>
              </tr>
              <tr v-else-if="listType == 'time_card'">
                <th>Date</th>
                <th>Address</th>
                <th>Action</th>
                <th>In Work Area</th>
              </tr>
              </thead>
              <tbody>
              <template v-if="listType == 'not_in_wa'">
                <tr v-for="location in locList" :key="location.id">
                  <td>{{ location.id }}</td>
                  <td>{{ location.name }}</td>
                  <td>{{ location.address }}</td>
                  <td>{{ location.radius }}</td>
                </tr>
              </template>
              <template v-else-if="listType == 'time_card'">
                <tr v-for="location in locList" :key="location.date">
                  <td>{{ getFormattedDateTime(location.date) }}</td>
                  <td>{{ location.address }}</td>
                  <td>{{ location.action }}</td>
                  <td>
                    <a-icon v-if="location.in_wa" type="check-circle"/>
                    <a-icon v-else type="close-circle"/>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>

          <div class="modal-footer">
            <button class="ui button" @click="_close()">OK</button>
            <download-excel
              :title="getTitle()"
              class="ui button"
              :data="locList"
              :fields="locFields"
              name="locations.xls"
            >
              <a-icon type="file-excel"/>
            </download-excel>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
import {Icon} from "ant-design-vue";
import Util from '@/util';
import JsonExcel from 'vue-json-excel';

export default {
  components: {
    'a-icon': Icon,
    'download-excel': JsonExcel
  },
  data: function () {
    return {
      isVisible: false,
      resolvePromise: null,
      rejectPromise: null,
      shiftId: null,
      locList: null,
      listType: null,
      locFields: {
        'Date': {
          field: 'date',
          callback: (val) => {
            return this.getFormattedDateTime(val);
          }
        },
        'Address': 'address',
        'Action': 'action',
        'In Work Area': {
          field: 'in_wa',
          callback: (val) => {
            return val === true ? 'Yes' : 'No';
          }
        }
      }
    };
  },
  methods: {
    show(opts = {}) {
      this.isVisible = true;

      this.locList = opts.loc_list.filter(loc => loc.address != null && loc.address.length > 0);

      if (this.locList.length > 0 && this.locList[0].shift_id) {
        this.shiftId = this.locList[0].shift_id;
      }

      this.listType = opts.list_type

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    _close() {
      this.isVisible = false;
      this.resolvePromise(null)
    },

    getFormattedDateTime(date) {
      return Util.convertDateTimeToCurrentTimeZoneWithFormat(date, 'MMMM Do YYYY, HH:mm');
    },

    getTitle() {
      return this.shiftId ? 'Shift #' + this.shiftId + ' Locations' : 'Locations'
    }
  }
}
</script>

<style>
#table {
  width: 100%;
}
</style>
