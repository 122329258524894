<template>
  <div>
    <h2 class="view-header">Associate Profiles</h2>
    <v-tour name="profilesTour" :steps="tourSteps" :callbacks="callbacks" :options="vueTourOptions"/>
    <a-modal
      title="Associate Profile"
      width="1250px"
      :footer="null"
      v-model="associateProfileModalVisible"
      @ok="endEditAssociateProfile"
      @cancel="endEditAssociateProfile"
    >
      <div class="associate-profile-modal-wrapper">
        <associate-profile
          :key="associateProfileModalKey"
          :associate-number="editingAssociateNumber"
          @edited="handleEdited"
          @clone="openCloneAssociate"
        />
      </div>
    </a-modal>
    <a-modal
      v-model="cloneAssociateVisible"
      title="Clone Associate"
      width="1000px"
      :footer="null"
    >
      <clone-associate
        :key="cloneAssociateModalKey"
        :associate-number="cloningAssociateNumber"
        @close="endCloneAssociate"
      />

    </a-modal>
    <send-notification :associate="associateProfileToSendSms" @close="closeSmsModal"/>
    <add-note :associate="associateProfileToAddNote" @close="closeNoteModal"/>

    <div class="table_controls_and_data">
      <div class="table_data_wrapper"></div>
      <div class="table_controls_wrapper">
        <div class="table_params">
        </div>
        <div class="table_predefined_filters">
          <a-checkbox v-model="isVerified" :disabled="loading">Verified</a-checkbox>
          <a-checkbox v-model="isActive" :disabled="loading">Active</a-checkbox>
          <a-button @click="openNewUserWithAssociateView" :disabled="loading">New</a-button>
          <a-select
            ref="userSelect"
            show-search
            :allowClear="true"
            placeholder="Associate"
            style="min-width: 180px"
            :filter-option="false"
            :not-found-content="null"
            :dropdown-match-select-width="false"
            :disabled="loading"
            :options="foundAssociates"
            @search="fetchAssociates"
            @change="handleSearchAssociatesChange"
          />
          <a-select
            default-value="0"
            v-model="selectedRole"
            class="role-select"
            placeholder="Job classification"
            :disabled="loading"
          >
            <a-select-option :key="0" :value="0">
              All Job Classifications
            </a-select-option>
            <a-select-option v-for="role in roles" :key="role.id" :value="role.id">
              {{ role.name }}
            </a-select-option>
          </a-select>
          <a-select default-value="0" v-model="selectedScheduleType" placeholder="Schedule Type" :disabled="loading">
            <a-select-option :key="0" :value="0">
              All Schedule Types
            </a-select-option>
            <a-select-option v-for="type in scheduleTypes" :key="type.id" :value="type.id">
              {{ type.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>
    <a-table
      rowKey="id"
      size="small"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <span slot="associate" slot-scope="text, record" :key="record.photo_url">
        <grouped-associate-info
          :first-name="record.first_name"
          :last-name="record.last_name"
          :photo-url="record.photo_url"
        />
      </span>
      <span slot="isActive" slot-scope="text, record">
        <a-popover v-if="record.suspension" title="Suspension">
          <template slot="content">
              <p>
                <b>Author: </b>
                <span>
                  {{ combineFullName(record.suspension.author.first_name, record.suspension.author.last_name) }}
                </span>
              </p>
              <p>
                <b>Reason</b>
                <span>
                  {{ record.suspension.reason }}
                </span>
              </p>
              <p>
                <b>Duration: </b>
                <span v-if="record.suspension.indefinite_period">
                  Indefinite
                </span>
                <span v-else>
                  {{ record.suspension.days_amount }}
                </span>
              </p>
              <p v-if="!record.suspension.indefinite_period">
                <b>Days left: </b>
                <span>
                  {{ record.suspension.remaining_days }}
                </span>
              </p>
          </template>
          <a-tag :color="getTagColor(record)">{{ getAssociateStatus(record) }}</a-tag>
        </a-popover>
        <a-tag v-else :color="getTagColor(record)">{{ getAssociateStatus(record) }}</a-tag>
      </span>
      <span slot="verified" slot-scope="text, record">
        <a-tag :color="getVerifiedTagColor(record)">{{ record.is_verified ? 'YES' : 'NO' }}</a-tag>
      </span>
      <span slot="scheduleType" slot-scope="text, record">
        {{ renderScheduleType(record.schedule_type) }}
      </span>
      <span slot="payStatus" slot-scope="text, record">
        {{ renderPayStatus(record.payment_info.status) }}
      </span>
      <span slot="rate" slot-scope="text, record">
        {{ renderRate(record.payment_info.rate) }}
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-button-group size="small">
          <a-button
            id="edit-associate-profile"
            icon="edit"
            @click="startEditAssociateProfile(record.associate_number)"
          />
          <a-button
            id="associate-shifts-btn"
            icon="profile"
            :disabled="!record.associate_shifts_exists"
            @click="openAssociateShiftsList(record.associate_number)"
          />
          <a-button
            id="send-message-btn"
            icon="message"
            :disabled="!record.phone"
            @click="openSmsSendModal(record)"
          />
          <a-button
            id="note-btn"
            icon="snippets"
            @click="openNoteModal(record)"
          />
          <a-button
            id="active-btn"
            :icon="record.is_active ? 'lock' : 'unlock'"
            @click="setActiveOrTerminate(record)"
          />
        </a-button-group>
      </span>
    </a-table>
  </div>
</template>

<script>
import {Table, Icon, Button, Modal, Checkbox, Select, Tag, Popover, notification} from "ant-design-vue";
import api from "@/api";
import Util from "@/util";

import store from "@/store";
import AssociateProfile from "@/components/associate-profile.vue";
import SendNotification from "@/components/send-notification.vue";
import AddNote from "@/components/add-note.vue";
import CloneAssociate from "@/components/clone-associate.vue";
import router from "@/router";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import {vueTourOptions} from "@/const";

const ScheduleType = {
  FIXED: 'fixed',
  SELF_SCHEDULE: 'self_schedule',
  FREE: 'free'
}

const PayStatus = Object.freeze({
  FULL_TIME: 'full_time',
  PART_TIME: 'part_time',
  ON_CALL: 'on_call',
  TERMINATED: 'terminated',
})

const StatusTagColors = Object.freeze({
  GRAY: '#b4b4b4',
  GREEN: '#34db30',
  RED: '#ff4646'
});

export default {
  components: {
    'a-table': Table,
    'a-icon': Icon,

    'a-button': Button,
    'a-button-group': Button.Group,

    'a-modal': Modal,
    'a-checkbox': Checkbox,
    'a-select': Select,
    'a-select-option': Select.Option,

    'a-tag': Tag,
    'a-popover': Popover,

    'associate-profile': AssociateProfile,
    'send-notification': SendNotification,
    'add-note': AddNote,
    'clone-associate': CloneAssociate,

    'grouped-associate-info': GroupedAssociateInfo
  },
  mixins: [api],
  data() {
    return {
      editingAssociateNumber: undefined,
      cloningAssociateNumber: undefined,

      associateNumber: undefined,
      roleId: undefined,
      scheduleTypeId: undefined,

      rateFrom: undefined,
      rateTo: undefined,

      showAllPaymentTypes: false,
      isActive: false,
      isVerified: false,

      roles: store.state.applicationState.projectRoles,
      selectedRole: 0,

      scheduleTypes: store.state.applicationState.scheduleTypes,
      selectedScheduleType: 0,

      tableData: [],

      foundAssociates: [],

      columns: [
        {
          title: 'Associate Number',
          dataIndex: 'associate_number',
          sorter: true
        },
        {
          title: 'Associate',
          key: 'associate',
          scopedSlots: {customRender: 'associate'},
          sorter: true
        },
        {
          title: 'Job classification',
          dataIndex: 'role.name',
          key: 'role',
          sorter: true
        },
        {
          title: 'Payment Type',
          dataIndex: 'payment_info.type',
          sorter: true
        },
        {
          title: 'Pay Status',
          scopedSlots: {customRender: 'payStatus'},
          key: 'pay_status',
          sorter: true
        },
        {
          title: 'Rate, $',
          key: 'rate',
          scopedSlots: {customRender: 'rate'},
          sorter: true
        },
        {
          title: 'Status',
          key: 'status',
          scopedSlots: {customRender: 'isActive'},
          align: 'center'
        },
        {
          title: 'Verified',
          key: 'verified',
          scopedSlots: {customRender: 'verified'},
          align: 'center'
        },
        {
          title: 'Schedule Type',
          key: 'schedule_type',
          scopedSlots: {customRender: 'scheduleType'},
          sorter: true
        },
        {
          title: 'Actions',
          scopedSlots: {customRender: 'actions'}
        }
      ],
      pagination: {
        pageSizeOptions: ['10', '50', '100'],
        pageSize: 50,
        current: 1,
        total: 0,
        showSizeChanger: true,
        onChange: (page) => {
          this.pagination.current = page;
          this.loadData()
        },
        onShowSizeChange: (current, size) => {
          this.pagination.pageSize = size;
          this.loadData();
        }
      },
      loading: false,

      associateProfileModalKey: 0,
      cloneAssociateModalKey: 0,
      associateProfileToSendSms: undefined,
      associateProfileToAddNote: undefined,

      cloneAssociateVisible: false,

      tourSteps: [
        {
          target: ".ant-table-row",
          header: {
            title: "Associate Profiles",
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: this.profilesTourBeforeStartCallback,
          content: `
            <p>This section displays key information about each associate. Each row contains:</p>
            <p><b>Associate Number</b>: Unique identifier for the associate.</p>
            <p><b>Full Image</b>: Picture of the associate (if exists).</p>
            <p><b>Classification</b>: Job classification of the associate.</p>
            <p><b>Payment Type</b>: Method of payment (e.g., salary, hourly).</p>
            <p><b>Payment Status</b>: Current status of payment (e.g., active, suspended).</p>
            <p><b>Rate</b>: Pay rate of the associate.</p>
            <p><b>Schedule Type</b>: Type of work schedule the associate follows.</p>
          `
        },
        {
          target: ".ant-table-row td:nth-child(7)",
          header: {
            title: "Associate Status",
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: "This field shows whether the associate is currently employed or has\n" +
            "been terminated."
        },
        {
          target: "#edit-associate-profile",
          header: {
            title: "Associate Edit",
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: () => {
            if (!this.editingAssociateNumber) {
              return;
            }
            return new Promise((resolve) => {
              this.endEditAssociateProfile();
              setTimeout(() => {
                resolve()
              }, 1000);
            })
          },
          content: `
          <p>Clicking 'Edit' allows you to view and modify various details about the associate, including:</p>
          <p><b>Personal Information</b>: Basic personal details of the associate.</p>
          <p><b>Job Information</b>: Details about the associate's job role and responsibilities.</p>
          <p><b>Payment Information</b>: Information related to the associate's payment method and status.</p>
          <p><b>Signed Documents</b>: Documents signed by the associate.</p>
          <p><b>Associate KPI</b>: Key Performance Indicators for the associate.</p>
          <p><b>Clone Associate</b>: This feature allows you to clone the associate with a different payment type, enabling them to work as both an employee and a contractor, or in different roles or for different companies.</p>
          `
        },
        {
          target: '.profile-form-menu li:nth-child(1)',
          header: {
            title: 'KPI'
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: () => {
            return new Promise((resolve) => {
              this.startEditAssociateProfile(this.tableData[0].associate_number);
              setTimeout(() => {
                resolve()
              }, 1000);
            })
          },
          content: 'Here you can view an associate\'s KPI'
        },
        {
          target: '.profile-form-menu li:nth-child(2)',
          header: {
            title: 'Personal'
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: () => {
            document.querySelector('.profile-form-menu li:nth-child(2)').click()
          },
          content: 'Here you can view an associate\'s personal information'
        },
        {
          target: '.profile-form-menu li:nth-child(3)',
          header: {
            title: 'Job'
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: () => {
            document.querySelector('.profile-form-menu li:nth-child(3)').click()
          },
          content: 'Here you can view an associate\'s job information (ex. job classification, schedule type)'
        },
        {
          target: '.profile-form-menu li:nth-child(4)',
          header: {
            title: 'Pay'
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: async () => {
            if (this.editingAssociateNumber) {
              document.querySelector('.profile-form-menu li:nth-child(4)').click()
              return;
            }

            await new Promise((resolve) => {
              this.startEditAssociateProfile(this.tableData[0].associate_number);
              setTimeout(() => {
                resolve()
              }, 1000);
            })
            document.querySelector('.profile-form-menu li:nth-child(4)').click()
          },
          content: 'Here you can view an associate\'s payment information,'
        },
        {
          target: "#associate-shifts-btn",
          header: {
            title: "Associate Shifts",
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: () => {
            return new Promise((resolve) => {
              this.endEditAssociateProfile();
              setTimeout(() => {
                resolve()
              }, 500);
            })
          },
          content: "This button lets you view all shifts worked by the associate."
        },
        {
          target: '#send-message-btn',
          header: {
            title: 'Send Message Button'
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: "Use this button to send a message to the associate, whether it's to\n" +
            "notify them of something or to request action."
        },
        {
          target: '#note-btn',
          header: {
            title: 'Note Button'
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: "This button allows you to leave a note for associate"
        },
        {
          target: '#active-btn',
          header: {
            title: 'Terminate/Activate Associate Button'
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: "This button allows you to activate or terminate associate"
        }
      ],
      callbacks: {
        onSkip: () => localStorage.setItem('profilesTourPassed', true),
        onFinish: () => localStorage.setItem('profilesTourPassed', true)
      }
    }
  },
  watch: {
    isActive() {
      this.loadData();
    },
    isVerified() {
      this.loadData();
    },
    selectedRole() {
      this.loadData();
    },
    selectedScheduleType() {
      this.loadData();
    },
    associateNumber() {
      this.loadData();
    }
  },
  computed: {
    vueTourOptions() {
      return vueTourOptions
    },
    selectedRoleFilter() {
      return this.selectedRole != 0 ? this.selectedRole : null;
    },
    selectedScheduleTypeFilter() {
      return this.selectedScheduleType != 0 ? this.selectedScheduleType : null;
    },
    associateProfileModalVisible() {
      return this.editingAssociateNumber != null;
    }
  },
  methods: {
    loadData(sorter) {
      this.loading = true;
      this.apiGetAssociateProfiles(
        this.associateNumber,
        this.selectedRoleFilter,
        this.selectedScheduleTypeFilter,
        this.rateFrom,
        this.rateTo,
        this.showAllPaymentTypes,
        this.isActive,
        this.isVerified,
        this.pagination.current,
        this.pagination.pageSize,
        sorter
      )
        .then((response) => {
          const body = response.body;

          this.tableData = body.associates;
          this.pagination.total = body.total;
        })
        .finally(() => this.loading = false)
    },

    renderScheduleType(scheduleType) {
      switch (scheduleType) {
        case ScheduleType.FIXED:
          return 'Fixed';
        case ScheduleType.SELF_SCHEDULE:
          return 'Self Schedule';
        case ScheduleType.FREE:
          return 'Free';
      }
    },
    renderPayStatus(payStatus) {
      switch (payStatus) {
        case PayStatus.TERMINATED:
          return 'Terminated';
        case PayStatus.ON_CALL:
          return 'On Call';
        case PayStatus.PART_TIME:
          return 'Part Time';
        case PayStatus.FULL_TIME:
          return 'Full Time';
        default:
          return 'N/A';
      }
    },

    renderRate(rateInCents) {
      return rateInCents / 100;
    },

    startEditAssociateProfile(associateNumber) {
      this.associateProfileModalKey++;
      this.editingAssociateNumber = associateNumber;
    },

    openAssociateShiftsList(associateNumber) {
      this.$store.state.applicationState.timeCardsSelectedEmployee = associateNumber;
      this.$router.push({path: '/shifts'});
    },

    endEditAssociateProfile() {
      this.editingAssociateNumber = undefined;
    },

    handleEdited() {
      this.loadData();
    },

    fetchAssociates(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout)
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.apiSearchAssociate(value).then((resp) => {
          this.foundAssociates = resp.data.associate_list.map(user => ({
            label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
            key: user.employee_id,
            value: user.employee_id
          }))
        })
      }, 1000)
    },

    handleSearchAssociatesChange(value) {
      this.associateNumber = value;
      this.$refs.userSelect.blur();
    },

    combineFullName(firstName, lastName) {
      return Util.combineAssociateName(firstName, lastName);
    },

    openSmsSendModal(associate) {
      this.associateProfileToSendSms = associate;
    },
    closeSmsModal() {
      this.associateProfileToSendSms = undefined;
    },
    openNoteModal(associate) {
      this.associateProfileToAddNote = associate;
    },
    closeNoteModal() {
      this.associateProfileToAddNote = undefined;
    },
    getTagColor(record) {
      if (!record.is_active) {
        return StatusTagColors.GRAY;
      }
      if (record.suspension) {
        return StatusTagColors.RED;
      }

      return StatusTagColors.GREEN;
    },
    getVerifiedTagColor(record) {
      return record.is_verified ? StatusTagColors.GREEN : StatusTagColors.RED;
    },
    getAssociateStatus(record) {
      if (!record.is_active) {
        return 'TERMINATED'
      }
      if (record.suspension) {
        return 'SUSPENDED'
      }

      return 'ACTIVE';
    },
    handleTableChange(pagination, filters, sorter) {
      this.loadData(sorter);
    },

    openCloneAssociate() {
      const associateNumberToClone = this.editingAssociateNumber;

      this.cloneAssociateModalKey++;
      this.editingAssociateNumber = undefined;

      this.cloneAssociateVisible = true;
      this.cloningAssociateNumber = associateNumberToClone;
    },
    endCloneAssociate() {
      this.cloneAssociateVisible = false;
      this.cloningAssociateNumber = undefined;
    },
    openNewUserWithAssociateView() {
      router.push('/create-user-with-associate');
    },

    setActiveOrTerminate(record) {
      this.apiSetAssociateActivityStatus(record.associate_number, record.is_active ? 'terminate' : 'activate')
        .then((resp) => {
          notification.success({
            message: 'Associate has been successfully ' + (record.is_active ? 'terminated' : 'activated')
          })
          record.is_active = !record.is_active;
        })
    },

    profilesTourBeforeStartCallback() {
      return new Promise((resolve, reject) => {
        const checkCondition = () => {
          if (this.tableData && this.tableData.length > 0 && !this.loading) {
            resolve();
          } else {
            setTimeout(checkCondition, 100);
          }
        };
        checkCondition();
      });
    },
  },
  mounted() {
    this.loadData();

    const passed = localStorage.getItem('profilesTourPassed');
    if (!passed) {
      this.$tours['profilesTour'].start();
    }

    if (this.$route.query && this.$route.query.associateNumber) {
      this.startEditAssociateProfile(this.$route.query.associateNumber);
    }
  }
}
</script>
<style>
.associate-profile-modal-wrapper {
  overflow-x: auto;
  width: 100%;
  height: 600px;
}
</style>
