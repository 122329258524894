import { render, staticRenderFns } from "./payroll-documents-upload.vue?vue&type=template&id=ec92236c&scoped=true&"
import script from "./payroll-documents-upload.vue?vue&type=script&lang=js&"
export * from "./payroll-documents-upload.vue?vue&type=script&lang=js&"
import style0 from "./payroll-documents-upload.vue?vue&type=style&index=0&id=ec92236c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec92236c",
  null
  
)

export default component.exports