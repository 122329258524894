<template>
  <div>
    <div class="flex flex-column gap-4 px-4">
      <div>
        <h4>Active Payment Type</h4>
        <span>{{ currentPaymentTypeName }}</span>
        <h4>Time Zone</h4>
        <span class="time-zone-select-group">
              <a-select
                class="time-zone-select"
                v-model="selectedTimeZone"
                @change="onTimeZoneChange"
                show-search
                filter-option
              >
              <a-select-option v-for="zone in timeZones" :key="zone" :value="zone">
                {{ zone }}
              </a-select-option>
            </a-select>
              <a-button icon="reload" @click="resetTimeZone">Reset</a-button>
            </span>
      </div>
      <div>
        <h4>Your Companies</h4>
        <all-projects/>
      </div>
    </div>
  </div>
</template>

<script>
import {Select, Button} from "ant-design-vue";
import moment from "moment-timezone";
import {mapMutations} from "vuex";
import api from "@/api";
import store from "@/store";
import AllProjects from "@/views/all-projects.vue";

export default {
  components: {
    AllProjects,
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-button': Button
  },
  mixins: [api],
  data() {
    return {
      selectedTimeZone: undefined,
      // selectedPaymentType: undefined,

      timeZones: moment.tz.names()
    }
  },
  computed: {
    paymentTypes() {
      return this.$store.state.applicationState.paymentTypes;
    },
    currentPaymentTypeName() {
      return this.$store.state.applicationState.currentPaymentTypeName ?? '-';
    }
  },
  methods: {
    ...mapMutations({
      setStoreTimeZone: 'setTimeZone'
    }),
    onTimeZoneChange(newTimeZone) {
      this.setStoreTimeZone(newTimeZone);
      window.location.reload();
    },
    resetTimeZone() {
      const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      this.selectedTimeZone = defaultTimeZone;
      this.setStoreTimeZone(defaultTimeZone);
    },
    // handlePaymentTypeChange(value) {
    //   this.apiSetAdminPaymentType(value).then(async () => await store.dispatch('loadApplicationState'));
    // }
  },
  mounted() {
    this.selectedTimeZone = this.$store.state.applicationState.timeZone ?? undefined;
    // this.selectedPaymentType = this.$store.state.applicationState.currentPaymentType ?? undefined;
  }
}
</script>

<style scoped>
.time-zone-select-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.time-zone-select {
  width: 250px;
}
</style>
