<template>
  <date-picker v-model="date" v-bind="$attrs"></date-picker>
</template>

<script>
import moment from "moment-timezone";
import DatePicker from 'vue2-datepicker';

export default {
  components: { DatePicker },
  props: ["value"],
  computed: {
    date: {
      get() {
        return moment.isMoment(this.value) ? this.value.toDate() : this.value;
      },
      set(val) {
        const result = val && moment(val);
        this.$emit("input", result);
      }
    }
  }
};
</script>
