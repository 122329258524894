<template>
  <a-modal
    title="Payment Request"
    v-model="visible"
    width="800px"
    :footer="null"
  >
    <a-modal
      title="Shift Actions List"
      v-model="visibleLocationsModal"
      width="850px"
      :footer="null"
      @cancel="closeLocationsInfo"
    >
      <locations :list="currentLocationsList"/>
    </a-modal>

    <div v-if="paymentInfo" class="content-view">
      <div class="content-view-block">
        <div id="payment-info">
          <h3>Payment Info</h3>
          <p>
            <b>ID</b>
            <span>{{ paymentInfo.id }}</span>
          </p>
          <p>
            <b>System</b>
            <span>{{ combinedPaymentSystem }}</span>
          </p>
          <p>
            <b>Status</b>
            <span>
            <a-tag
              class="status-tag"
              :color="getPaymentStatusTagColor()"
            >
              {{ paymentStatus }}
            </a-tag>
          </span>
          </p>
          <p>
            <b>Total, $</b>
            <span>{{ convertedTotal }}</span>
          </p>
        </div>
        <div id="associate-info">
          <h3>Associate Info</h3>
          <p>
            <b>Number</b>
            <span>{{ paymentInfo.associate.associate_number }}</span>
          </p>
          <p>
            <b>Name</b>
            <span>{{ requesterFullName }}</span>
          </p>
          <p>
            <b>Profile</b>
            <a-button
              icon="edit"
              size="small"
              :disabled="loading"
              @click="openAssociateProfile(paymentInfo.associate.associate_number)"
            >
              Open
            </a-button>
          </p>
        </div>
        <div v-if="paymentInfo && paymentInfo.reviewed_by">
          <h3>Reviewer Info</h3>
          <p>
            <b>Number</b>
            <span>{{ paymentInfo.reviewed_by.associate_number }}</span>
          </p>
          <p>
            <b>Name</b>
            <span>{{ reviewerFullName }}</span>
          </p>
          <p>
            <b>Profile</b>
            <a-button
              icon="edit"
              size="small"
              :disabled="loading"
              @click="openAssociateProfile(paymentInfo.reviewed_by.associate_number)"
            >
              Open
            </a-button>
          </p>
        </div>
        <div class="flex flex-column gap-3">
          <div v-if="paymentCancellingProcessStarted" class="flex flex-column gap-2">
            <a-textarea
              v-model="cancelReason"
              placeholder="Enter Decline Reason"
              :auto-size="{ minRows: 1, maxRows: 5 }"
            />
            <div class="flex gap-2 justify-content-between">
              <a-button
                type="danger"
                icon="close"
                :disabled="!canApprovePayment || cancelReasonIsEmpty || loading"
                @click="cancelPayment"
              >
                Decline
              </a-button>
              <a-button
                type="primary"
                icon="backward"
                :disabled="loading"
                @click="resetPaymentCancellingProcess"
              >
                Cancel
              </a-button>
            </div>
          </div>
          <div v-else class="flex gap-2 justify-content-between">
            <a-button
              id="approve-pr-btn"
              type="primary"
              icon="check"
              :loading="loading"
              :disabled="!canApprovePayment"
              @click="approvePayment"
            >
              Approve
            </a-button>
            <a-button
              id="decline-pr-btn"
              type="danger"
              icon="close"
              :loading="loading"
              :disabled="!canApprovePayment"
              @click="cancelPayment"
            >
              Decline
            </a-button>
          </div>
          <a-button
            v-if="markPaidAvailable"
            icon="edit"
            :disabled="markPaidDisabled || loading"
            @click="markPaid"
          >
            Mark Paid
          </a-button>
        </div>
      </div>
      <div class="content-view-block">
        <div>
          <h3>Other Payment</h3>
          <template v-if="!editOtherPayment">
            <template v-if="otherPaymentAvailable">
              <p>
                <b>Amount, $</b>
                <span>{{ convertedOtherPaymentAmount }}</span>
              </p>
              <p>
                <b>Explanation</b>
                <span>{{ paymentInfo.other_payment.explanation }}</span>
              </p>
              <p>
                <b>Actions</b>
                <a-button size="small" icon="edit" @click="switchEditOtherPayment">Edit</a-button>
              </p>
            </template>
            <template v-else>
              <p>
                <b>Actions</b>
                <a-button size="small" icon="edit" :disabled="!canApprovePayment || loading" @click="switchEditOtherPayment">Add</a-button>
              </p>
            </template>
          </template>
          <template v-else>
            <p>
              <b>Amount, $</b>
              <a-input-number v-model="newOtherPaymentAmount" :min="0.01" placeholder="Amount, $"/>
            </p>
            <p>
              <b>Explanation</b>
              <a-textarea
                v-model="newExplanation"
                placeholder="Enter Explanation"
                :auto-size="{ minRows: 1, maxRows: 5 }"
              />
            </p>
            <p>
              <b>Actions</b>
              <span class="flex flex-row gap-2">
              <a-button
                size="small"
                icon="check"
                type="primary"
                :disabled="setOtherPaymentDisabled || loading"
                @click="setNewOtherPayment"
              >
                Set
              </a-button>
              <a-button
                v-if="otherPaymentAvailable"
                size="small"
                icon="undo"
                type="danger"
                :disabled="loading"
                @click="deleteOtherPayment"
              >
                Reset
              </a-button>
              <a-button size="small" :disabled="loading" icon="close" @click="switchEditOtherPayment">Cancel</a-button>
            </span>
            </p>
          </template>
        </div>
        <div id="content-info">
          <h3>Content</h3>
          <p>
            <b>Invoice</b>
            <a-dropdown size="small" :disabled="!canDownloadInvoice">
              <a-menu slot="overlay">
                <a-menu-item key="pdf" @click="downloadInvoice('pdf')">
                  <a-icon type="file-pdf"/>
                  PDF
                </a-menu-item>
                <a-menu-item key="csv" @click="downloadInvoice('csv')">
                  <a-icon type="file-text"/>
                  CSV
                </a-menu-item>
              </a-menu>
              <a-button :disabled="loading" size="small" icon="download">
                Download
              </a-button>
            </a-dropdown>
          </p>
          <p>
            <b>Time/Attendance Detail Report</b>
            <a-dropdown :disabled="timeAttendanceDownloadDisabled" size="small">
              <a-menu slot="overlay">
                <a-menu-item key="pdf" @click="downloadTimeAttendanceReport(timeAttendanceExportFormatType.PDF)">
                  <a-icon type="file-pdf"/>
                  PDF
                </a-menu-item>
                <a-menu-item key="excel" @click="downloadTimeAttendanceReport(timeAttendanceExportFormatType.EXCEL)">
                  <a-icon type="file-excel"/>
                  Excel
                </a-menu-item>
              </a-menu>
              <a-button :disabled="loading" size="small" icon="download">
                Download
              </a-button>
            </a-dropdown>
          </p>
          <p>
            <b>Actions</b>
            <a-button
              size="small"
              icon="environment"
              :disabled="locationsLoading"
              @click="showLocationsInfo"
            >
              Open
            </a-button>
          </p>
          <p v-if="paymentInfo.files && paymentInfo.files.length > 0">
            <b>Files</b>
            <a-button
              size="small"
              icon="download"
              @click="openFiles"
            >
              Open
            </a-button>
          </p>
        </div>
        <div id="shifts-info" v-if="paymentInfo.shifts">
          <h3>Shifts</h3>
          <a-table
            class="shifts-to-pay-table"
            size="small"
            :columns="shiftsToPayColumns"
            :pagination="false"
            :data-source="paymentInfo.shifts"
          >
          <span slot="shift-date" slot-scope="text, record">
            {{ formatDateRange(record.work_start, record.work_end) }}
          </span>
            <div slot="expandedRowRender" slot-scope="record">
              <div class="content-view-block">
                <p v-if="record.status">
                  <b>Status</b>
                  <a-tag
                    class="payment-status-tag"
                    :color="getShiftStatusTagColor(record.status)"
                  >
                    {{ tryToGetPaymentStatusTagText(record.status) }}
                  </a-tag>
                </p>
                <p>
                  <b>Scheduled</b>
                  <span class="text-right">{{ getScheduledDuration(record) }}</span>
                </p>
                <p>
                  <b>Signed</b>
                  <span class="text-right">{{ getSignedDuration(record) }}</span>
                </p>
                <p>
                  <b>Actual</b>
                  <span class="text-right">{{ getActualDuration(record) }}</span>
                </p>
                <p>
                  <b>Lunch Break</b>
                  <span class="text-right">{{ getFirstLunchDuration(record) }}</span>
                </p>
                <p v-if="record.second_lunch_duration">
                  <b>2nd Lunch Break</b>
                  <span class="text-right">{{ getSecondLunchDuration(record) }}</span>
                </p>
                <p>
                  <b>Payment Type</b>
                  <span class="text-right">{{ record.payment_type }}</span>
                </p>
                <p>
                  <b>Rate</b>
                  <span class="text-right">${{ convertedRate(record) }}</span>
                </p>
                <p>
                  <b>Instant Loan</b>
                  <span class="text-right">{{ record.instant_loan_percent * 100 }}%</span>
                </p>
                <p>
                  <b>Attachments</b>
                  <a-dropdown size="small" v-if="attachmentsAvailable(record)">
                    <a-menu slot="overlay">
                      <a-menu-item v-for="file in record.files" :key="file.url"
                                   @click="openAttachmentOnNewPage(file)">
                        <a-icon type="file-image"/>
                        {{ file.file_name }}
                      </a-menu-item>
                    </a-menu>
                    <a-button :disabled="loading" size="small" icon="download">
                      Download
                    </a-button>
                  </a-dropdown>
                  <span v-else>
                  N/A
                </span>
                </p>
              </div>
            </div>
          </a-table>
        </div>
      </div>
    </div>
    <div class="loading-spin" v-else>
      <a-spin/>
    </div>
  </a-modal>
</template>
<script>
import {Button, Dropdown, Icon, Menu, notification, Table, Tag, Spin, Input, InputNumber, Modal} from 'ant-design-vue';
import moment from "moment-timezone";

import api from "@/api";
import Util from "@/util";
import shiftsHelper from "@/helpers/shifts";
import locations from "@/components/locations.vue";
import {tryToGetPaymentStatusTagText} from "@/const";

const statusTagColors = {
  GREEN: '#34db30',
  GRAY: '#b4b4b4',
  RED: '#ff4646',
  ORANGE: '#ffb546',
  BLUE_LIGHT: '#52bfff',
}

const shiftStatusEnum = {
  SCHEDULED: 'scheduled',
  STARTED: 'started',
  MISSED: 'missed',
  SICK: 'sick',
  VACATION: 'vacation',
  CANCELLED: 'cancelled',
  CALL_OFF: 'call_off',
  NOT_SIGNED: 'not_signed',
  AUTH_ISSUES: 'auth_issues',
  READY_TO_PAYMENT: 'not_paid',
  DRAFT: 'draft',
  IN_PROGRESS: 'in_progress',
  PAID: 'paid',
}

const shiftStatusColors = {
  GREEN: [shiftStatusEnum.READY_TO_PAYMENT],
  GRAY: [shiftStatusEnum.SCHEDULED, shiftStatusEnum.STARTED, shiftStatusEnum.PAID],
  RED: [shiftStatusEnum.MISSED],
  LILAC: [shiftStatusEnum.SICK, shiftStatusEnum.VACATION, shiftStatusEnum.CALL_OFF, shiftStatusEnum.CANCELLED],
  ORANGE: [shiftStatusEnum.NOT_SIGNED, shiftStatusEnum.AUTH_ISSUES],
  BLUE_LIGHT: [shiftStatusEnum.IN_PROGRESS],
  TURTLE: [shiftStatusEnum.DRAFT],
}

export default {
  data() {
    return {
      paymentInfo: undefined,
      shiftsToPayColumns: [
        {
          title: 'Date',
          scopedSlots: {customRender: 'shift-date'}
        }
      ],
      paymentCancellingProcessStarted: false,
      cancelReason: undefined,
      loading: false,
      editOtherPayment: false,

      newOtherPaymentAmount: undefined,
      newExplanation: undefined,

      currentLocationsList: undefined,

      timeAttendanceExportFormatType: {
        PDF: 0,
        EXCEL: 1
      },

      visible: false,
      locationsLoading: false
    }
  },
  props: ['paymentId'],
  mixins: [api, shiftsHelper],
  components: {
    locations,
    'a-button': Button,
    'a-button-group': Button.Group,
    'a-dropdown': Dropdown,
    'a-table': Table,
    'a-tag': Tag,
    'a-icon': Icon,
    'a-menu': Menu,
    'a-spin': Spin,
    'a-menu-item': Menu.Item,
    'a-input': Input,
    'a-textarea': Input.TextArea,
    'a-input-number': InputNumber
  },
  watch: {
    paymentId(curr, prev) {
      if (prev && !curr) {
        this.visible = false;
      }
    }
  },
  computed: {
    requesterFullName() {
      if (!this.paymentInfo || !this.paymentInfo.associate) {
        return '-';
      }

      return Util.combineAssociateName(
        this.paymentInfo.associate.user.first_name,
        this.paymentInfo.associate.user.last_name
      )
    },
    reviewerFullName() {
      if (!this.paymentInfo || !this.paymentInfo.reviewed_by) {
        return '-';
      }

      return Util.combineAssociateName(
        this.paymentInfo.reviewed_by.user.first_name,
        this.paymentInfo.reviewed_by.user.last_name
      )
    },
    combinedPaymentSystem() {
      let method = '-';

      if (this.paymentInfo && this.paymentInfo.method) {
        method = Util.capitalize(this.paymentInfo.method);
      }
      if (this.paymentInfo.method && this.paymentInfo.manual_payment_method) {
        method += ' ' + '(' + Util.splitAndCapitalize(
          this.paymentInfo.manual_payment_method
        ) + ')';
      }

      return method;
    },
    convertedTotal() {
      if (!this.paymentInfo) {
        return 0;
      }

      return Util.convertDbToMoney(this.paymentInfo.total);
    },

    convertedOtherPaymentAmount() {
      if (!this.paymentInfo || !this.paymentInfo.other_payment) {
        return 0;
      }

      return Util.convertDbToMoney(this.paymentInfo.other_payment.amount);
    },
    canDownloadInvoice() {
      return this.paymentInfo ? this.paymentInfo.accepted_agreement : false;
    },
    canApprovePayment() {
      return this.paymentInfo ? this.paymentInfo.next_action == "wait_for_approval" : false;
    },

    cancelReasonIsEmpty() {
      return !this.cancelReason || this.cancelReason.length == 0;
    },

    timeAttendanceDownloadDisabled() {
      return !this.paymentInfo.shifts || this.paymentInfo.shifts.length == 0
    },
    otherPaymentAvailable() {
      return this.paymentInfo.other_payment;
    },

    setOtherPaymentDisabled() {
      const amountChanged = this.newOtherPaymentAmount != undefined
        && this.newOtherPaymentAmount >= 0.01
        && Util.convertMoneyToDb(this.newOtherPaymentAmount) != (this.paymentInfo.other_payment?.amount || 0);
      const explanationChanged = this.newExplanation != undefined
        && this.newExplanation != this.paymentInfo.other_payment?.explanation;

      return !(amountChanged || explanationChanged);
    },

    paymentStatus() {
      switch (this.paymentInfo.status) {
        case 'ACCEPTED':
          return 'AWAITING APPROVAL';
        case 'PENDING':
          if (this.paymentInfo.method == 'Manual') {
            return 'REQUIRED PAYMENT';
          }
          return 'SENDING MONEY';
        case 'COMPLETED':
          return 'PAID';
        default:
          return this.paymentInfo.status;
      }
    },

    markPaidAvailable() {
      return this.paymentInfo && this.paymentInfo.method == 'Manual';
    },
    markPaidDisabled() {
      return !(this.markPaidAvailable && this.paymentInfo.status == 'PENDING');
    },

    visibleLocationsModal() {
      return this.currentLocationsList;
    }
  },
  methods: {
    tryToGetPaymentStatusTagText,
    loadData() {
      if (this.paymentId) {
        this.apiGetPayment(this.paymentId)
          .then((response) => this.paymentInfo = {...response.data})
      }
    },
    getPaymentStatusTagColor() {
      switch (this.paymentInfo.status) {
        case 'CREATED':
          return statusTagColors.GRAY;
        case 'PROCESSING':
          return statusTagColors.GRAY;
        case 'CONFIRMED':
          return statusTagColors.GRAY;
        case 'ACCEPTED':
          return statusTagColors.ORANGE;
        case 'PENDING':
          return statusTagColors.BLUE_LIGHT;
        case 'COMPLETED':
          return statusTagColors.GREEN;
        case 'CANCELLED':
          return statusTagColors.RED;
      }
    },
    getShiftStatusTagColor(status) {
      switch (true) {
        case shiftStatusColors.GREEN.includes(status):
          return statusTagColors.GREEN;
        case shiftStatusColors.GRAY.includes(status):
          return statusTagColors.GRAY;
        case shiftStatusColors.RED.includes(status):
          return statusTagColors.RED
        case shiftStatusColors.LILAC.includes(status):
          return statusTagColors.LILAC
        case shiftStatusColors.BLUE_LIGHT.includes(status):
          return statusTagColors.BLUE_LIGHT
        case shiftStatusColors.ORANGE.includes(status):
          return statusTagColors.ORANGE
        case shiftStatusColors.TURTLE.includes(status):
          return statusTagColors.TURTLE
      }
    },
    openAssociateProfile(associateNumber) {
      this.closeModal();

      this.$router.push({
        path: '/associate-profiles',
        query: {associateNumber}
      })
    },

    downloadInvoiceErrorNotification() {
      notification['error']({
        message: 'Error',
        description: 'An error occurred while loading the invoice.',
      })
    },
    notFoundNotification(msg) {
      notification['warning']({
        message: 'Not Found',
        description: msg
      })
    },
    otherPaymentUpdateErrorNotification() {
      notification['warning']({
        message: 'Other Payment Update Error',
        description: 'Please try again later'
      })
    },

    downloadInvoice(type) {
      this.apiGetInvoice(this.paymentInfo.id, type)
        .then((response) => {
          if (response.data.hasOwnProperty('file_url')) {
            window.open(response.data.file_url, '_blank')
          } else {
            this.downloadInvoiceErrorNotification();
          }
        })
        .catch((err) => {
          this.downloadInvoiceErrorNotification();
        })
    },

    approvePayment() {
      this.loading = true;
      if (!this.canApprovePayment) {
        notification['warning']({
          message: 'Error',
          description: 'Worker have not finished processing yet'
        })
      }
      let data = {};

      if (this.paymentInfo.method === "wise") {
        data = {"fund_type": "BALANCE"}
      }
      this.apiApprovePayment(this.paymentId, data)
        .then((resp) => {
          notification['success']({
            message: 'Success',
            description: 'The request was approved'
          })
          this.loadData();
        })
        .finally(() => this.loading = false)
    },
    cancelPayment() {
      if (!this.canApprovePayment) {
        notification['warning']({
          message: 'Error',
          description: 'Worker have not finished processing yet'
        })
      }

      if (!this.paymentCancellingProcessStarted) {
        this.paymentCancellingProcessStarted = true;
        return;
      }

      this.loading = true;

      this.apiCancelPayment(this.paymentInfo.id, this.cancelReason)
        .then((resp) => {
          notification['success']({
            message: 'Success',
            description: 'The request was declined'
          })
          this.resetPaymentCancellingProcess();
          this.loadData();
        })
        .finally(() => this.loading = false)
    },
    resetPaymentCancellingProcess() {
      this.cancelReason = undefined;
      this.paymentCancellingProcessStarted = false;
    },

    getScheduledHours(record) {
      return this.formatWorkHours(
        moment(record.scheduled_start),
        moment(record.scheduled_end)
      );
    },
    getScheduledDuration(record) {
      const timeRange = this.formatTimeRange(record.scheduled_start, record.scheduled_end);
      return timeRange.start + ' - ' + timeRange.end + ' (' + this.getScheduledHours(record) + ')';
    },

    getSignedHours(record) {
      return this.formatWorkHours(
        moment(record.work_start),
        moment(record.work_end),
        record.lunch_start ? moment(record.lunch_start) : null,
        record.lunch_end ? moment(record.lunch_end) : null,
        record.second_lunch_start ? moment(record.second_lunch_start) : null,
        record.second_lunch_end ? moment(record.second_lunch_end) : null
      )
    },
    getSignedDuration(record) {
      const timeRange = this.formatTimeRange(record.work_start, record.work_end);
      return timeRange.start + ' - ' + timeRange.end + ' (' + this.getSignedHours(record) + ')';
    },

    getActualHours(record) {
      return this.formatWorkHours(
        moment(record.actual_work_start),
        moment(record.actual_work_end),
        record.actual_lunch_start ? moment(record.actual_lunch_start) : null,
        record.actual_lunch_end ? moment(record.actual_lunch_end) : null,
        record.actual_second_lunch_start ? moment(record.actual_second_lunch_start) : null,
        record.actual_second_lunch_end ? moment(record.actual_second_lunch_end) : null
      )
    },
    getActualDuration(record) {
      const timeRange = this.formatTimeRange(record.actual_work_start, record.actual_work_end);
      return timeRange.start + ' - ' + timeRange.end + ' (' + this.getActualHours(record) + ')';
    },

    getFirstLunchDuration(record) {
      if (!record.lunch_start || !record.lunch_end) {
        return null;
      }

      const timeRange = this.formatTimeRange(record.lunch_start, record.lunch_end);
      return timeRange.start + ' - ' + timeRange.end
        + ' (' + this.minutesToHoursAndMinutes(record.lunch_duration) + ')';
    },
    getSecondLunchDuration(record) {
      if (!record.second_lunch_start || !record.second_lunch_end) {
        return null;
      }

      const timeRange = this.formatTimeRange(record.second_lunch_start, record.second_lunch_end);
      return timeRange.start + ' - ' + timeRange.end
        + ' (' + this.minutesToHoursAndMinutes(record.second_lunch_duration) + ')';
    },


    attachmentsAvailable(record) {
      return record.files && record.files.length > 0;
    },
    openAttachmentOnNewPage(file) {
      window.open(file.url, '_blank')
    },

    convertedRate(record) {
      return Util.convertDbToMoney(record.hour_rate);
    },

    downloadTimeAttendanceReport(format) {
      this.loading = true;
      switch (format) {
        case this.timeAttendanceExportFormatType.PDF:
          this.apiGetTimeAttendanceDetailReportByShiftIds(this.paymentInfo.shifts.map(shift => shift.id))
            .then((resp) => {
              if (resp.body.error_code != 0) {
                this.notFoundNotification(resp.body.msg);
              } else {
                window.open(resp.body.report_url, '_blank')
              }
            })
            .finally(() => this.loading = false)
          break;
        case this.timeAttendanceExportFormatType.EXCEL:
          this.apiSendShiftsReportByShiftIds(this.paymentInfo.shifts.map(shift => shift.id))
            .then((resp) => {
              if (resp.body.error_code != 0) {
                this.notFoundNotification(resp.body.msg);
              }

              window.open(resp.body.report_url, '_blank')
            })
            .finally(() => this.loading = false)
          break;
      }
    },

    switchEditOtherPayment() {
      if (this.otherPaymentAvailable) {
        this.newOtherPaymentAmount = Util.convertDbToMoney(this.paymentInfo.other_payment.amount);
        this.newExplanation = this.paymentInfo.other_payment.explanation;
      } else {
        this.newOtherPaymentAmount = 0.01;
        this.newExplanation = undefined;
      }

      this.editOtherPayment = !this.editOtherPayment;
    },

    setNewOtherPayment() {
      this.loading = true;
      const dbAmount = Util.convertMoneyToDb(this.newOtherPaymentAmount);

      this.apiSetOtherPayment(this.paymentId, dbAmount, this.newExplanation)
        .then((response) => {
          if (!response.body || response.body.error_code != 0) {
            this.otherPaymentUpdateErrorNotification();
            return;
          }

          this.loadData();
        })
        .catch(() => this.otherPaymentUpdateErrorNotification())
        .finally(() => {
          this.editOtherPayment = false;
          this.loading = false;
        });
    },
    deleteOtherPayment() {
      this.loading = true;

      this.apiDeleteOtherPayment(this.paymentId)
        .then((response) => {
          if (!response.body || response.body.error_code != 0) {
            this.otherPaymentUpdateErrorNotification();
            return;
          }

          this.loadData();
        })
        .catch(() => this.otherPaymentUpdateErrorNotification())
        .finally(() => {
          this.editOtherPayment = false;
          this.loading = false;
        });
    },

    markPaid() {
      Modal.confirm({
        title: 'Mark Paid',
        content: 'If you are sure - click OK to mark the request as paid',
        onOk: () => {
          this.loading = true;
          this.apiMarkManualPaymentRequestPaid(this.paymentId)
            .then((response) => {
              if (!response.body || response.body.error_code != 0) {
                this.otherPaymentUpdateErrorNotification();
                return;
              }

              notification['success']({
                message: 'Success',
                description: 'Payment request has been marked as paid'
              })

              this.loadData();
            })
            .catch(() => this.otherPaymentUpdateErrorNotification())
            .finally(() => {
              this.loading = false;
            });
        },
        onCancel() {
        }
      })
    },

    closeLocationsInfo() {
      this.currentLocationsList = undefined;
    },

    closeModal() {
      this.$emit('close');
    },

    showLocationsInfo() {
      this.locationsLoading = true;
      this.apiGetShiftLocations(this.paymentInfo.shifts.map(shift => shift.id))
        .then((response) => {
          const body = response.body;

          if (body.error_code == 0) {
            const locations = response.body.shift_actions;
            if (locations.length == 0 || !locations[0] || !locations[0].records || !locations[0].records.length) {
              this.showLocationsListEmptyMessage();
            } else {
              this.currentLocationsList = locations[0].records;
            }
          } else {
            this.showLocationsListLoadErrorMessage();
          }
        })
        .catch(() => this.showLocationsListLoadErrorMessage())
        .finally(() => this.locationsLoading = false);
    },
    openFiles() {
      this.paymentInfo.files.forEach((file) => {
        window.open(file.url, '_blank');
      })
    }
  },
  mounted() {
    if (this.paymentId) {
      this.visible = true;
      this.loadData(this.paymentId);
    }
  }
}
</script>

<style scoped>
.shifts-to-pay-table {
  height: 300px !important;
  overflow-y: auto;
}

.status-tag {
  margin: unset;
}

.loading-spin {
  background-color: rgba(255, 255, 255);
}
</style>
