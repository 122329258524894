<template>
  <div>
    <add-company-pay-code
      :key="addPayCodeFormKey"
      :visible="addPayCodeFormVisible"
      @cancel="addPayCodeFormVisible = false"
      @added="added"
    />
    <h2 class="view-header">Pay Codes</h2>
    <div class="actions">
      <a-button
        icon="reload"
        @click="refresh"
      />
      <a-button @click="addPayCode">Add Pay Code</a-button>
    </div>
    <a-table
      size="small"
      :pagination="{ pageSize: 50 }"
      :columns="columns"
      :data-source="tableData"
    >
      <span slot="type" slot-scope="text, record">
        {{ titleCase(record.type) }}
      </span>
      <span slot="conflicted" slot-scope="text, record">
        {{ record.conflicted ? 'Yes' : 'No' }}
      </span>
    </a-table>
  </div>
</template>

<script>

import {Icon, Button, Space, Table} from 'ant-design-vue';

import api from '@/api';
import TextFormModal from "@/components/text-form-modal";

import Util from "@/util";
import AddPayCode from "@/components/add-company-pay-code.vue";

export default {
  components: {
    'a-button': Button,
    'a-table': Table,
    'text-form-modal': TextFormModal,
    'add-company-pay-code': AddPayCode,
    Icon,
    Space,
  },

  mixins: [api],

  data: () => ({
    tableData: [],
    addPayCodeFormVisible: false,
    addPayCodeFormKey: 0,

    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 200,
        scopedSlots: {customRender: 'type'},
        align: 'center'
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: 100,
        align: 'center'
      },
      {
        title: 'Conflicted',
        key: 'conflicted',
        scopedSlots: {customRender: 'conflicted'},
        align: 'center'
      }
    ]
  }),

  methods: {
    titleCase: Util.titleCase,

    refresh() {
      this.loadData();
    },

    loadData() {
      this.tableData = []

      this.apiGetPayCodes()
        .then((data) => {
          data.body.pay_codes.sort((a, b) => {
            return a.id - b.id
          })
          data.body.pay_codes.forEach(item => this.tableData.push({
            rowKey: item.id,
            "id": item.id,
            "name": item.name,
            "description": item.description,
            "type": item.type.toUpperCase(),
            "value": item.value,
            "conflicted": item.conflicted
          }))
        })
        .catch(error => {
          console.error(error)
        });
    },

    async addPayCode() {
      ++this.addPayCodeFormKey;
      this.addPayCodeFormVisible = true;
    },
    added() {
      this.addPayCodeFormVisible = false;
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
  },
};
</script>


<style scoped>
.ui_table.roles {
  width: 300px;
  margin: 10px auto;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 4px;
  margin-bottom: 8px;
}
</style>
