<template>
  <div>
    <h2 class="view-header">Associate Pay Code</h2>

    <div class="flex flex-column mt-4">
      <div v-if="isNew" class="pay-code-creation-info">
        <p>
          When you’re creating Pay Codes, please note the following:
        </p>
        <ul>
          <li>
            If the associate(s) have any signed shifts on the selected dates, you won’t be able to create a Pay Code
            for those dates.
          </li>
          <li>
            If there are any canceled, missed, or scheduled shifts, we will automatically replace them with the Pay
            Code you selected.
          </li>
        </ul>
      </div>
      <div class="content-view">
        <div class="content-view-block">
          <div style="width: 400px">
            <h3 class="content-subheader">
              Pay Code Information
            </h3>
            <p>
              <b>Name</b>
              <span>
              <a-select
                class="select-pay-code"
                v-model="currentPayCode.id"
                placeholder="Choose Pay Code"
                @select="handlePayCodeChange"
              >
                <a-select-option v-for="pc in payCodes" :value="pc.id" :key="pc.id">
                  {{ pc.name }}
                </a-select-option>
              </a-select>
            </span>
            </p>
            <p v-if="currentPayCode.description">
              <b>Description</b>
              <span>{{ currentPayCode.description }}</span>
            </p>
            <p>
              <b>Date</b>
              <span v-if="isNew" class="flex flex-column align-items-center gap-1">
              <a-date-picker
                v-if="isTotal"
                v-model="currentPayCode.date"
                placeholder="Date"
                multiple
                :format="dateFormat"
              />
              <a-range-picker
                v-else
                :ranges="pickerRanges"
                class="pay-code-range-picker"
                separator="-"
                :format="dateFormat"
                @change="onDateRangeChange"
              />
            </span>
              <span v-else class="flex flex-column align-items-center gap-1">
              <a-date-picker
                class="pay-code-date-picker"
                v-model="currentPayCode.date"
                placeholder="Date"
                :format="dateFormat"
                disabled
              />
            </span>
            </p>
            <p>
              <b>{{ payCodeValue }}</b>
              <span>
              <a-input-number
                class="pay-code-duration-selector"
                v-model="currentPayCode.value"
                placeholder="Enter Value"
                :min="0"
                :step="0.01"
              />
            </span>
            </p>
          </div>
          <div>
            <div class="flex flex-row gap-2">
              <template>
                <a-button
                  type="primary"
                  @click="save"
                  :disabled="disabledSave"
                  :loading="loading"
                >
                  {{ isNew ? 'Add' : 'Save' }}
                </a-button>
                <a-button v-if="!isNew" @click="cancel">Cancel</a-button>
              </template>
            </div>
          </div>
        </div>
        <div class="content-view-block">
          <div>
            <h3 class="content-subheader">
              Associate Information
            </h3>

            <template v-if="isNew">
              <p>
                <template>
                  <b>Associates</b>
                  <a-select
                    class="filters-select"
                    mode="multiple"
                    ref="associateSelect"
                    v-model:value="selectedAssociates"
                    show-search
                    :allowClear="true"
                    placeholder="Associates"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    :default-active-first-option="false"
                    :dropdown-match-select-width="false"
                    :options="foundAssociates"
                    :loading="loading"
                    style="min-width: 180px"
                    @search="fetchUsers"
                    @change="handleSearchUsersChange"
                    @focus="handleFocusAssociates"
                  />
                </template>
              </p>
            </template>
            <template v-else-if="currentAssociate.associate_number">
              <p>
                <b>Associate Number</b>
                <span>{{ currentAssociate.associate_number }}</span>
              </p>
              <p>
                <b>Name</b>
                <span>{{ currentAssociate.name }}</span>
              </p>
              <p>
                <b>Job Classification</b>
                <span>{{ currentAssociate.role.name }}</span>
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Button, Checkbox, DatePicker, InputNumber, notification, Select, Table} from "ant-design-vue";
import Util from "@/util";
import moment from "moment-timezone";
import store from "@/store";
import api from "@/api";

const PayStatus = Object.freeze({
  FULL_TIME: 'full_time',
  PART_TIME: 'part_time',
  ON_CALL: 'on_call',
  TERMINATED: 'terminated',
})

export default {
  components: {
    'a-button': Button,
    'a-table': Table,

    'a-select': Select,
    'a-select-option': Select.Option,

    'a-date-picker': DatePicker,
    'a-range-picker': DatePicker.RangePicker,
    'a-input-number': InputNumber,

    'a-checkbox': Checkbox
  },
  mixins: [api],
  props: ["shiftId"],
  data() {
    return {
      currentPayCode: {
        id: undefined,
        date: undefined,
        name: undefined,
        description: undefined,
        value: undefined
      },

      payCodeDates: [],
      payCodeDuration: 0,

      loadingAssociatesData: false,

      currentAssociate: {
        number: undefined,
        name: undefined,
        role: undefined
      },

      dateFormat: store.state.applicationState.dateFormat,

      initialPayCodeState: null,

      loading: false,

      foundAssociates: [],

      associates: [],
      selectedAssociates: [],
      selectedAssociateNumber: undefined,
    }
  },
  computed: {
    serverFormattedPayCodeDate() {
      return this.currentPayCode.date ? this.currentPayCode.date.format(store.state.applicationState.serverDateFormat) : null;
    },

    isTotal() {
      return this.currentPayCode.type && this.currentPayCode.type === "total";
    },
    payCodeValue() {
      return this.isTotal ? "Total, $" : "Duration Per Day, h"
    },

    isNew() {
      return this.shiftId == null;
    },
    disabledSave() {
      const isAssociateSelected =
        this.selectedAssociateNumber != null ||
        this.selectedAssociates && this.selectedAssociates.length > 0
      ;

      const isCodeSelected =
        this.currentPayCode.id !== undefined
        && this.currentPayCode.id !== null
        && this.currentPayCode.id !== 0;

      const isDateSelected = this.currentPayCode.date != null || this.payCodeDates && this.payCodeDates.length > 0;
      const isDurationValid = this.currentPayCode.value !== undefined && this.currentPayCode.value > 0;

      if (this.isNew) {
        return !isAssociateSelected || !isCodeSelected || !isDateSelected || !isDurationValid || this.loadingAssociatesData;
      } else {
        return !this.isNew && this.currentPayCode === this.initialPayCodeState || this.loadingAssociatesData;
      }
    },
    rowSelection() {
      return {
        onChange: this.onSelectChange
      }
    },

    pickerRanges() {
      return {
        'Today': [moment(), moment()],

        'This Week (From Today)': [moment(), moment().endOf('week')],
        'Next Week': [
          moment().add(1, 'week').startOf('week'),
          moment().add(1, 'week').endOf('week')
        ],

        'This Month (From Today)': [moment(), moment().endOf('month')],
        'Next Month': [
          moment().add(1, 'month').startOf('month'),
          moment().add(1, 'month').endOf('month')
        ]
      };
    },

    payCodes() {
      return this.$store.state.applicationState.currentProject.pay_codes.map(pay_code => ({
        ...pay_code,
        value: pay_code.type.toLowerCase() === "total" ? Util.convertDbToMoney(pay_code.value) : pay_code.value,
      }));
    }
  },
  methods: {
    fillPayCodeDataFromServerResponse(associate_shift) {
      const pay_code = associate_shift.shift.pay_code;
      this.currentPayCode = {
        id: pay_code.id,
        name: pay_code.name,
        date: moment(associate_shift.shift.date),
        description: pay_code.description,
        type: pay_code.type,
        value: pay_code.type.toLowerCase() === 'total' ? Util.convertDbToMoney(associate_shift.shift.hour_rate) :
          (associate_shift.shift.duration / 60).toFixed(2),
      };
      this.currentPayCode.initialValue = this.currentPayCode.value;

      this.currentAssociate = associate_shift.associate;
      this.currentAssociate.name = Util.capitalize(this.currentAssociate.first_name) + " " +
        Util.capitalize(this.currentAssociate.last_name);
    },

    loadPayCodeData() {
      this.apiGetAssociatePayCodeByShift(this.shiftId)
        .then((resp) => this.fillPayCodeDataFromServerResponse(resp.body.shift));
    },
    savePayCode() {
      this.loading = true;
      const successObject = {};

      const setMidnight = (date) => {
        return moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      };

      if (this.isNew) {
        this.loadingAssociatesData = true;

        const selectedAssociates = this.selectedAssociates.map(associate_number => ({
          associate_number: associate_number
        }));

        const payCode = {
          ...this.currentPayCode,
          value: this.currentPayCode.type.toLowerCase() === "total" ?
            Util.convertMoneyToDb(this.currentPayCode.value) : this.currentPayCode.value,
        };

        const payCodeDates = this.currentPayCode.date
          ? [setMidnight(this.currentPayCode.date)]
          : this.payCodeDates.map(date => setMidnight(date));

        this.apiAddAssociatePayCodes(
          selectedAssociates,
          payCode,
          payCodeDates,
        )
          .then((resp) => {
            if (resp.body.error_code != 0) {
              notification['warning']({
                message: 'Error adding pay code',
                description: resp.body.msg ?? 'Please try again later or contact administrator'
              });
              return;
            }

            successObject['message'] = 'Pay code successfully added';
            if (resp.body.msg) {
              successObject['description'] = resp.body.msg;
            }

            notification['success']({...successObject});
            this.$emit('on-add');
          })
          .finally(() => {
            this.loading = false;
            this.loadingAssociatesData = false;
          });
      } else {
        const payCode = {
          ...this.currentPayCode,
          value: this.currentPayCode.type.toLowerCase() === "total" ?
            Util.convertMoneyToDb(
              this.currentPayCode.value - this.currentPayCode.initialValue
            ) : this.currentPayCode.value - this.currentPayCode.initialValue,
        };

        const payCodeDate = setMidnight(this.currentPayCode.date);

        this.apiEditAssociatePayCode(
          this.shiftId,
          payCode,
        )
          .then((resp) => {
            if (resp.body.error_code != 0) {
              notification['warning']({
                message: 'Error editing pay code',
                description: resp.body.msg ?? 'Please try again later or contact administrator'
              });
              return;
            }

            successObject['message'] = 'Pay code successfully edited';
            if (resp.body.msg) {
              successObject['description'] = resp.body.msg;
            }

            notification['success']({...successObject});
            this.$emit('on-edit');
          })
          .finally(() => {
            this.loading = false;
            this.loadingAssociatesData = false;
          });
      }
    },
    updateDate(newDate) {
      this.currentPayCode.date = moment(newDate);
    },

    save() {
      this.savePayCode();
      this.initialPayCodeState = null;
    },

    cancel() {
      this.returnInitialState();
    },

    saveInitialState() {
      this.initialPayCodeState = JSON.parse(JSON.stringify(this.currentPayCode));
    },

    returnInitialState() {
      if (this.initialPayCodeState) {
        Object.assign(this.currentPayCode, this.initialPayCodeState);
        this.updateDate(this.initialPayCodeState.date);

        this.initialPayCodeState = null;
      }
    },

    fetchUsers(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout)
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.loading = true;
        this.apiSearchAssociate(value)
          .then((resp) => {
            this.foundAssociates = resp.data.associate_list.map(user => ({
              label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
              key: user.employee_id,
              value: user.employee_id
            }))
            this.loading = false;
          })
          .catch(err => console.error(err))
      }, 1000)
    },

    handleSearchUsersChange(value) {
      this.selectedAssociates = value;
    },

    handleFocusAssociates() {
      if (!this.selectedAssociates || !this.selectedAssociates.length) {
        this.fetchUsers('');
      }
    },

    handlePayCodeChange(value) {
      const pc = this.payCodes.find((code) => code.id === value);
      this.currentPayCode = {
        ...pc,
        date: this.currentPayCode.date,
        initialValue: this.currentPayCode.initialValue
      }
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedAssociates = selectedRows.map(row => row.associateNumber);
    },

    renderPayStatus(payStatus) {
      switch (payStatus) {
        case PayStatus.TERMINATED:
          return 'Terminated';
        case PayStatus.ON_CALL:
          return 'On Call';
        case PayStatus.PART_TIME:
          return 'Part Time';
        case PayStatus.FULL_TIME:
          return 'Full Time';
        default:
          return 'N/A';
      }
    },

    clearCurrentPayCode() {
      this.currentPayCode = {
        id: undefined,
        date: undefined,
        name: undefined,
        description: undefined,
        value: undefined
      }
    },
    onDateRangeChange(dates) {
      if (!dates || !dates.length) {
        this.payCodeDates = [];
        return;
      }

      let startDate = moment(dates[0]).startOf('day');
      let endDate = moment(dates[1]).startOf('day');
      let dateArray = [];

      while (startDate <= endDate) {
        dateArray.push(startDate.clone().utc().format());
        startDate.add(1, 'day');
      }

      this.payCodeDates = dateArray;
    }
  },
  mounted() {
    if (!this.isNew) {
      this.loadPayCodeData();
    }
  }
}
</script>

<style scoped>
.pay-code-duration-selector {
  width: 100px;
}

.pay-code-range-picker, .select-pay-code {
  width: 200px;
}

.pay-code-creation-info, .view-header {
  margin-left: 30px;
}
</style>
