<template>
  <div class="authorizations-container">
    <div>
      Are you sure you want to {{ shift.id ? 'adjust the' : 'save this' }} shift?
    </div>
    <div>
      <template v-if="autoSign">
        In order for the user to receive this shift for signing - turn off the “Auto Sign” selector.
      </template>
      <template v-else>
        User will get this shift to sign. If you want to avoid this - turn on the "Auto Sign" selector.
      </template>
    </div>
    <div v-if="authorizations && authorizations.length > 0" class="flex flex-column gap-2">
      <span>Notice that the specified data will result in the creation of authorizations:</span>
      <a-table
        size="small"
        :data-source="authorizations"
        :columns="columns"
        :pagination="false"
      >
      <span slot="authType" slot-scope="text, record">
        {{ getAuthorizationName(record) }}
      </span>
        <span slot="explanation" slot-scope="text, record">
        {{ authorizationDescriptionEnum[record] ?? 'Unfortunately, explanation is not available' }}
      </span>
      </a-table>
    </div>
    <div class="flex align-items-center gap-2">
      <a-switch v-model="autoSign"/>
      <span>Auto Sign</span>
    </div>
    <div class="authorization-buttons">
      <a-button
        type="primary"
        @click="saveShift"
      >
        Save
      </a-button>
      <a-button
        type="danger"
        @click="continueAdjustingShift"
      >
        Cancel
      </a-button>
    </div>
  </div>
</template>

<script>

import api from "@/api";
import Util from "@/util";
import {Button, Table, Switch} from "ant-design-vue";

export default {
  components: {
    'a-button': Button,
    'a-table': Table,
    'a-switch': Switch
  },
  mixins: [api],
  props: ["authorizations", "shift", "enableAutoSign"],
  data() {
    return {
      columns: [
        {
          title: 'Authorization Type',
          key: 'authType',
          scopedSlots: {customRender: 'authType'}
        },
        {
          title: 'Explanation',
          key: 'explanation',
          scopedSlots: {customRender: 'explanation'}
        },
      ],
      autoSign: true
    }
  },
  methods: {
    getAuthorizationName(authorizationKey) {
      return authorizationKey.split('_').map(elem => Util.capitalize(elem)).join(' ')
    },
    saveShift() {
      this.$emit('add', this.autoSign);
    },
    continueAdjustingShift() {
      this.$emit('cancel')
    }
  },
  computed: {
    beforeShiftStartGracePeriod() {
      return this.$store.state.applicationState.timeConstants.before_shift_start_grace_period
    },
    afterShiftEndGracePeriod() {
      return this.$store.state.applicationState.timeConstants.after_shift_end_grace_period
    },
    minWorkDurationHours() {
      return Util.formatMinutesToHoursAndMinutesDuration(this.$store.state.applicationState.timeConstants.min_work_duration);
    },
    longWorkDurationHours() {
      return Util.formatMinutesToHoursAndMinutesDuration(this.$store.state.applicationState.timeConstants.long_work_duration);
    },
    lunchDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(this.$store.state.applicationState.timeConstants.lunch_duration);
    },
    secondLunchDuration() {
      return Util.formatMinutesToHoursAndMinutesDuration(this.$store.state.applicationState.timeConstants.second_lunch_duration);
    },

    shiftDuration() {
      let workTime = this.shift.scheduled.end - this.shift.scheduled.start;
      if (this.shift.firstLunchStart && this.shift.firstLunchEnd) {
        workTime -= (this.shift.firstLunchEnd - this.shift.firstLunchStart);
      }
      if (this.shift.secondLunchStart && this.shift.secondLunchEnd) {
        workTime -= (this.shift.secondLunchEnd - this.shift.secondLunchStart);
      }
      return workTime;
    },

    authorizationDescriptionEnum() {
      const requiredDuration = this.shiftDuration > this.longWorkDurationHours ?
        this.longWorkDurationHours : this.minWorkDurationHours
      return {
        early_start_authorization: `Adjust the work start. It should coincide with the scheduled work start.`,
        early_end_authorization: `Adjust the work end. It should coincide with the scheduled work end.`,
        late_end_authorization: `Adjust the work end. It should coincide with the scheduled work end.`,
        early_lunch_end_authorization: `Increase the duration of lunch. Lunch duration should be
          at least ${this.lunchDuration} minutes.`,
        early_second_lunch_end_authorization: `Increase the duration of second lunch.
          Second lunch duration should be at least ${this.secondLunchDuration} minutes.`,
        no_lunch_authorization: `Specify the lunch time. Lunch should be on this shift.`,
        no_second_lunch_authorization: `Specify the second lunch time. Second lunch should be on this shift.`,
        absence_on_shift_authorization: `Authorize absence on shift. Associate was absent for a valid reason.`,
        late_start_authorization: `Adjust the work start. It should coincide with the scheduled work start.`,
        signed_actual_time_discrepancy: `There is a time discrepancy between signed and actual time.`,
        overtime_authorization: `Authorize overtime. The work hours should not exceed the scheduled hours of work.`,
      }
    }
  },
  mounted() {
    this.autoSign = this.enableAutoSign ?? false;
  }
}

</script>

<style scoped>
.authorizations-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.authorization-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
