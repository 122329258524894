<template>
	<transition name="modal" v-if="is_visible" ref="popup" @close="close()">
		<div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" style="width: 30vw">
          <div>
            <h2>{{title}}</h2>
          </div>

          <div class="modal-body" style="overflow: auto">
            <table v-if="file_list.length > 0" id="table">
              <thead>
                  <tr>
                      <th>File</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="file in file_list" :key="file.file_name">
                      <td><a :href="file.url" target="_blank">{{file.file_name}}</a></td>
                  </tr>
              </tbody>
            </table>
            <p v-else>No files found</p>
          </div>

          <div class="modal-footer">
            <button class="ui button" @click="close()">OK</button>
          </div>
        </div>
		  </div>
		</div>
  </transition>
</template>


<script>
import {Icon} from "ant-design-vue";
import JsonExcel from 'vue-json-excel';

export default {
    components: {
      'a-icon': Icon,
      'download-excel': JsonExcel
    },
    data: function() {
        return {
          is_visible: false,

          file_list: [],
          title: undefined,

          resolvePromise: undefined,
          rejectPromise: undefined
        };
    },
    methods: {
      show(opts) {
        this.is_visible = true;

        this.file_list = opts.file_list;
        this.title = opts.title;

        return new Promise((resolve, reject) => {
          this.resolvePromise = resolve
          this.rejectPromise = reject
        })
      },

      close() {
        this.is_visible = false;

        this.file_list = null;
        this.title = null;

        this.resolvePromise(null)
      },
    }
}
</script>

<style>
</style>
