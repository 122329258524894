<template>
  <div v-if="existingAssociateInfo">
    <div class="create-user-with-associate-controls">
      <h3>Clone Associate</h3>
    </div>
    <a-modal
      title="You can disable previous associate"
      v-model="openedDisablePreviousAssociateModal"
      :footer="null"
    >
      <div class="flex flex-column gap-4">
        <div>
          <p>
            You are about to set a new associate with a different pay type or role.
          </p>
          <p>
            You have the option to disable the previous associate profile if it is no longer needed.
          </p>
        </div>
        <div class="flex gap-2 justify-content-end">
          <a-button @click="cloneAssociate(false)">Leave The Account</a-button>
          <a-button @click="cloneAssociate(true)" type="primary">Disable Previous Associate</a-button>
        </div>
      </div>
    </a-modal>
    <a-form layout="inline" :form="form">
      <div class="content-view">
        <div class="content-view-block">
          <h3>Personal Information</h3>
          <p>
            <b>Full Name</b>
            <span>{{ fullName }}</span>
          </p>
          <p>
            <b>Email</b>
            <span>{{ email }}</span>
          </p>
          <p>
            <b>Phone Number</b>
            <span>{{ phoneNumber }}</span>
          </p>

          <h3>Company Information</h3>
          <p>
            <b class="associate-number-label">Associate Number <span class="required-field">*</span></b>
            <a-form-item required>
              <a-input
                v-decorator="['associateNumber', {rules: [
                    {required: true, message: associateNumberSuggestionMsg}]
                  }]"
                placeholder="Enter associate number"
                @input="handleAssociateNumberChange"
              />
            </a-form-item>
          </p>
          <p>
            <b>SSN <span class="required-field">*</span></b>
            <a-form-item>
              <a-input
                v-decorator="['socialSecurityNumber', {rules: [
                    {required: true, message: ssnErrorMsg}, {pattern: /^\d{9}$/, message: ssnErrorMsg}
                    ]}]"
                placeholder="Enter SSN"
              />
            </a-form-item>
          </p>
          <p>
            <b>Job Classification <span class="required-field">*</span></b>
            <a-form-item required>
              <a-select
                v-decorator="['projectRole', {
                    rules: [{required: true, message: 'Please select a job classification!'}],
                    initialValue: roleDefaultValue
                  }]"
                placeholder="Select job classification"
              >
                <a-select-option v-for="role in projectRoles" :key="role.id" :value="role.id">{{
                    role.name
                  }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>
          <p>
            <b>Schedule Type <span class="required-field">*</span></b>
            <a-form-item required>
              <a-select
                v-decorator="['scheduleType', {
                    rules: [{required: true, message: 'Please select a schedule type!'}],
                    initialValue: scheduleTypeDefaultValue}]"
                placeholder="Select ST"
              >
                <a-select-option v-for="type in scheduleTypes" :key="type.name" :value="type.name">{{
                    type.label
                  }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>
          <p>
            <b>Hire Date</b>
            <a-form-item>
              <a-date-picker
                v-decorator="['hireDate', {rules: [{required: false}]}]"
                placeholder="Select date"
                :format="visualDateFormat"
                :disabled-date="disabledDateAfterToday"
              />
            </a-form-item>
          </p>
          <p>
            <b>Seniority Date</b>
            <a-form-item>
              <a-date-picker
                v-decorator="['seniorityDate', {rules: [{required: false}]}]"
                placeholder="Select date"
                :format="visualDateFormat"
                :disabled-date="disabledDateAfterToday"
              />
            </a-form-item>
          </p>
          <p>
            <b>Terminated Date</b>
            <span>
              <a-form-item>
                <a-date-picker
                  v-decorator="['terminatedDate', {rules: [{required: false}]}]"
                  placeholder="Select date"
                  :format="visualDateFormat"
                  :disabled-date="disabledDateAfterToday"
                />
              </a-form-item>
            </span>
          </p>

          <div class="view-button-group">
            <a-form-item>
              <a-button
                type="primary"
                :loading="loadingForm"
                :disabled="enabledCloneButton"
                @click="toggleDisableSuggestionModal"
              >
                Clone
              </a-button>
            </a-form-item>
          </div>
        </div>
        <div class="content-view-block">
          <p>
            <a-tooltip>
              <template slot="title">
                {{ shiftActionsAutocompleteTooltip }}
              </template>
              <b>Shift Actions Autocomplete</b>
            </a-tooltip>
            <span class="clone-associate-checkbox">
              <a-form-item>
                <a-checkbox
                  v-decorator="['shiftActionsAutocomplete', {rules: [{required: false, type: 'boolean', initialValue: false}]}]"
                />
              </a-form-item>
            </span>
          </p>
          <p>
            <a-tooltip>
              <template slot="title">
                {{ requireShiftConfirmationTooltip }}
              </template>
              <b>Require Shift Confirmation</b>
            </a-tooltip>
            <span class="clone-associate-checkbox">
              <a-form-item>
                <a-checkbox
                  v-decorator="['requireShiftConfirmation', {rules: [{required: false, type: 'boolean', initialValue: false}]}]"
                />
              </a-form-item>
            </span>
          </p>

          <h3>Payment Information</h3>
          <p>
            <b>Payment Type</b>
            <a-form-item>
              <a-select
                v-decorator="['paymentType', {rules: [{required: false}], initialValue: paymentTypeDefaultValue}]"
                placeholder="Select PT"
                @change="handlePaymentTypeChange"
              >
                <a-select-option v-for="type in paymentTypes" :key="type.id" :value="type.id">{{
                    type.name
                  }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>
          <p>
            <b>Payment Status</b>
            <a-form-item>
              <a-select
                v-decorator="['paymentStatus', {rules: [{required: false}], initialValue: paymentStatusDefaultValue}]"
                placeholder="Select Payment Status"
              >
                <a-select-option v-for="status in paymentStatuses" :key="status.id" :value="status.id">{{
                    status.label
                  }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>
          <p v-if="isEmployeePaymentTypeSelected && isFullTimePaymentStatus()">
            <b>Payment Method</b>
            <a-form-item>
              <a-select
                v-decorator="['paymentMethod', {rules: [{required: false}], initialValue: paymentMethodDefaultValue}]"
                placeholder="Select PM"
              >
                <a-select-option v-for="method in paymentMethods" :key="method.id" :value="method.id">{{
                    method.label
                  }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>
          <p v-if="isEmployeePaymentTypeSelected">
            <b>Rate, $</b>
            <a-form-item>
              <a-input-number
                v-decorator="['rate', {rules: [{required: false}], initialValue: undefined}]"
                placeholder="Enter rate"
                :min="0"
              />
            </a-form-item>
          </p>
          <p v-if="isContractorPaymentTypeSelected">
            <b>Agency<span class="required-field">*</span></b>
            <a-form-item>
              <a-select
                v-decorator="[
                  'legalEntity',
                  {rules: [{required: isContractorPaymentTypeSelected, message: 'Select an legal entity'}]}]"
                placeholder="Please select agency"
              >
                <a-select-option
                  v-for="agency in agencies"
                  :key="agency.id"
                  :value="agency.id"
                >
                  {{ agency.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </p>
          <p v-if="isContractorPaymentTypeSelected">
            <b>Hourly Rate, $</b>
            <a-form-item>
              <a-input-number
                v-decorator="['rate', {rules: [{required: false}], initialValue: 0}]"
                placeholder="Enter rate"
                :min="0"
              />
            </a-form-item>
          </p>
          <p>
            <b>Allow Instant Payment</b>
            <span>
              <a-form-item>
                <a-checkbox
                  v-decorator="['allowInstantPayment', {rules: [{required: false, type: 'boolean', initialValue: false}]}]"
                />
              </a-form-item>
            </span>
          </p>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import {
  Card,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Select,
  notification,
  Button,
  Checkbox,
  Tooltip,
  Modal
} from 'ant-design-vue';
import ConstData from '@/helpers/const-data';
import api from "@/api";
import Util from "@/util";
import moment from 'moment-timezone';

const ScheduleType = Object.freeze({
  FIXED: 'fixed',
  SELF_SCHEDULE: 'self_schedule',
  FREE: 'free'
});

const PaymentType = Object.freeze({
  EMPLOYEE: 3,
  CONTRACTOR: 6,
  VOLUNTEER: 5
});

const PaymentStatus = Object.freeze({
  FULL_TIME: 'full_time',
  PART_TIME: 'part_time',
  ON_CALL: 'on_call',
  TERMINATED: 'terminated'
});

const PaymentMethod = Object.freeze({
  HOURLY: 0,
  SALARY: 1
});

export default {
  components: {
    'a-card': Card,
    'a-form': Form,
    'a-form-item': Form.Item,
    'a-input': Input,
    'a-input-password': Input.Password,
    'a-input-number': InputNumber,
    'a-date-picker': DatePicker,
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-button': Button,
    'a-checkbox': Checkbox,
    'a-tooltip': Tooltip,
    'a-modal': Modal
  },
  mixins: [api],
  props: ['associateNumber'],
  data() {
    return {
      form: undefined,
      rate: undefined,
      scheduleTypes: [
        {"name": "fixed", "label": "Fixed"},
        {"name": "self_schedule", "label": "Self Schedule"},
        {"name": "free", "label": "Free"}
      ],
      paymentTypes: [
        {"id": PaymentType.EMPLOYEE, "name": "Employee"},
        {"id": PaymentType.CONTRACTOR, "name": "Contractor"},
        {"id": PaymentType.VOLUNTEER, "name": "Volunteer"}
      ],
      paymentStatuses: [
        {"id": PaymentStatus.FULL_TIME, label: "Full Time"},
        {"id": PaymentStatus.PART_TIME, label: "Part Time"},
        {"id": PaymentStatus.ON_CALL, label: "On Call"},
        {"id": PaymentStatus.TERMINATED, label: "Terminated"}
      ],
      paymentMethods: [
        {"id": PaymentMethod.HOURLY, "label": "Hourly"},
        {"id": PaymentMethod.SALARY, "label": "Salary"}
      ],

      isEmployeePaymentTypeSelected: true,
      isContractorPaymentTypeSelected: false,

      paymentTypeDefaultValue: PaymentType.EMPLOYEE,
      paymentStatusDefaultValue: PaymentStatus.FULL_TIME,
      paymentMethodDefaultValue: PaymentMethod.HOURLY,
      scheduleTypeDefaultValue: ScheduleType.SELF_SCHEDULE,
      roleDefaultValue: undefined,

      namePattern: /^[A-Z][a-zA-Z\s-]{1,24}$/,
      associateNumberPattern: /^\d{6}$/,

      firstNameSuggestionMsg: 'Please enter your first name',
      lastNameSuggestionMsg: 'Please enter your last name',
      emailSuggestionMsg: 'Please enter your email',
      phoneSuggestionMsg: 'Please enter your phone number',
      passwordSuggestionMsg: 'Please enter password',
      associateNumberSuggestionMsg: 'Please enter associate number of new associate',

      nameErrorMsg: 'The first name or last name must be between 2 to 25 characters long and must be capitalized. ' +
        'Use letters (Latin alphabet), spaces, and hyphens only. ',
      emailErrorMsg: 'Incorrect email',
      phoneErrorMsg: 'Invalid phone number. It should start with a + and contain 10 to 15 digits.',
      passwordErrorMsg: 'Password must be longer than 6 characters.',
      associateNumberErrorMsg: 'Associate number must be exactly 6 digits.',
      ssnErrorMsg: 'Enter the SSN as 9 digits without hyphens',

      shiftActionsAutocompleteTooltip: 'The associate will automatically start and end first and second meal break'
        + ' and finish work to prevent policy violations in your company. Associate will self-sign the shift and have'
        + ' the ability to change tracked work hours',
      requireShiftConfirmationTooltip: 'Associate is required to get the shift confirmed before signing it',
      suspectedAssociateTooltip: 'Activate if an associate misbehaves while on the job, doesn\'t check in on the app, '
        + 'misses shifts, violates company policy or other rules',
      autoApprovePrTooltip: 'Payment requests from this associate will be approved automatically',

      labelCol: {},

      statesList: ConstData.usStates,
      countriesList: ConstData.countries,

      loadingForm: false,

      existingAssociateInfo: undefined,
      enabledCloneButton: false,

      openedDisablePreviousAssociateModal: false
    };
  },
  computed: {
    paymentType() {
      return this.form.getFieldValue('paymentType');
    },
    isContractorOrEmployeePaymentTypeSelected() {
      return this.isEmployeePaymentTypeSelected || this.isContractorPaymentTypeSelected;
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    serverDateFormat() {
      return this.$store.state.applicationState.serverDateFormat;
    },
    projectRoles() {
      return this.$store.state.applicationState.projectRoles;
    },
    agencies() {
      return this.$store.state.applicationState.currentProject.legal_entities;
    },

    fullName() {
      return this.existingAssociateInfo.first_name + ' ' + this.existingAssociateInfo.last_name;
    },
    email() {
      return this.existingAssociateInfo.email;
    },
    phoneNumber() {
      return this.existingAssociateInfo.phone;
    }
  },
  methods: {
    loadExistingAssociateData() {
      this.apiGetAssociate(this.associateNumber)
        .then((response) => {
          this.existingAssociateInfo = response.data;
        })
    },
    calculateDefaultDate() {
      return moment().subtract(18, 'years').endOf('day');
    },

    isFullTimePaymentStatus() {
      return this.form.getFieldValue('paymentStatus') === undefined
        || this.form.getFieldValue('paymentStatus') === PaymentStatus.FULL_TIME;
    },

    disabledDateAfterToday(current) {
      const now = moment()
      return current && current > now.endOf('day');
    },

    handleAssociateNumberChange() {
      const associateNumberValue = this.form.getFieldValue('associateNumber');

      if (associateNumberValue) {
        this.apiIsAssociateNumberBusy(associateNumberValue)
          .then((response) => {
            if (response.data.is_associate_number_busy) {
              this.form.setFields({
                associateNumber: {
                  value: '',
                  errors: [new Error(`Associate number ${associateNumberValue} is busy. Set another one`)]
                }
              })
            }
          })
      }
    },
    handlePaymentTypeChange(value) {
      switch (value) {
        case PaymentType.EMPLOYEE:
          this.isEmployeePaymentTypeSelected = true;
          this.isContractorPaymentTypeSelected = false;
          break;
        case PaymentType.CONTRACTOR:
          this.isEmployeePaymentTypeSelected = false;
          this.isContractorPaymentTypeSelected = true;
          break;
        default:
          this.isEmployeePaymentTypeSelected = false;
          this.isContractorPaymentTypeSelected = false;
      }
    },
    cloneAssociate(withDisablePrevious) {
      this.form.validateFields((err, values) => {
        this.openedDisablePreviousAssociateModal = false;
        if (err) {
          this.showIncorrectFormError();
          return;
        }

        this.loadingForm = true;

        const newAssociateNumber = values.associateNumber;

        let legalEntity;

        if (this.isContractorPaymentTypeSelected) {
          legalEntity = this.agencies.find(agency => agency.id == values.legalEntity);
        }

        const associateInformation = {
          associate_number: this.associateNumber, // to clone
          social_security_number: values.socialSecurityNumber ?? null,
          role: {id: values.projectRole},
          schedule_type: values.scheduleType,
          hire_date: values.hireDate,
          seniority_date: values.seniorityDate,
          terminated_date: values.terminatedDate,
          automatic_completing_actions: values.shiftActionsAutocomplete ?? false,
          required_shift_confirmation: values.requireShiftConfirmation ?? false,
          allow_instant_payment: values.allowInstantPayment ?? false,
          payment_type: Object.keys(PaymentType).find(key => PaymentType[key] === values.paymentType),
          pay_status: values.paymentStatus,
          pay_method: values.paymentMethod ? Object.keys(PaymentMethod)
            .find(key => PaymentMethod[key] === values.paymentMethod).toLowerCase() : 'hourly',
          rate: values.rate ? Util.convertMoneyToDb(values.rate) : 0,
          legal_entity: this.isContractorPaymentTypeSelected ? { ...legalEntity} : undefined,
          is_verified: true
        }

        if (!associateInformation.legal_entity && this.isContractorPaymentTypeSelected) {
          let message;

          if (!values.rate) {
            message = `Hourly rate for Agency "${this.legalEntity.name}" is not specified!`
          }

          this.showIncorrectFormError(message);
          this.loadingForm = false;

          return;
        }

        this.apiCloneAssociate(newAssociateNumber, associateInformation, withDisablePrevious)
          .then((response) => {
            const data = response.data;

            if (data.error_code && data.error_code != 0) {
              notification.warning({
                message: 'Associate Clone Error',
                description: data.msg
              })

              return;
            }

            notification.success({
              message: 'Associate Clone Success',
              description: 'The Associate was cloned'
            })
            this.endCloneAssociate();
          })
          .catch(() => {
            notification.warning({
              message: 'Associate Clone Error',
              description: 'Please try again later'
            })
          })
          .finally(() => this.loadingForm = false)
      })
    },
    showIncorrectFormError(msg) {
      notification.warning({
        message: 'Incorrect form',
        description: msg ?? 'Some fields are not filled in or are filled in incorrectly. Please check the fields'
      })
    },
    endCloneAssociate() {
      this.$emit('close');
    },

    toggleDisableSuggestionModal() {
      this.openedDisablePreviousAssociateModal = !this.openedDisablePreviousAssociateModal;
    },
  },
  beforeMount() {
    this.form = this.$form.createForm(this, {name: 'create_user_form'});

    const workerRole = this.$store.state.applicationState.projectRoles.find(
      (role) => role.name.toLowerCase() == 'worker'
    );

    if (workerRole) {
      this.roleDefaultValue = workerRole.id;
    }

  },
  mounted() {
    this.loadExistingAssociateData();
  }
}
</script>


<style scoped>
.content-view-block {
  min-width: 400px;
}

.create-user-with-associate-controls {
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.associate-number-label {
  word-wrap: normal;
}

</style>

<style lang="css" scoped src="../assets/css/form-styles.css"/>
