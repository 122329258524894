<template>
  <a-modal
    title="Add Pay Code"
    :visible="visible"
    :footer="null"
    @cancel="$emit('cancel')"
  >
    <div class="content-view">
      <div class="content-view-block w-full">
        <p>
          <b>Name <span class="required-field">*</span></b>
          <a-input
            class="form-input"
            v-model="name"
            placeholder="Enter name"
          />
        </p>
        <p>
          <b>Description <span class="required-field">*</span></b>
          <a-input
            class="form-input"
            v-model="description"
            placeholder="Enter description"
          />
        </p>
        <p>
          <b>Type <span class="required-field">*</span></b>
          <a-select
            v-model="type"
            class="form-input"
            placeholder="Enter type"
          >
            <a-select-option value="duration">Duration</a-select-option>
            <a-select-option value="total">Total</a-select-option>
          </a-select>
        </p>
        <p>
          <b>Value {{ type == 'total' ? ', $' : '' }} <span class="required-field">*</span></b>
          <a-input-number
            v-model="value"
            :min="0"
            class="form-input small"
            placeholder="Enter value"
          />
        </p>
        <p>
          <b>Conflicted <span v-if="type !== 'total'" class="required-field">*</span></b>
          <a-switch :disabled="type === 'total'" v-model="conflicted" />
        </p>
      </div>
    </div>
    <div class="flex align-items-center justify-content-center gap-2">
        <a-button
          type="primary"
          :disabled="disabledAddButton"
          @click="add"
        >
          Add
        </a-button>
        <a-button type="danger" @click="reset">Reset</a-button>
    </div>
  </a-modal>
</template>

<script>
import {Switch, Input, Select, Form, InputNumber, Button, notification} from "ant-design-vue";
import api from "@/api";

export default {
  components: {
    'a-switch': Switch,
    'a-input': Input,
    'a-input-number': InputNumber,
    'a-textarea': Input.TextArea,

    'a-button': Button,
    'a-button-group': Button.Group,

    'a-select': Select,
    'a-select-option': Select.Option,

    'a-form': Form
  },
  mixins: [api],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    disabledAddButton() {
      return !this.name || !this.description || !this.type || !this.value || this.loading;
    }
  },
  watch: {
    type(cur, prev) {
      this.conflicted = cur !== 'total';
    }
  },
  data() {
    return {
      name: undefined,
      description: undefined,
      type: undefined,
      value: 0,
      conflicted: true,

      loading: false
    }
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data());
    },
    add() {
      this.loading = true;
      this.apiAddPayCode({
        name: this.name,
        description: this.description,
        type: this.type,
        value: this.value,
        conflicted: this.conflicted
      })
        .then((res) => {
          if (res.body.error_code != 0) {
            notification['warning']({
              message: 'Error Adding Pay Code',
              description: 'Please try again later'
            })
            return;
          } else {
            notification['success']({
              message: 'Pay Code successfully added'
            })
          }

          this.$emit('added')
        })
        .finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>
.form-input {
  width: 200px;
}
.form-input.small {
  width: 100px;
}
</style>
