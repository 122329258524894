<template>
  <div>
    <a-modal
      v-model:visible="visible"
      title="Mark Paid Several"
      :footer="null"
      @cancel="cancelMarkPaid(false)"
    >
      <div class="content-view">
        <div class="content-view-block w-full">
          <p>
            <b>Associate</b>
            <a-select
              class="filters-select"
              ref="associateSelect"
              mode="multiple"
              v-model:value="associateNumbers"
              show-search
              :allowClear="true"
              placeholder="Associate"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              :default-active-first-option="false"
              :dropdown-match-select-width="false"
              :options="foundAssociates"
              :loading="loading"
              :disabled="!!role"
              @search="fetchAssociates"
              @change="handleSearchAssociatesChange"
              @focus="handleFocusAssociates"
            />
          </p>
          <p>
            <b>Job Classification</b>
            <a-select
              class="filters-select"
              v-model:value="role"
              :dropdown-match-select-width="false"
              :allow-clear="true"
              placeholder="Job Classification"
              :disabled="associateNumbers.length > 0"
              @change="handleRoleChange"
            >
              <a-select-option v-for="role in roles" :key="role.id" :value="role.id">
                {{ role.name }}
              </a-select-option>
            </a-select>
          </p>
          <p>
            <b>Range <span class="required-field">*</span></b>
            <a-range-picker
              :ranges="pickerRanges"
              class="filters-select"
              separator="-"
              :format="dateFormat"
              v-model:value="dates"
              @change="onDateRangeChange"
            />
          </p>
        </div>
      </div>
      <div class="flex align-items-center justify-content-center">
        <a-button
          type="primary"
          @click="markPaidForRange"
          :disabled="!isMarkPaidEnabled"
          :loading="markingPaid"
        >
          Mark Paid
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {Modal, Button, Select, DatePicker, notification} from "ant-design-vue";
import api from "@/api";
import moment from "moment-timezone";
import store from "@/store";

export default {
  components: {
    'a-modal': Modal,
    'a-button': Button,
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-range-picker': DatePicker.RangePicker
  },
  mixins: [api],
  props: {
    visible: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      associateNumbers: [],
      dates: undefined,
      role: undefined,
      foundAssociates: [],
      loading: false,
      markingPaid: false,
      searchUsersTimeout: undefined,
      dateFormat: store.state.applicationState.dateFormat,
      serverDateFormat: store.state.applicationState.serverDateFormat,
    }
  },
  computed: {
    roles() {
      return this.$store.state.applicationState.projectRoles;
    },
    pickerRanges() {
      return {
        'Today': [moment(), moment()],
        'This Week (From Today)': [moment(), moment().endOf('week')],
        'Next Week': [
          moment().add(1, 'week').startOf('week'),
          moment().add(1, 'week').endOf('week')
        ],
        'This Month (From Today)': [moment(), moment().endOf('month')],
        'Next Month': [
          moment().add(1, 'month').startOf('month'),
          moment().add(1, 'month').endOf('month')
        ]
      };
    },
    isMarkPaidEnabled() {
      return !!this.dates && this.dates.length == 2 || !this.markingPaid;
    },

    dateFrom() {
      if (!this.dates || this.dates.length !== 2) {
        return undefined;
      }

      return this.dates[0].format(this.serverDateFormat);
    },
    dateTo() {
      if (!this.dates || this.dates.length !== 2) {
        return undefined;
      }

      return this.dates[1].format(this.serverDateFormat);
    }
  },
  methods: {
    cancelMarkPaid(dataChanged) {
      this.$emit('cancel', dataChanged);
    },
    fetchAssociates(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout);
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.loading = true;
        this.apiSearchAssociate(value).then((resp) => {
          this.foundAssociates = resp.data.associate_list.map(user => ({
            label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
            key: user.employee_id,
            value: user.employee_id
          }));
          this.loading = false;
        });
      }, 1000);
    },
    handleFocusAssociates() {
      if (!this.associateNumbers.length) {
        this.fetchAssociates();
      }
    },
    handleSearchAssociatesChange(value) {
      if (value.length > 0) {
        this.role = undefined;
      }
    },
    handleRoleChange(value) {
      if (value) {
        this.associateNumbers = [];
      }
    },
    onDateRangeChange(dates) {
      this.dates = dates;
    },
    markPaidForRange() {
      this.markingPaid = true;
      this.apiMarkShiftAsPaidOnRange(this.dateFrom, this.dateTo, this.associateNumbers, this.role)
        .then((response) => {
          if (response.data.error_code !== 0) {
            notification['error']({
              message: 'Error',
              description: 'An error occurred when marking shifts as paid. Please try again'
            })
          } else {
            notification['success']({
              message: 'Success',
              description: 'Shifts are marked as paid'
            })
            this.cancelMarkPaid(true);
          }
        })
        .finally(() => {
          this.markingPaid = false;
        })
    }
  }
}
</script>

<style scoped>
.filters-select {
  width: 250px;
}
</style>
