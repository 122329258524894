<template>
  <div>
    <div class="flex flex-column gap-3 align-items-center">
      <a-upload-dragger
          class="upload-dragger"
          :file-list="fileList"
          :before-upload="beforeUpload"
          :remove="handleRemove"
          :multiple="true"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox"/>
        </p>
        <p class="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p class="ant-upload-hint">
          Support for upload of images
        </p>
      </a-upload-dragger>
      <a-button
          class="upload-button"
          type="primary"
          :disabled="fileList.length == 0 || uploading"
          @click="upload"
      >
        Upload
      </a-button>
    </div>
  </div>
</template>

<script>
import {Button, notification, Upload, Icon} from "ant-design-vue";
import api from "@/api";

export default {
  props: ['shiftId', 'newShift', 'attachmentsFileIds'],
  components: {
    'a-upload-dragger': Upload.Dragger,
    'a-button': Button,
    'a-icon': Icon
  },
  data() {
    return {
      fileList: [],
      uploading: false
    }
  },
  mixins: [api],
  methods: {
    beforeUpload(file) {
      if (file.type.indexOf('image') != 0) {
        notification['error']({
          message: 'Error',
          description: 'You can only upload image files'
        })
      } else {
        this.fileList = [...this.fileList, file];
      }
      return false;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);

      this.fileList = newFileList;
    },
    async upload() {
      try {
        this.uploading = true;
        const response = await this.apiUploadFiles(this.fileList);

        if (!response.data || !response.data.ids || response.data.ids.length === 0) {
          throw new Error('Error occurred while uploading files.');
        }

        const ids = response.data.ids;

        if (this.newShift) {
          this.$emit('attachments-saved', ids);
          return;
        }

        await this.apiAddAttachmentsToShift(this.shiftId, ids)
            .catch(() => {
              throw new Error('Error occurred while attaching files to shift. Please try again');
            })
      } catch (error) {
        this.showNotification('error', 'Error',  'An error occurred. Please try again.');
      } finally {
        this.uploading = false;
        this.fileList = [];

        if (!this.newShift) {
          this.showNotification('success', 'Success', 'File(-s) has been uploaded');
          this.$emit('document-uploaded');
        } else {
          this.showNotification('success', 'Success', 'File(-s) has been attached to new shift');
        }
      }
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    }
  },
  mounted() {
    if (this.attachmentsFileIds && this.attachmentsFileIds.length > 0) {
      this.fileList = this.attachmentsFileIds;
    }
  }
}
</script>

<style scoped>
.upload-dragger {
  width: 90%;
}
.upload-button {
  width: 100px;
}
</style>
