<template>
  <div>
    <h2 class="view-header">Associate Shifts</h2>
    <v-tour name="shiftsTour" :steps="tourSteps" :callbacks="callbacks" :options="vueTourOptions"/>
    <div class="no_print">
      <associate-shifts-filters
        :current-filters="filters"
        :visible="associateShiftsFiltersVisible"
        :key="filtersModalKey"
        @apply="applyFilters"
      />

      <mark-paid-several
        :visible="visibleMarkPaidSeveralModal"
        :key="markPaidSeveralModalKey"
        @cancel="cancelMarkPaidSeveral"
      />

      <a-modal
        v-model="visibleAssociatePayCodeModal"
        width="1000px"
        :footer="null"
        @cancel="closeAssociatePayCodeModal"
      >
        <associate-pay-code
          :shiftId="editingAssociateShiftId"
          @on-add="handleAddOrEditAssociatePayCode"
          @on-edit="handleAddOrEditAssociatePayCode"
          @on-cancel="closeAssociatePayCodeModal"
        />
      </a-modal>

      <a-modal
        title="Shift Actions List"
        v-model="visibleLocationsModal"
        width="850px"
        :footer="null"
        @cancel="closeLocationsInfo"
      >
        <locations :list="currentLocationsList"/>
      </a-modal>

      <payment-info style="overflow-y: auto; height: 600px" :key="paymentRequestKey" :payment-id="openedPaymentId"/>

      <add-multiple-shifts
        :key="addMultipleShiftsModalKey"
        :visible="visibleAddMultipleShiftsModal"
        @close="closeMultipleShiftsModal"
      />

      <a-modal
        title="Authorizations List"
        width="1200px"
        v-model="authorizationsListVisible"
        @cancel="handleAuthListCancel"
      >
        <shift-auth-list-modal
          ref="shiftAuthListModal"
          :auths-list="currentShiftAuthsList"
          @change="handleSelectedAuthListChange"
        />
        <template slot="footer">
          <a-button
            type="primary"
            :disabled="selectedCurrentShiftAuthsList.length == 0"
            @click="initAcceptSelectedAuthList"
          >
            Accept
          </a-button>
          <a-button
            type="danger"
            :disabled="selectedCurrentShiftAuthsList.length == 0"
            @click="initDenySelectedAuthList"
          >
            Deny
          </a-button>
          <a-button
            @click="handleAuthListCancel"
          >
            Cancel
          </a-button>
        </template>
      </a-modal>
      <a-modal
        width="600px"
        title="Reason of Deny Authorization"
        v-model="denyReasonVisible"
        @ok="confirmDeny"
      >
        <shift-auth-deny-form ref="shiftAuthDenyForm" @reason="setResolveComment" @params="setDenyParams"/>
      </a-modal>
      <a-modal
        width="600px"
        title="Accept Authorization Comment"
        v-model="acceptCommentVisible"
        @ok="confirmAccept"
      >
        <a-input placeholder="Optional" v-model="resolveComment"/>
      </a-modal>

      <a-modal
        v-model="visibleAssociateShiftModal"
        width="950px"
        :footer="null"
        @cancel="closeAssociateShiftModal"
      >
        <associate-shift
          :key="associateShiftComponentKey"
          :shift-id="editingAssociateShiftId"
          :associate-number="associateNumberOfChangingShift"
          @on-add-or-edit="closeAssociateShiftModalWithChanges"
          @on-shift-deletion="openShiftDeletionConfirmationModal"
          @on-associate-create="closeAssociateShiftModalToCreateNewAssociate"
          @shift-exists="handleShiftExists"
        />
      </a-modal>

      <send-notification :associate="associateProfileToSendSms" @close="closeSmsModal"/>

      <a-modal
        title="Shift Deletion"
        v-model="visibleShiftDeletionConfirmationModal"
        :closable="false"
        @cancel="closeShiftDeletionModal"
      >
        <div>
          <span v-if="deletingVersion && deletingVersion > 1">
            Are you sure you want to delete shift #{{ deletingAssociateShiftId }} or just revoke last signature?
          </span>
          <span v-else-if="deletingVersion && deletingVersion == 1">
            Are you sure you want to delete shift #{{ deletingAssociateShiftId }}?
          </span>
        </div>
        <template slot="footer">
          <a-button type="danger" @click="deleteShift">Delete Shift</a-button>
          <a-button v-if="deletingVersion && deletingVersion > 1" type="danger" @click="revokeSignature">
            Revoke Signature
          </a-button>
          <a-button type="primary" @click="closeShiftDeletionModal">No</a-button>
        </template>
      </a-modal>

      <shift-overlap-modal
        :visible="visibleShiftAlreadyExistsModal"
        :overlapping-shifts="overlappingShifts"
        @edit-existing-shift="editExistingShift"
        @edit-new-shift="closeExistingShiftModal"
      />

      <a-modal
        title="Shift Confirmation"
        v-model="visibleShiftConfirmationModal"
        :footer="null"
        @cancel="closeConfirmationInfo"
      >
        <shift-confirmation :confirmation="currentConfirmation"/>
      </a-modal>

      <create-new-associate
        :visible="visibleNewAssociateModal"
        @close="closeNewAssociateModal"
      />

      <div class="table_controls_and_data">
        <div class="table_data_wrapper" style="padding: 0 0 0 35px">
        </div>
        <div class="table_controls_wrapper">
          <div class="table_predefined_filters" style="flex-shrink: 1">
            <a-button
              icon="diff"
              :disabled="loading"
              @click="addShift"
            >
              Add
            </a-button>
            <a-button
              icon="copy"
              :disabled="loading"
              @click="openMultipleShiftsModal"
            >
              Add Several
            </a-button>
            <a-button
              icon="dollar"
              :disabled="loading"
              @click="openMarkPaidSeveralModal"
            >
              Mark Paid Several
            </a-button>
            <a-button-group>
              <a-button
                icon="filter"
                :disabled="loading"
                @click="showAssociateShiftsFilterModal"
              />
              <a-button
                icon="undo"
                :disabled="loading || disableResetButton"
                @click="resetAssociateShiftsFilters"
              />
            </a-button-group>
            <a-date-picker
              v-model="dateFrom"
              :disabled-date="disabledStartDate"
              :disabled="loading"
              format="MM/DD/YYYY"
              placeholder="Start Date"
              style="flex: 1 1 0"
            />
            <a-date-picker
              v-model="dateTo"
              :disabled-date="disabledEndDate"
              :disabled="loading"
              format="MM/DD/YYYY"
              placeholder="End Date"
              style="flex: 1 1 0"
            />
          </div>
        </div>
      </div>
      <a-table
        size="small"
        :columns="columns"
        row-key="shift_id"
        :data-source="tableData"
        :loading="loading"
        :style="{ whiteSpace: 'pre' }"
        :locale="{ emptyText: 'Shifts Not Found' }"
        :pagination="pagination"
        :expanded-row-render="expandedRowRender"
        :dropdown-match-select-width="false"
        :expand-row-by-click="true"
        @change="handleTableChange"
      >
        <span slot="associate" slot-scope="text, record" :key="record.user_avatar">
          <grouped-associate-info
            :first-name="record.first_name"
            :last-name="record.last_name"
            :photo-url="record.user_avatar"
            :unverified="record.unverified"
          />
        </span>
        <span class="tags-cell" slot="status" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">
              {{ getShiftStatusTagText(record.status) }}
            </template>
            <a-tag class="auth-tag" :color="record.pay_code_name ? '#d47aff' : getAuthStatusTagColor(record.status)">
            {{ record.pay_code_name ?? getShiftStatusTagLabel(record.status) }}
            </a-tag>
          </a-tooltip>
          <a-tooltip v-if="record.retro_shift">
            <template slot="title">
              This is a shift that has been worked or is due to be worked more than one month ago
            </template>
            <a-tag class="auth-tag" :color="statusTagColors.GRAY">
              Retro Shift
            </a-tag>
          </a-tooltip>
        </span>
        <span slot="authorizations" slot-scope="text, record">
          <span
            v-if="record.authorizations && record.authorizations.length > 0"
            class="flex flex-column gap-2"
          >
            <a-tag
              class="auth-tag"
              v-for="auth in record.authorizations"
              :key="auth.id"
              :color="getShiftAuthStatusTagColor(auth)"
            >
            {{ convertType(auth.type) }}
           </a-tag>
          </span>
          <span v-else>-</span>
        </span>
        <span slot="actions" slot-scope="text, record">
          <a-button-group size="small">
            <a-button
              id="edit-shift-btn"
              title="Edit Shift"
              icon="edit"
              @click="editShift(record)"
            />
            <a-button
              id="show-auths-btn"
              title="Show Auths"
              :disabled="!record.authorizations || record.authorizations.length == 0"
              icon="file-done"
              @click="showShiftAuthorizations(record)"
            />
            <a-button
              id="send-msg-btn"
              title="Send Message" icon="message" :disabled="!record.phone" @click="openSmsSendModal(record)"/>
            <a-button
              id="payment-request-btn"
              icon="dollar"
              :disabled="!record.payment_request"
              @click="openPaymentRequest(record.payment_request)"
            />
            <a-button
              icon="environment"
              size="small"
              :disabled="locationsLoading || !!record.pay_code_name"
              @click="showLocationsInfo(record)"
            />
            <a-button
              id="delete-shift-btn"
              title="Delete Shift"
              icon="delete"
              :disabled="!!record.pay_code_name"
              @click="openShiftDeletionConfirmationModal(record.shift_id, record.version)"
            />
          </a-button-group>
        </span>
        <span slot="locationsList" slot-scope="text, record">
          <a-button
            icon="environment"
            size="small"
            :disabled="locationsLoading"
            @click="showLocationsInfo(record)"
          >
              Open
            </a-button>
        </span>
        <span slot="confirmation" slot-scope="text, record">
          <template v-if="!record.confirmation">
            No
          </template>
          <template v-else>
            <a-button
              icon="file-done"
              size="small"
              @click="showConfirmationInfo(record.confirmation)"
            >
              Open
            </a-button>
          </template>
        </span>
        <span slot="origin" slot-scope="text, record">
          <a-tag :color="getOriginColor(record.origin)">
            {{ record.origin }}
          </a-tag>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>

import {
  Col,
  DatePicker,
  Input,
  Row,
  Icon,
  Button,
  Space,
  Pagination,
  Table,
  Tag,
  Select,
  Checkbox,
  Modal,
  Tooltip,
  notification, Menu, Dropdown
} from 'ant-design-vue';
import UsersListSelect from '@/components/users-list-select.vue'

import api from '@/api';
import shiftsHelper from '@/helpers/shifts';

import Util from '@/util';


import AssociateShift from "../components/associate-shift.vue";
import ShiftAttachmentsUpload from "@/components/shift-attachments-upload.vue";
import SendNotification from "@/components/send-notification.vue";
import ShiftConfirmation from "@/components/shift-confirmation.vue";

import moment from "moment-timezone";
import Locations from "@/components/locations.vue";
import shiftAuths from "@/helpers/shift-auths";
import ShiftAuthListModal from "@/components/shift-auth-list-modal.vue";
import ShiftAuthDenyForm from "@/components/shift-auth-deny-form.vue";
import ShiftOverlapModal from "@/components/shift-overlap-modal.vue";
import AssociateShiftsFilters from "@/components/associate-shifts-filters.vue";
import AddMultipleShifts from "@/components/add-multiple-shifts.vue";
import CreateNewAssociate from "@/components/create-new-associate.vue";

import {
  getShiftStatusTagLabel,
  getAuthStatusTagColor,
  getShiftStatusTagText, statusTagColors, vueTourOptions
} from "@/const";
import PaymentInfo from "@/views/payment-info.vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import MarkPaidSeveral from "@/components/mark-paid-several.vue";
import AssociatePayCode from "@/views/associate-pay-code.vue";


const AuthStatus = Object.freeze({
  INITIAL: 'initial',
  ACCEPTED: 'accepted',
  DECLINED: 'declined'
});

const filtersBaseState = Object.freeze({
  associateNumber: undefined,
  status: undefined,
  role: undefined,
});

const initialPagination = {
  showSizeChanger: true,
  pageSizeOptions: ['5', '10', '20', '50'],
  pageSize: 50,
  total: 0,
  current: 1
}

function getTableHeaderByKey(key) {
  return `th[key="${key}"]`;
}

export default {
  components: {
    AssociatePayCode,
    SendNotification,
    ShiftAttachmentsUpload,
    Icon,
    Space,
    UsersListSelect,
    ShiftConfirmation,
    'a-table': Table,
    'a-date-picker': DatePicker,
    'a-input': Input,
    'a-input-search': Input.Search,
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-row': Row,
    'a-col': Col,
    'a-icon': Icon,
    'a-tag': Tag,
    'a-button': Button,
    'a-button-group': Button.Group,
    'a-pagination': Pagination,
    'a-checkbox': Checkbox,
    'a-tooltip': Tooltip,
    'a-modal': Modal,
    'a-menu': Menu,
    'a-menu-item': Menu.Item,
    'a-dropdown': Dropdown,
    'associate-shift': AssociateShift,
    'locations': Locations,
    'shift-auth-list-modal': ShiftAuthListModal,
    'shift-auth-deny-form': ShiftAuthDenyForm,
    'shift-overlap-modal': ShiftOverlapModal,
    'associate-shifts-filters': AssociateShiftsFilters,
    'add-multiple-shifts': AddMultipleShifts,
    'create-new-associate': CreateNewAssociate,
    'payment-info': PaymentInfo,
    'grouped-associate-info': GroupedAssociateInfo,
    'mark-paid-several': MarkPaidSeveral
  },

  mixins: [api, shiftsHelper],

  props: ['dayRoute'],

  data() {
    return {
      dateFrom: null,
      dateTo: null,

      tableData: [],

      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '50'],
        pageSize: 50,
        total: 0,
        current: 1
      },

      filters: {
        associateNumber: undefined,
        status: undefined,
        role: undefined,
      },
      associateShiftsFiltersVisible: false,
      filtersModalKey: 0,

      associateNumberOfChangingShift: undefined,

      searching_users: false,

      visibleAssociateShiftModal: false,
      visibleShiftAlreadyExistsModal: false,
      visibleShiftDeletionConfirmationModal: false,

      creatingNewAssociateShift: false,

      overlappingShifts: [],

      editingAssociateShiftId: undefined,
      deletingAssociateShiftId: undefined,
      deletingVersion: undefined,
      associateShiftComponentKey: 0,

      columns: [
        {
          title: 'ID',
          dataIndex: 'shift_id',
          key: 'shift_id',
          width: 80,
          sorter: true,
          align: 'center'
        },
        {
          title: 'Associate',
          dataIndex: 'name',
          scopedSlots: {customRender: 'associate'},
          sorter: true,
          align: 'center'
        },
        {
          title: 'Job Classification',
          dataIndex: 'role',
          key: 'role',
          sorter: true,
          align: 'center'
        },
        {
          title: 'Status',
          key: 'status',
          scopedSlots: {customRender: 'status'},
          align: 'center',
        },
        {
          title: 'Authorizations',
          key: 'authorizations',
          scopedSlots: {customRender: 'authorizations'},
          align: 'center'
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          align: 'center'
        },
        // {
        //   title: 'Shift Actions',
        //   scopedSlots: {customRender: 'locationsList'},
        //   key: 'geo_issues',
        //   align: 'center'
        // },
        // {
        //   title: 'Confirmation',
        //   scopedSlots: {customRender: 'confirmation'},
        //   key: 'confirmation',
        //   align: 'center'
        // },
        {
          title: 'Origin',
          key: 'origin',
          scopedSlots: {customRender: 'origin'},
          align: 'center'
        },
        {
          title: 'Actions',
          key: 'actions',
          scopedSlots: {customRender: 'actions'},
          align: 'center'
        }
      ],


      printing: false,
      loading: false,

      addAttachmentsVisible: false,
      addAttachmentsShiftId: undefined,

      associateProfileToSendSms: undefined,

      currentConfirmation: undefined,
      currentLocationsList: undefined,

      currentShiftAuthsList: [],
      selectedCurrentShiftAuthsList: [],
      authorizationsListVisible: false,

      denyReasonVisible: false,
      acceptCommentVisible: false,

      currentShiftRecord: undefined,

      resolveComment: "",
      locationsLoading: false,

      visibleAddMultipleShiftsModal: false,
      addMultipleShiftsModalKey: 0,

      visibleMarkPaidSeveralModal: false,
      markPaidSeveralModalKey: 0,

      visibleNewAssociateModal: false,

      visibleAssociateSelectionModal: false,

      paymentRequestKey: 0,
      openedPaymentId: undefined,

      statusesNotInStatusList: ['suspected', 'completed_only'],
      tourSteps: [
        {
          target: '.view-header',
          header: {
            title: "Associate Shifts Table"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: this.shiftsTourBeforeStartCallback,
          content: "This section displays detailed information about each shift worked by your associates. Click on a shift to see more details."
        },
        {
          target: getTableHeaderByKey('status'),
          header: {
            title: "Status"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: `
            <p>Each shift can have various statuses: </p>
            <ul style="text-align: left">
              <li>Scheduled: Shift has been scheduled.</li>
              <li>Started: Shift is currently in progress.</li>
              <li>Missed: Shift has been missed.</li>
              <li>Cancelled: Shift has been cancelled.</li>
              <li>Completed: Completed</li>
              <li>Unpaid: Payment for the shift is pending.</li>
              <li>Paid: Shift has been paid.</li>
              <li>Not Signed: Shift report not signed.</li>
              <li>Auth Issues: There are authorization issues with the shift.</li>
              <li>Ready To Pay: Shift has not been paid.</li>
              <li>Payment Cancelled: Payment was cancelled.</li>
              <li>Payment In Progress: Payment processing is in progress.</li>
              <li>Draft Payment: Payment request is in draft status.</li>

            </ul>
          `
        },
        {
          target: getTableHeaderByKey('authorizations'),
          header: {
            title: "Authorizations"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: `
          <p>Authorizations indicate rule violations during the shift. There are various types of authorizations:</p>
          <ul style="text-align: left">
            <li>Early Start</li>
            <li>Late Start</li>
            <li>No Meal Break</li>
            <li>Early Meal Break End</li>
            <li>No Second Meal Break</li>
            <li>Early Second Meal Break End</li>
            <li>Early End</li>
            <li>Late End</li>
            <li>Edit Shift</li>
            <li>Overtime</li>
            <li>Outside of Work Area</li>
            <li>Absence on Shift</li>
            <li>Manually Shift</li>
            <li>Signed Actual Time Discrepancy</li>
            <li>No Rest Break</li>
          </ul>
          `
        },

        {
          target: 'tbody tr .ant-table-row-expand-icon',
          header: {
            title: "Shift Times"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: "This buttons opens shift details"
        },
        {
          target: '[data-row-key="signed"]',
          header: {
            title: "Signed Times"
          },
          params: {
            placement: "top",
            enableScrolling: false
          },
          before: this.beforeSignedShiftStep,
          content: "This section shows the times signed off by the associate, which they consider accurate."
        },
        {
          target: '[data-row-key="actual"]',
          header: {
            title: "Actual Times"
          },
          params: {
            placement: "top",
            enableScrolling: false
          },
          content: "These are the times tracked by the application during the shift."
        },
        {
          target: '[data-row-key="scheduled"]',
          header: {
            title: "Scheduled Times"
          },
          params: {
            placement: "top",
            enableScrolling: false
          },
          content: "These are the times tracked by the application during the shift."
        },
        {
          target: '#actions-btn-group',
          header: {
            title: "Actions"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: "Here you can perform various actions on the shift. Let's explore each option available."
        },
        {
          target: "#edit-shift-btn",
          header: {
            title: "Editing a Shift"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: this.beforeAuthsButtonStep,
          content: "Clicking the 'Edit' button opens a modal window where you can edit the shift details."
        },
        {
          target: '#shift-times',
          header: {
            title: "Shift Times",
          },
          params: {
            placement: "left",
            enableScrolling: false
          },
          before: this.beforeEditShiftStep,
          content: "Shift Times contains the aforementioned shift times (Signed, Actual, Scheduled)."
        },
        {
          target: '#signed-work-time',
          header: {
            title: "Shift Times",
          },
          params: {
            placement: "right",
            enableScrolling: false
          },
          before: this.beforeEditShiftStep,
          content: `
          <p>You can modify the shift details using these fields. If you save changes that contradict company policies, authorizations might appear.</p>
          <p>To save a shift with authorisations and release the associate from signing them - use the "Auto Sign" checkbox in the modal confirmation box that will appear when you try to save the shift.</p>
          `
        },
        {
          target: "#show-auths-btn",
          header: {
            title: "Authorizations Button"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: this.beforeAuthsButtonStep,
          content: "Clicking this button allows you to view any authorizations associated with the shift. When selecting authorisations from the list, you can accept or deny them"
        },
        {
          target: "#send-msg-btn",
          header: {
            title: "Send Message Button"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: "Use this button to send a message to the associate, requesting them to discuss or take action regarding their shift."
        },
        {
          target: "#payment-request-btn",
          header: {
            title: "Payment Request Button"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: "Use this button to open a payment request containing the given shift (if it exists)"
        },
        {
          target: "#delete-shift-btn",
          header: {
            title: "Delete Shift Button"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: "This button allows you to delete the shift and all its associated information."
        },
      ],
      callbacks: {
        onStart: this.shiftTourStartCallback,
        onSkip: this.shiftTourFinishCallback,
        onFinish: this.shiftTourFinishCallback
      },

      signedShiftStepPassed: false,
      visibleAssociatePayCodeModal: false
    }
  },

  computed: {
    statusTagColors() {
      return statusTagColors
    },
    dateFromString() {
      if (this.dateFrom == null)
        return null;
      return this.dateFrom.format('YYYY-MM-DD');
    },

    dateToString() {
      if (this.dateTo == null)
        return null;
      return this.dateTo.format('YYYY-MM-DD');
    },
    nestedColumns() {
      return [
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          className: 'column-wrap'
        },
        {
          title: 'Start',
          key: 'start',
          customRender: (text, record) => {
            const colorClass = record.type === 'Signed' && record.signed_start_diff_info ? record.signed_start_diff_info.color : '';
            const mainDiv = <div
              class={`${colorClass}`}>{record.start && record.start.value ? record.start.value : record.start}</div>;

            if (record.signed_start_diff_info) {
              const tooltipTitle = `Signed start time differs ${record.signed_start_diff_info.difference} minutes from tracked time.`;
              return <a-tooltip>
                <template slot="title">
                  {tooltipTitle}
                </template>
                {mainDiv}
              </a-tooltip>;
            }

            return mainDiv;
          }
        },
        {
          title: 'End',
          key: 'end',
          customRender: (text, record) => {
            const colorClass = record.type === 'Signed' && record.signed_end_diff_info ? record.signed_end_diff_info.color : '';
            const mainDiv = <div
              class={`${colorClass}`}>{record.end && record.end.value ? record.end.value : record.end}</div>;

            if (record.signed_end_diff_info) {
              const tooltipTitle = `Signed end time differs ${record.signed_end_diff_info.difference} minutes from tracked time`;
              return <a-tooltip>
                <template slot="title">
                  {tooltipTitle}
                </template>
                {mainDiv}
              </a-tooltip>;
            }

            return mainDiv;
          }
        },
        {
          title: 'Dur',
          dataIndex: 'duration'
        },
        {
          title: 'Work Hrs',
          dataIndex: 'work_hours'
        },
        {
          title: 'Pay Hrs',
          dataIndex: 'pay_hours'
        },
        {
          title: 'L1 Start',
          dataIndex: 'first_lunch_start',
          key: 'first_lunch_start',
          // customRender: (text, record) => {
          //   const colorClass = record.type === 'Signed' ? this.getColorClass(record.colors.lunch1_color) : '';
          //   return <div
          //     class={`${colorClass}`}>{record.first_lunch_start && record.first_lunch_start.value ? record.first_lunch_start.value : record.first_lunch_start}</div>;
          // }
        },
        {
          title: 'L1 End',
          dataIndex: 'first_lunch_end',
          key: 'first_lunch_end',
          // customRender: (text, record) => {
          //   const colorClass = record.type === 'Signed' ? this.getColorClass(record.colors.lunch1_color) : '';
          //   return <div
          //     class={`${colorClass}`}>{record.first_lunch_end && record.first_lunch_end.value ? record.first_lunch_end.value : record.first_lunch_end}</div>;
          // }
        },
        {
          title: 'L1 Dur',
          dataIndex: 'first_lunch_duration'
        },
        {
          title: 'L2 Start',
          dataIndex: 'second_lunch_start',
          key: 'second_lunch_start',
          // customRender: (text, record) => {
          //   const colorClass = record.type === 'Signed' ? this.getColorClass(record.colors.lunch2_color) : '';
          //   return <div
          //     class={`${colorClass}`}>{record.second_lunch_start && record.second_lunch_start.value ? record.second_lunch_start.value : record.second_lunch_start}</div>;
          // }
        },
        {
          title: 'L2 End',
          dataIndex: 'second_lunch_end',
          key: 'second_lunch_end',
          // customRender: (text, record) => {
          //   const colorClass = record.type === 'Signed' ? this.getColorClass(record.colors.lunch2_color) : '';
          //   return <div
          //     class={`${colorClass}`}>{record.second_lunch_end && record.second_lunch_end.value ? record.second_lunch_end.value : record.second_lunch_end}</div>;
          // }
        },
        {
          title: 'L2 Dur',
          dataIndex: 'second_lunch_duration'
        }
      ]
    },

    visibleShiftConfirmationModal() {
      return this.currentConfirmation;
    },

    visibleLocationsModal() {
      return this.currentLocationsList !== undefined;
    },

    user() {
      return this.$store.state.applicationState.user;
    },

    roles() {
      return this.$store.state.applicationState.projectRoles;
    },

    filtersAreEmpty() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },
    disableResetButton() {
      return this.filtersAreEmpty;
    },

    status() {
      return this.statusesNotInStatusList.includes(this.filters.status) ? null : this.filters.status;
    },
    showCompletedOnly() {
      return this.filters.status === 'completed_only';
    },
    vueTourOptions() {
      return vueTourOptions
    },
  },
  watch: {
    filters: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.sendToFirstPage();
          this.loadData();
        }
      },
      deep: true
    },
    dateFromString() {
      this.sendToFirstPage();
      this.loadData();
    },
    dateToString() {
      this.sendToFirstPage();
      this.loadData();
    },
    pagination: {
      handler() {
        localStorage.setItem('shiftsPagination', JSON.stringify(this.pagination))
      },
      deep: true
    }
  },
  methods: {
    convertType: shiftAuths.convertType,
    getAuthStatusTagColor,
    getShiftStatusTagLabel,
    getShiftStatusTagText,


    getSignedStartDiff(shift) {
      const {raw_start, raw_signed_start} = shift;

      const startTime = moment(raw_start);
      const signedStartTime = moment(raw_signed_start);

      const differenceInMinutes = Math.abs(startTime.diff(signedStartTime, 'minutes'));

      const result = {
        difference: differenceInMinutes,
        color: null
      };

      if (differenceInMinutes > 30) {
        result.color = 'orange-highlight';
      }

      return result;
    },
    getSignedEndDiff(shift) {
      const {raw_end, raw_signed_end} = shift;

      const endTime = moment(raw_end);
      const signedEndTime = moment(raw_signed_end);

      const differenceInMinutes = Math.abs(endTime.diff(signedEndTime, 'minutes'));

      const result = {
        difference: differenceInMinutes,
        color: null
      };

      if (differenceInMinutes > 30) {
        result.color = 'orange-highlight';
      }

      return result;
    },

    updateTotal(newTotal) {
      this.pagination = {...this.pagination, total: newTotal};
    },
    async loadData(sorter) {
      // const shifts = localStorage.getItem('shifts');
      // if (shifts) {
      //   this.tableData = JSON.parse(shifts);
      //   return;
      // }
      this.loading = true;

      await this.apiGetAssociateShifts(
        this.filters.associateNumber ? [this.filters.associateNumber] : [],
        this.dateFromString,
        this.dateToString,
        this.filters.role ?? null,
        this.status ?? null,
        this.pagination,
        this.showCompletedOnly,
        sorter
      )
        .then((data) => {
          this.tableData = [];
          this.updateTotal(data.body.total);
          data.body.shifts_info.forEach(shift => {
            const formattedDurations = this.formatShiftDurations(shift);

            this.tableData.push({
              rowKey: shift.shift_id,
              "shift_id": shift.shift_id,
              "first_name": Util.capitalize(shift.associate.first_name),
              "last_name": Util.capitalize(shift.associate.last_name),
              "phone": shift.associate.phone,
              "associate_number": shift.associate_number,
              "role": shift.associate.role != null ? shift.associate.role : '-',
              "date": this.getDateInfo(shift),

              "scheduled_date": formattedDurations.scheduled.date,
              "actual_date": formattedDurations.actual.date,
              "signed_date": formattedDurations.signed.date,

              "scheduled_start": formattedDurations.scheduled.start,
              "scheduled_end": formattedDurations.scheduled.end,
              "actual_start": formattedDurations.actual.start,
              "actual_end": formattedDurations.actual.end,
              "signed_start": formattedDurations.signed.start,
              "signed_end": formattedDurations.signed.end,

              "scheduled_duration": shift.scheduled_duration_mins ? this.minutesToHoursAndMinutes(shift.scheduled_duration_mins) : '-',
              "work_duration": shift.work_duration_mins ? this.minutesToHoursAndMinutes(shift.work_duration_mins) : '-',
              "signed_duration": shift.signed_duration_mins ? this.minutesToHoursAndMinutes(shift.signed_duration_mins) : '-',

              "work_hours": this.formatWorkHours(
                shift.signed_start ? moment.utc(shift.signed_start) : null,
                shift.signed_end ? moment.utc(shift.signed_end) : null,
                shift.signed_lunch_start ? moment.utc(shift.signed_lunch_start) : null,
                shift.signed_lunch_end ? moment.utc(shift.signed_lunch_end) : null,
                shift.signed_second_lunch_start ? moment.utc(shift.signed_second_lunch_start) : null,
                shift.signed_second_lunch_end ? moment.utc(shift.signed_second_lunch_end) : null
              ),
              "pay_hours": this.formatPayHours(
                shift.scheduled_start ? moment.utc(shift.scheduled_start) : null,
                shift.scheduled_end ? moment.utc(shift.scheduled_end) : null,
                shift.signed_start ? moment.utc(shift.signed_start) : null,
                shift.signed_end ? moment.utc(shift.signed_end) : null,
                shift.signed_lunch_start ? moment.utc(shift.signed_lunch_start) : null,
                shift.signed_lunch_end ? moment.utc(shift.signed_lunch_end) : null,
                shift.signed_second_lunch_start ? moment.utc(shift.signed_second_lunch_start) : null,
                shift.signed_second_lunch_end ? moment.utc(shift.signed_second_lunch_end) : null
              ),

              "actual_first_lunch_start": formattedDurations.actual.first_lunch_start,
              "actual_first_lunch_end": formattedDurations.actual.first_lunch_end,
              "signed_first_lunch_start": formattedDurations.signed.first_lunch_start,
              "signed_first_lunch_end": formattedDurations.signed.first_lunch_end,

              "actual_first_lunch_duration": shift.lunch_duration_mins ? Util.formatMinutesToHoursAndMinutesDuration(shift.lunch_duration_mins) : '-',
              "signed_first_lunch_duration": shift.signed_lunch_duration_mins ? Util.formatMinutesToHoursAndMinutesDuration(shift.signed_lunch_duration_mins) : '-',

              "actual_second_lunch_start": formattedDurations.actual.second_lunch_start,
              "actual_second_lunch_end": formattedDurations.actual.second_lunch_end,
              "signed_second_lunch_start": formattedDurations.signed.second_lunch_start,
              "signed_second_lunch_end": formattedDurations.signed.second_lunch_end,

              "actual_second_lunch_duration": shift.second_lunch_duration_mins ? Util.formatMinutesToHoursAndMinutesDuration(shift.second_lunch_duration_mins) : '-',
              "signed_second_lunch_duration": shift.signed_second_lunch_duration_mins ? Util.formatMinutesToHoursAndMinutesDuration(shift.signed_second_lunch_duration_mins) : '-',

              "overtimes": this.getOvertimesInfo(shift),
              "status": shift.status,
              "pay_code_name": shift.pay_code ? shift.pay_code.name : undefined,
              "has_unresolved_auths": shift.has_unresolved_auths,
              "is_suspected": shift.is_suspected,
              "confirmation": shift.confirmation,
              // "colors": shift.colors,

              "raw_start": shift.work_start,
              "raw_end": shift.work_end,

              "raw_signed_start": shift.signed_start,
              "raw_signed_end": shift.signed_end,
              "authorizations": shift.authorizations,

              "version": shift.version,

              "retro_shift": shift.retro_shift,
              "payment_request": shift.payment_request,

              "user_avatar": shift.associate.user_avatar,
              "unverified": shift.associate.is_verified === false,

              "origin": shift.origin
            })
          });
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => this.loading = false)
    },

    editShift(shift) {
      this.editingAssociateShiftId = shift.shift_id;
      this.associateNumberOfChangingShift = shift.associate_number;
      if (shift.pay_code_name) {
        this.visibleAssociatePayCodeModal = true;
      } else {
        this.openAssociateShiftModal();
      }
    },

    addShift() {
      this.openAssociateShiftModal();
    },

    disabledStartDate(startValue) {
      const endValue = this.dateTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.dateFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    getShiftAuthStatusTagColor(auth) {
      if (auth.status == AuthStatus.ACCEPTED) {
        return 'rgb(55, 138, 90)';
      } else if (auth.status == AuthStatus.DECLINED) {
        return 'red';
      }

      return '';
    },

    handleTableChange(pagination, filters, sorter) {
      this.pagination = {...pagination};
      this.loadData(sorter)
    },

    // getRowClassName(record) {
    //   return `row-color-${record.colors.shift_color}`;
    // },

    expandedRowRender(record) {
      const data = [
        {
          key: 'scheduled',
          type: 'Scheduled',
          date: record.scheduled_date,
          start: record.scheduled_start,
          end: record.scheduled_end,
          duration: record.scheduled_duration,
          work_hours: '-',
          pay_hours: '-',
          first_lunch_start: '-',
          first_lunch_end: '-',
          first_lunch_duration: '-',
          second_lunch_start: '-',
          second_lunch_end: '-',
          second_lunch_duration: '-',
          // colors: record.colors
        },
        {
          key: 'actual',
          type: 'Actual',
          date: record.actual_date,
          start: record.actual_start,
          end: record.actual_end,
          duration: record.work_duration,
          work_hours: '-',
          pay_hours: '-',
          first_lunch_start: record.actual_first_lunch_start,
          first_lunch_end: record.actual_first_lunch_end,
          first_lunch_duration: record.actual_first_lunch_duration,
          second_lunch_start: record.actual_second_lunch_start,
          second_lunch_end: record.actual_second_lunch_end,
          second_lunch_duration: record.actual_second_lunch_duration,
          // colors: record.colors
        },
        {
          key: 'signed',
          type: 'Signed',
          date: record.signed_date,
          start: {value: record.signed_start},
          end: {value: record.signed_end},
          duration: record.signed_duration,
          work_hours: record.work_hours,
          pay_hours: record.pay_hours,
          first_lunch_start: record.signed_first_lunch_start,
          first_lunch_end: record.signed_first_lunch_end,
          first_lunch_duration: record.signed_first_lunch_duration,
          second_lunch_start: record.signed_second_lunch_start,
          second_lunch_end: record.signed_second_lunch_end,
          second_lunch_duration: record.signed_second_lunch_duration,
          signed_start_diff_info: this.getSignedStartDiff(record),
          signed_end_diff_info: this.getSignedEndDiff(record),
          colors: record.colors
        }
      ];

      return <a-table size="small" columns={this.nestedColumns} dataSource={data} pagination={false}/>;
    },


    getColorClass(color) {
      switch (color) {
        case 'yellow':
          return 'yellow-cell';
        case 'red':
          return 'red-cell';
        default:
          return '';
      }
    },

    clearAssociateShiftModalData() {
      this.editingAssociateShiftId = undefined;
      this.creatingNewAssociateShift = false;
      this.associateNumberOfChangingShift = undefined;
    },

    openAssociateShiftModal() {
      this.associateShiftComponentKey++;

      if (!this.editingAssociateShiftId) {
        this.creatingNewAssociateShift = true;

        if (!this.associateNumberOfChangingShift) {
          this.associateNumberOfChangingShift = this.filters.associateNumber;
        }
      }

      this.visibleAssociateShiftModal = true;
    },

    closeAssociateShiftModal() {
      this.visibleAssociateShiftModal = false;
      this.clearAssociateShiftModalData();

      this.associateShiftComponentKey++;
    },
    closeAssociateShiftModalWithChanges() {
      this.loadData();
      this.closeAssociateShiftModal();
    },

    closeAssociateShiftModalToCreateNewAssociate() {
      this.closeAssociateShiftModal();
      this.openNewAssociateModal();
    },
    openNewAssociateModal() {
      this.visibleNewAssociateModal = true;
    },

    openShiftDeletionConfirmationModal(shiftId, version) {
      this.deletingAssociateShiftId = shiftId;
      this.deletingVersion = version;
      this.visibleAssociateShiftModal = false;
      this.visibleShiftDeletionConfirmationModal = true;
    },

    revokeSignature() {
      this.apiRevokeAssociateShiftSignature(this.deletingAssociateShiftId, this.deletingVersion)
        .then((response) => {
          if (response.body.error_code == 0) {
            this.showShiftVersionDeletionSuccessMessage();
          } else {
            this.showShiftVersionDeletionErrorMessage();
          }

        })
        .catch(() => {
          this.showShiftVersionDeletionErrorMessage();
        })
        .finally(() => {
          this.closeShiftDeletionModal();
          this.loadData();
        });
    },
    deleteShift() {
      this.apiDeleteAssociateShift(this.deletingAssociateShiftId)
        .then((response) => {
          if (response.body.error_code == 0) {
            this.showShiftDeletionSuccessMessage();
          } else {
            this.showShiftDeletionErrorMessage();
          }
        })
        .catch(() => {
          this.showShiftDeletionErrorMessage();
        })
        .finally(() => {
          this.closeShiftDeletionModal();
          this.loadData();
        });
    },

    showShiftDeletionSuccessMessage() {
      notification['success']({
        message: 'Success',
        description: `Shift #${this.deletingAssociateShiftId} has been successfully deleted`
      })
    },
    showShiftVersionDeletionSuccessMessage() {
      notification['success']({
        message: 'Success',
        description: `Last signature of #${this.deletingAssociateShiftId} has been successfully deleted`
      })
    },
    showShiftVersionDeletionErrorMessage() {
      notification['warning']({
        message: 'Error',
        description: `An error occurred when revoking shift #${this.deletingAssociateShiftId} last signature. Please try again`
      })
    },
    showShiftDeletionErrorMessage() {
      notification['warning']({
        message: 'Error',
        description: `An error occurred when deleting shift #${this.deletingAssociateShiftId}. Please try again`
      })
    },

    showLocationsListLoadErrorMessage() {
      notification['warning']({
        message: 'Error',
        description: 'An error occurred while loading the list of locations. Please try again later'
      })
    },
    showLocationsListEmptyMessage() {
      notification['warning']({
        message: 'No Actions Available',
        description: 'Actions are unavailable for this shift'
      })
    },

    showAuthStatusesSetErrorMessage() {
      notification['warning']({
        message: 'Error',
        description: 'Authorization statuses were not set due to an error. Try again'
      })
    },

    closeShiftDeletionModal() {
      this.visibleShiftDeletionConfirmationModal = false;
      this.deletingAssociateShiftId = undefined;
      this.deletingVersion = undefined;

      if (this.editingAssociateShiftId) {
        this.visibleAssociateShiftModal = true;
      }
    },

    handleShiftExists(overlappingShifts) {
      this.visibleAssociateShiftModal = false;

      this.visibleShiftAlreadyExistsModal = true;
      this.overlappingShifts = overlappingShifts;
    },

    editExistingShift(id) {
      this.visibleShiftAlreadyExistsModal = false;
      this.editingAssociateShiftId = id;
      this.openAssociateShiftModal()
    },
    closeExistingShiftModal() {
      this.visibleShiftAlreadyExistsModal = false;

      if (this.creatingNewAssociateShift) {
        this.visibleAssociateShiftModal = true;
      }
    },

    openSmsSendModal(record) {
      this.associateProfileToSendSms = {
        associate_number: record.associate_number,
        first_name: record.first_name,
        last_name: record.last_name,
      };
    },
    closeSmsModal() {
      this.associateProfileToSendSms = undefined;
    },

    showConfirmationInfo(confirmation) {
      this.currentConfirmation = confirmation;
    },
    closeConfirmationInfo() {
      this.currentConfirmation = undefined;
    },

    showLocationsInfo(shift) {
      this.locationsLoading = true;
      this.apiGetShiftLocations([shift.shift_id])
        .then((response) => {
          const body = response.body;

          if (body.error_code == 0) {
            const locations = response.body.shift_actions;
            if (locations.length == 0 || !locations[0] || !locations[0].records || !locations[0].records.length) {
              this.showLocationsListEmptyMessage();
            } else {
              this.currentLocationsList = locations[0].records;
            }
          } else {
            this.showLocationsListLoadErrorMessage();
          }
        })
        .catch(() => this.showLocationsListLoadErrorMessage())
        .finally(() => this.locationsLoading = false);
    },
    closeLocationsInfo() {
      this.currentLocationsList = undefined;
    },

    showShiftAuthorizations(shiftRecord) {
      if (this.$refs.shiftAuthListModal) {
        this.$refs.shiftAuthListModal.clearData();
      }
      this.currentShiftRecord = shiftRecord;
      this.currentShiftAuthsList = shiftRecord.authorizations;
      this.authorizationsListVisible = true;
    },

    closeCurrentShiftAuthsModal() {
      this.authorizationsListVisible = false;
    },

    cleanShiftAuthsEditModalData() {
      this.currentShiftAuthsList = [];
      this.selectedCurrentShiftAuthsList = [];
      this.currentShiftRecord = undefined;
      this.resolveComment = "";
      this.params = {
        readyForPayment: false,
        withWriteUp: false,
        suspend: 0,
        terminated: false
      };
    },
    closeCurrentShiftAuthsModalWithListClean() {
      this.closeCurrentShiftAuthsModal();
      this.cleanShiftAuthsEditModalData();
    },
    handleAuthListCancel() {
      this.closeCurrentShiftAuthsModalWithListClean();
    },
    acceptCommentModalOpen() {
      this.acceptCommentVisible = true;
    },

    denyReasonSelectorOpen() {
      const fullSelectedAuths = this.currentShiftAuthsList.filter(auth =>
        this.selectedCurrentShiftAuthsList.includes(auth.id)
      );

      this.denyReasonVisible = true;
      this.$nextTick(() => {
        this.$refs.shiftAuthDenyForm.setAuths(fullSelectedAuths);
      });
    },

    initAcceptSelectedAuthList() {
      this.closeCurrentShiftAuthsModal();
      this.acceptCommentModalOpen();
    },
    initDenySelectedAuthList() {
      this.closeCurrentShiftAuthsModal();
      this.denyReasonSelectorOpen();
    },

    handleSelectedAuthListChange(authList) {
      this.selectedCurrentShiftAuthsList = authList;
    },

    setResolveComment(reason) {
      this.resolveComment = reason;
    },
    setDenyParams(params) {
      this.params = params;
    },

    confirmDeny() {
      this.denyReasonVisible = false;
      if (this.resolveComment && this.selectedCurrentShiftAuthsList && this.selectedCurrentShiftAuthsList.length > 0) {
        this.denySelectedAuthList();
      } else {
        notification['warning']({
          message: 'Error',
          description: 'Please fill deny reason and try again'
        })
      }
    },
    confirmAccept() {
      this.acceptCommentVisible = false;

      if (this.selectedCurrentShiftAuthsList && this.selectedCurrentShiftAuthsList.length > 0) {
        this.acceptSelectedAuthList();
      }
    },

    acceptSelectedAuthList() {
      this.apiSetShiftAuthStatusForMultipleAuths(this.selectedCurrentShiftAuthsList, AuthStatus.ACCEPTED, this.resolveComment)
        .then((response) => {
          if (response.body.error_code != '0') {
            this.showAuthStatusesSetErrorMessage();
            this.closeCurrentShiftAuthsModalWithListClean();
            return;
          }

          this.setShiftAuthStatusesLocally(AuthStatus.ACCEPTED);

          notification['success']({
            message: 'Success',
            description: 'Authorization statuses were set successfully'
          });
        })
        .finally(() => this.closeCurrentShiftAuthsModalWithListClean());
    },

    denySelectedAuthList() {
      this.apiSetShiftAuthStatusForMultipleAuths(this.selectedCurrentShiftAuthsList, AuthStatus.DECLINED, this.resolveComment, this.params)
        .then((response) => {
          if (response.body.error_code != '0') {
            this.showAuthStatusesSetErrorMessage();
            this.cleanShiftAuthsEditModalData();
            return;
          }

          this.setShiftAuthStatusesLocally(AuthStatus.DECLINED);

          notification['success']({
            message: 'Success',
            description: 'Authorization statuses were set successfully'
          });
        })
        .finally(() => this.cleanShiftAuthsEditModalData());
    },

    setShiftAuthStatusesLocally(status) {
      this.currentShiftRecord.authorizations.forEach(auth => {
        if (this.selectedCurrentShiftAuthsList.includes(auth.id)) {
          auth.status = status;
          auth.reviewed_by = {
            first_name: this.user.first_name,
            last_name: this.user.last_name
          };
          auth.review_date = moment();
        }
      })
    },

    showAssociateShiftsFilterModal() {
      ++this.filtersModalKey;
      this.associateShiftsFiltersVisible = true;
    },
    hideAssociateShiftsFilterModal() {
      this.associateShiftsFiltersVisible = false;
    },
    resetAssociateShiftsFilters() {
      this.dateFrom = undefined;
      this.dateTo = undefined;

      this.filters = {...filtersBaseState};
    },
    applyFilters(filters) {
      this.hideAssociateShiftsFilterModal();
      this.filters = {...filters};
    },

    sendToFirstPage() {
      this.pagination.current = 1;
    },

    openMultipleShiftsModal() {
      this.addMultipleShiftsModalKey += 1;
      this.visibleAddMultipleShiftsModal = true;
    },
    openMarkPaidSeveralModal() {
      this.markPaidSeveralModalKey += 1;
      this.visibleMarkPaidSeveralModal = true;
    },
    closeMultipleShiftsModal(duplicatedShifts) {
      this.visibleAddMultipleShiftsModal = false;

      if (duplicatedShifts) {
        this.loadData();
      }
    },

    closeNewAssociateModal(newAssociateNumber) {
      this.visibleNewAssociateModal = false;

      if (newAssociateNumber) {
        this.associateNumberOfChangingShift = newAssociateNumber;
        this.openAssociateShiftModal();
      }
    },

    openPaymentRequest(id) {
      this.paymentRequestKey++;
      this.openedPaymentId = id;
    },

    shiftsTourBeforeStartCallback() {
      return new Promise((resolve, reject) => {
        const checkCondition = () => {
          if (this.tableData && this.tableData.length > 0 && !this.loading) {
            resolve();
          } else {
            setTimeout(checkCondition, 100);
          }
        };
        checkCondition();
      });
    },
    shiftTourStartCallback() {
      this.tourIsOn = true;
    },
    shiftTourFinishCallback() {
      localStorage.setItem('shiftsTourPassed', true);
      this.tourIsOn = false;
    },

    beforeAuthsButtonStep() {
      if (!this.editingAssociateShiftId) {
        return;
      }

      return new Promise((resolve) => {
        this.closeAssociateShiftModal();
        setTimeout(() => {
          resolve()
        }, 500);
      })
    },
    beforeEditShiftStep() {
      if (this.editingAssociateShiftId) {
        return;
      }

      return new Promise((resolve) => {
        this.editShift(this.tableData[0]);
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },
    beforeSignedShiftStep() {
      if (this.signedShiftStepPassed) {
        return;
      }

      const firstRow = document.querySelector('tbody tr');
      if (firstRow) {
        const buttonDiv = firstRow.querySelector('.ant-table-row-expand-icon');

        if (buttonDiv) {
          buttonDiv.click();
          this.signedShiftStepPassed = true;
        }
      }
    },

    cancelMarkPaidSeveral(dataChanged) {
      this.visibleMarkPaidSeveralModal = false;

      if (dataChanged) {
        this.loadData();
      }
    },

    getOriginColor(origin) {
      switch(origin) {
        case 'Time Tracker':
          return '#7432FF'
        case 'Scheduler':
          return '#52BFFF'
        case 'Marketplace':
          return '#FF4646'
      }
    },

    handleAddOrEditAssociatePayCode() {
      this.visibleAssociatePayCodeModal = false;
      this.editingAssociateShiftId = null;
      this.loadData();
    },
    closeAssociatePayCodeModal() {
      this.visibleAssociatePayCodeModal = false;
      this.editingAssociateShiftId = null;
    },
  },
  mounted() {
    const shiftsTourPassed = localStorage.getItem('shiftsTourPassed');
    if (!shiftsTourPassed) {
      this.$tours['shiftsTour'].start();
    }

    const localPagination = localStorage.getItem('shiftsPagination');
    this.pagination = localPagination ? JSON.parse(localPagination) : initialPagination;

    this.loadData();
  },

};
</script>

<style scoped>
.role-select {
  min-width: 150px;
}

.auth-tag {
  text-align: center;
}

.auth-tag:hover, .suspected-tag:hover {
  cursor: pointer;
}

.already-existing-shift-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tags-cell {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.table-associate-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.table-associate-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
}

.orange-highlight {
  background-color: #ffb546;
}
</style>
