<template>
  <div>
    <div class="flex flex-column gap-3 align-items-center">
      <a-upload-dragger
          :file-list="fileList"
          :before-upload="beforeUpload"
          :remove="handleRemove"
          :multiple="true"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox"/>
        </p>
        <p class="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p class="ant-upload-hint">
          Support for upload of .pdf files
        </p>
      </a-upload-dragger>
    </div>
  </div>
</template>

<script>
import {Button, notification, Upload, Icon} from "ant-design-vue";
import api from "@/api";

export default {
  props: ['payrollId'],
  components: {
    'a-upload-dragger': Upload.Dragger,
    'a-button': Button,
    'a-icon': Icon
  },
  data() {
    return {
      fileList: [],
      uploading: false
    }
  },
  watch: {
    fileList() {
      this.$emit('documents-uploaded', this.fileList);
    }
  },
  mixins: [api],
  methods: {
    beforeUpload(file) {
      if (file.type != 'application/pdf') {
        notification['error']({
          message: 'Error',
          description: 'You can only upload PDF files'
        })
      } else {
        this.fileList = [...this.fileList, file];
      }
      return false;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);

      this.fileList = newFileList;
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    }
  }
}
</script>

<style scoped>
.ant-upload-drag-icon, .ant-upload-hint {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-upload-text {
  padding: 0 10px;
}
</style>
