<template>
  <div>
    <h2 class="view-header">Mispunch</h2>
    <div class="table_controls_and_data">
      <div class="table_data_wrapper"></div>
      <div class="table_controls_wrapper">
        <div class="table_params">
          <a-button :disabled="table_data.length == 0 && !not_found" @click="clearData">Clear</a-button>
          <a-dropdown :disabled="table_data.length == 0">
            <a-menu slot="overlay">
              <a-menu-item key="excel">
                <download-excel
                  :data="table_data"
                  worksheet="Mispunch"
                  name="mispunch.xls"
                >
                  <a-icon type="file-excel"/>
                  Excel
                </download-excel>
              </a-menu-item>
              <a-menu-item key="csv">
                <download-excel
                  :data="table_data"
                  :escapeCsv="false"
                  type="csv"
                  name="mispunch.csv"
                >
                  <a-icon type="file-text"/>
                  CSV
                </download-excel>
              </a-menu-item>
              <a-menu-item key="pdf" @click="printTable">
                <a-icon type="file-pdf"/>
                <span>Print/PDF</span>
              </a-menu-item>
            </a-menu>
            <a-button style="margin-left: 8px" :disabled="loading">
              Export
              <a-icon type="down"/>
            </a-button>
          </a-dropdown>
        </div>
        <div class="table_predefined_filters">
          <a-select
            v-model="selectedRole"
            :disabled="loading"
            :allow-clear="true"
            class="role-select"
            placeholder="Job Classification"
          >
            <a-select-option v-for="role in roles" :key="role.id" :value="role.id">
              {{ role.name }}
            </a-select-option>
          </a-select>
          <a-select
            ref="userSelect"
            v-model:value="selected_employees"
            show-search
            :allowClear="true"
            placeholder="Associate"
            style="min-width: 180px"
            mode="multiple"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            :default-active-first-option="false"
            :dropdown-match-select-width="false"
            :disabled="loading"
            :options="found_employees"
            :loading="searching_users"
            @search="fetchUsers"
            @change="handleSearchUsersChange"
          />

          <a-date-picker
            v-model="date_from"
            :disabled-date="disabledStartDate"
            :format="select_date_format"
            placeholder="Start Date"
            style="flex: 1 1 0"
            :disabled="loading"
          />
          <a-date-picker
            v-model="date_to"
            :disabled-date="disabledEndDate"
            :format="select_date_format"
            placeholder="End Date"
            style="flex: 1 1 0"
            :disabled="loading"
          />
        </div>
      </div>
    </div>
    <a-table
      size="small"
      :columns="columns"
      :data-source="table_data"
      :loading="loading"
      key="id"
    >
      <span slot="missing_start" slot-scope="text, record">
        <a-tag :color="record.missing_start ? 'red' : 'green'">{{ record.missing_start ? 'YES' : 'NO' }}</a-tag>
      </span>
      <span slot="missing_end" slot-scope="text, record">
        <a-tag :color="record.missing_end ? 'red' : 'green'">{{ record.missing_end ? 'YES' : 'NO' }}</a-tag>
      </span>
      <span slot="missing_first_lunch" slot-scope="text, record">
        <a-tag :color="record.missing_first_lunch ? 'red' : 'green'">{{
            record.missing_first_lunch ? 'YES' : 'NO'
          }}</a-tag>
      </span>
      <span slot="missing_second_lunch" slot-scope="text, record">
        <a-tag :color="record.missing_second_lunch ? 'red' : 'green'">{{
            record.missing_second_lunch ? 'YES' : 'NO'
          }}</a-tag>
      </span>
    </a-table>
    <div v-if="showStats" class="flex flex-row justify-content-end mt-2">
      <div class="stats-block">
        <div class="stat">
          <div class="label"><b>Missing First Lunch Total</b></div>
          <div class="value"><span>{{ missingFirstLunchTotal }}</span></div>
        </div>
        <div class="stat">
          <div class="label"><b>Missing Second Lunch Total</b></div>
          <div class="value"><span>{{ missingSecondLunchTotal }}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ant-table td {
  white-space: nowrap;
}
</style>

<script>
import api from "@/api";
import moment from "moment";
import JsonExcel from "vue-json-excel";
import {Button, DatePicker, Select, Menu, Dropdown, Icon, Table, Switch, Tag} from "ant-design-vue";
import printJS from "print-js";

export default {
  components: {
    'a-button': Button,
    'a-date-picker': DatePicker,
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-menu': Menu,
    'a-menu-item': Menu.Item,
    'a-tag': Tag,
    'a-icon': Icon,
    'a-dropdown': Dropdown,
    'a-table': Table,
    'a-switch': Switch,
    'download-excel': JsonExcel,
  },
  data: () => {
    return {
      loading: false,
      search_users_timeout: undefined,
      searching_users: null,
      selectedRole: undefined,

      table_data: [],

      found_employees: [],
      selected_employees: [],

      shifts: [],

      date_from: null,
      date_to: null,
      not_found: false,

      select_date_format: 'YYYY-MM-DD',
      work_date_format: 'YYYY-MM-DD HH:mm',
      lunch_date_format: 'HH:mm',

      missingFirstLunchTotal: 0,
      missingSecondLunchTotal: 0,

      columns: [
        {
          title: 'Shift ID',
          dataIndex: 'id',
          key: 'id',
          width: '100px',
          sorter: (a, b) => a.id - b.id
        },
        {
          title: 'Associate Number',
          dataIndex: 'associate_number',
          key: 'associate_number',
          width: '120px',
          sorter: (a, b) => a.associate_number - b.associate_number
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          width: '120px',
          sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf()
        },
        {
          title: 'Weekday',
          dataIndex: 'weekday',
          key: 'weekday',
          width: '110px'
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: '200px',
          sorter: (a, b) => a.name == null ? -1 : b.name == null ? 1 : a.name.localeCompare(b.name)
        },
        {
          title: 'Job Classification',
          dataIndex: 'role_name',
          key: 'role_name'
        },
        {
          title: 'Missing Start',
          dataIndex: 'missing_start',
          key: 'missing_start',
          align: 'center',
          scopedSlots: {customRender: 'missing_start'}
        },
        {
          title: 'Missing End',
          dataIndex: 'missing_end',
          key: 'missing_end',
          align: 'center',
          scopedSlots: {customRender: 'missing_end'}
        },
        {
          title: 'Missing First Lunch',
          dataIndex: 'missing_first_lunch',
          key: 'missing_first_lunch',
          align: 'center',
          scopedSlots: {customRender: 'missing_first_lunch'}
        },
        {
          title: 'Missing Second Lunch',
          dataIndex: 'missing_second_lunch',
          key: 'missing_second_lunch',
          align: 'center',
          scopedSlots: {customRender: 'missing_second_lunch'}
        },
      ],
      table_height: null
    }
  },

  watch: {
    selectedRole() {
      this.loadData();
    },
    selected_employees() {
      this.loadData();
    },
    dateFromString() {
      this.loadData();
    },
    dateToString() {
      this.loadData();
    }
  },

  mixins: [api],

  computed: {
    dateFromString() {
      return this.date_from ? moment.utc(this.date_from).format(this.select_date_format) : null
    },
    dateToString() {
      return this.date_to ? moment.utc(this.date_to).format(this.select_date_format) : null
    },
    roles() {
      return this.$store.state.applicationState.projectRoles;
    },
    showStats() {
      return this.table_data && this.table_data.length > 0;
    }
  },

  methods: {
    async onKeydown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
        event.preventDefault();
        try {
          this.$nextTick(this.printTable);
        } catch (error) {
          console.error(error);
        }
      }
    },
    printTable() {
      printJS({
        printable: this.table_data,
        properties: [
          {field: 'id', displayName: 'Shift ID'},
          {field: 'associate_number', displayName: 'Associate Number'},
          {field: 'date', displayName: 'Date'},
          {field: 'weekday', displayName: 'Weekday'},
          {field: 'name', displayName: 'Name'},
          {field: 'role_name', displayName: 'Job Classification'},
          {field: 'missing_start', displayName: 'Missing Start'},
          {field: 'missing_end', displayName: 'Missing End'},
          {field: 'missing_first_lunch', displayName: 'Missing First Lunch'},
          {field: 'missing_second_lunch', displayName: 'Missing Second Lunch'}
        ],
        type: 'json',
        header: '<h4>Mispunch Report</h4>',
        style: 'tr { page-break-inside: avoid; page-break-after: auto; }',
        documentTitle: ' '
      })
    },

    loadData() {
      this.not_found = false;
      this.table_data = [];
      this.loading = true;

      Object.assign(this.$data.columns, this.$options.data().columns)
      this.apiGetMispunchReport(this.selected_employees, this.dateFromString, this.dateToString, this.selectedRole)
        .then((response) => {
          this.table_data = response.body.mispunch_records;
          this.missingFirstLunchTotal = response.body.missing_first_lunch_total;
          this.missingSecondLunchTotal = response.body.missing_second_lunch_total;

          if (this.table_data.length == 0) {
            this.not_found = true;
          }
        })
        .catch((err) => console.error(err))
        .finally(() => this.loading = false);
    },
    clearData() {
      this.not_found = false;
      this.date_from = null;
      this.date_to = null;
      this.table_data = [];
      this.found_employees = [];
      this.selected_employees = [];
    },

    loadShiftNumbersData() {
      this.apiGetShifts()
        .then(response => {
          const shifts = response.body.shifts;
          // const filters = this.columns.find(col => col.key == 'shift_number').filters;
          //
          // shifts.forEach(filter => {
          //   filters.push({
          //     text: filter.code,
          //     value: filter.code
          //   })
          // })
        })
    },

    disabledStartDate(startValue) {
      const endValue = this.date_to;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.date_from;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    fetchUsers(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout)
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.searching_users = true;
        this.apiSearchAssociate(value).then((resp) => {
          this.found_employees = resp.data.associate_list.map(user => ({
            label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
            key: user.employee_id,
            value: user.employee_id
          }))
          this.searching_users = false;
        })
      }, 1000)
    },

    handleSearchUsersChange(value) {
      this.selected_employees = value;
      this.$refs.userSelect.blur();
    }
  },

  mounted() {
    this.loadShiftNumbersData();
    this.loadData();
  }
}
</script>

<style scoped>
.role-select {
  min-width: 170px;
}

.stats-block {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.stat {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.label {
  flex-basis: 85%;
}

.value {
  flex-basis: 15%;
}

</style>
