<template>
	<transition name="modal"  v-if="isVisible" ref="popup"  @close="_close()">
		<div class="modal-mask">
      <div class="modal-wrapper" >
        <div class="modal-container">
          <div>
            <h2>{{header}}</h2>
          </div>

          <div class="modal-body" style="overflow: auto">
            {{text}}
          </div>

          <div class="modal-footer">
            <button class="ui button" @click="_close()">OK</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
import {Icon} from "ant-design-vue";

export default {
    components: {
      'a-icon': Icon
    },
    data: function() {
        return {
          isVisible: false,
          header: null,
          text: null,

          resolvePromise: null,
          rejectPromise: null
        };
    },
    methods: {
      show(opts = {}) {
        this.isVisible = true;

        this.header = opts.header;
        this.text = opts.text;

        return new Promise((resolve, reject) => {
          this.resolvePromise = resolve
          this.rejectPromise = reject
        })
      },

      _close() {
        this.isVisible = false;
        this.resolvePromise()
      },
    }
}
</script>

<style scoped>
  .modal-container {
    width: 400px;
  }
</style>
