<template>
  <div>
    <div class="flex flex-column gap-2">
      <div v-if="paymentTypes.length > 0">
        <p>
          For further work, please select the payment type of associates whose data you want to work with.
          Later it can be changed in "Project" -> "Settings"
        </p>
        <a-select
          :default-value="paymentTypes[0].id"
          @change="handleSelectChange"
        >
          <a-select-option v-for="pt in paymentTypes" :value="pt.id" :key="pt.id">
            {{ pt.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";

import {Select} from "ant-design-vue";

export default {
  components: {
    'a-select': Select,
    'a-select-option': Select.Option
  },
  data() {
    return {
      paymentTypes: []
    }
  },
  mixins: [api],
  methods: {
    async initializePaymentTypes() {
      await this.apiGetPaymentTypes()
        .then(resp => {
          this.paymentTypes = resp.body.payment_types;
        })
    },
    handleSelectChange(value) {
      this.$emit('update:paymentType', value)
    }
  },
  async mounted() {
    await this.initializePaymentTypes();

    this.$emit('update:paymentType', this.paymentTypes[0].id);
  }
}
</script>
