export default {
    methods: {
        // if proxied - set to your client ip
        getRootAddress() {
            if (process.env.NODE_ENV === 'production') {
                return "/" // "https://qa.epeople.com/" //"http://localhost:8000/" //"https://bb.epeople.com/"
            } else {
              return "https://qa.epeople.com/" //"https://bb.epeople.com/" //"http://bb.epeople.com:8000/" //"http://bb.epeople.com:8000/" //"https://bb.epeople.com/"
            }
        },

    getApiCallAddress() {
      return this.getRootAddress() + "api/"
    },

    apiGetFrontendVersion() {
      const host = this.getApiCallAddress() + 'tc_get_frontend_version';

      return this.$http.post(host, JSON.stringify({}));
    },

    apiSignIn(login, password) {
      const formData = new FormData();
      formData.append('username', login);
      formData.append('password', password);

      return this.$http.post(this.getRootAddress() + 'sign_in', formData);
    },
    apiSignOut() {
      return this.$http.post(this.getRootAddress() + 'sign_out').then(() => this.$router.push('/auth'));
    },

    apiResetPassword(username) {
      const host = this.getApiCallAddress() + 'reset_password_request';

      return this.$http.post(host, JSON.stringify({
        'email': username
      }));
    },
    apiConfirmResetPassword(username, newPassword, verificationCode) {
      const host = this.getApiCallAddress() + 'reset_password';

      return this.$http.post(host, JSON.stringify({
        'email': username,
        'password': newPassword,
        'verification_code': verificationCode
      }))
    },

    apiSearchAssociate(part) {
      const host = this.getApiCallAddress() + "tc_search_employee";
      return this.$http.post(host, JSON.stringify({
          'query': part && part.trim().length > 0 ? part : '/all'
        })
      );
    },
    apiGetAssociateProfiles(
      associateNumber,
      roleId,
      scheduleTypeId,
      rateFrom,
      rateTo,
      showAllPaymentTypes,
      isActive,
      isVerified,
      page,
      size,
      sorter,
      withoutPayrollSystemId
    ) {
      const host = this.getApiCallAddress() + "tc_get_associate_profiles";

      return this.$http.post(host, JSON.stringify({
        'associate_number': associateNumber,
        'role_id': roleId,
        'schedule_type_id': scheduleTypeId,
        'rate_from': rateFrom,
        'rate_to': rateTo,
        'show_all_payment_types': showAllPaymentTypes,
        'is_active': isActive,
        'is_verified': isVerified,
        'page': page,
        'size': size,
        'sorter': sorter,
        'without_payroll_system_id': withoutPayrollSystemId
      }))
    },
    apiGetAssociateShifts(associate_numbers, date_from, date_to, role, status, tableParams, showOnlyCompleted, sorter) {
      const host = this.getApiCallAddress() + "tc_get_employee_shifts";
      return this.$http.post(host, JSON.stringify({
          'associate_numbers': associate_numbers,
          'date_from': date_from,
          'date_to': date_to,
          'role': role,
          'status': status,
          'page': tableParams && tableParams.current ? tableParams.current : null,
          'size': tableParams && tableParams.pageSize ? tableParams.pageSize : null,
          'only_completed': showOnlyCompleted ?? false,
          'sorter': sorter
        })
      );
    },
    apiGetAssociateShift(shift_id) {
      const host = this.getApiCallAddress() + "tc_get_employee_shift";
      return this.$http.post(host, JSON.stringify({
          'shift_id': shift_id
        })
      );
    },
    apiAddAssociateShift(
      associateNumber,
      signedStart,
      signedEnd,
      signedLunchStart,
      signedLunchEnd,
      signedSecondLunchStart,
      signedSecondLunchEnd,
      autoSign,
      lunchWaived
    ) {
      const host = this.getApiCallAddress() + 'shift/manually_entered/create';

      return this.$http.post(host, JSON.stringify({
        'associate_number': associateNumber,
        'project_id': this.$store.state.applicationState.currentProject.id,
        'position': {
          'latitude': 0,
          'longitude': 0,
          'altitude': 0
        },
        'completed_authorizations': [],
        'shift': {
          'work_start': signedStart,
          'work_end': signedEnd,
          'lunch_start': signedLunchStart,
          'lunch_end': signedLunchEnd,
          'second_lunch_start': signedSecondLunchStart,
          'second_lunch_end': signedSecondLunchEnd,
          'lunch_waived': !!lunchWaived,
          'modified_by_admin': true
        },
        'auto_sign': autoSign
      }));
    },
    apiSetAssociateShift(
      shiftId,
      signedStart,
      signedEnd,
      signedLunchStart,
      signedLunchEnd,
      signedSecondLunchStart,
      signedSecondLunchEnd,
      autoSign,
      scheduledAndActualAsSigned
    ) {
      const host = this.getApiCallAddress() + "shift/adjust_time";

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'shift': {
          'id': shiftId,
          'work_start': signedStart,
          'work_end': signedEnd,
          'lunch_start': signedLunchStart,
          'lunch_end': signedLunchEnd,
          'second_lunch_start': signedSecondLunchStart,
          'second_lunch_end': signedSecondLunchEnd,
          'lunch_waived': false,
          'modified_by_admin': true,
          'set_scheduled_and_actual_as_signed': scheduledAndActualAsSigned
        },
        'position': {
          'latitude': 0,
          'longitude': 0,
          'altitude': 0
        },
        'auto_sign': autoSign
      }))
    },
    apiRevokeAssociateShiftSignature(shiftId, version) {
      const host = this.getApiCallAddress() + `shift/${shiftId}/version/${version}/delete`;

      return this.$http.post(host, JSON.stringify({}));
    },
    apiDeleteAssociateShift(shift_id) {
      const host = this.getApiCallAddress() + "tc_delete_employee_shift";
      return this.$http.post(host, JSON.stringify({
          'shift_id': shift_id
        })
      );
    },
    apiGetPaymentTypes() {
      const host = this.getApiCallAddress() + "tc_get_payment_types";
      return this.$http.post(host, JSON.stringify({})
      );
    },
      apiGetPayCodes() {
        const host = this.getApiCallAddress() + "project/pay_code/get_all";
        return this.$http.post(host, JSON.stringify({
            'project_id': this.$store.state.applicationState.currentProject.id,
          })
        );
      },
      apiAddPayCode(pay_code) {
        const host = this.getApiCallAddress() + "project/pay_code/create";
        return this.$http.post(host, JSON.stringify({
            'project_id': this.$store.state.applicationState.currentProject.id,
            'pay_code': pay_code,
          })
        );
      },
      apiGetShiftAuths(employee_ids, date_from, date_to, tableParams, shiftId, sorter) {
        const host = this.getApiCallAddress() + "get_shift_auths";
        return this.$http.post(host, JSON.stringify({
          'employee_ids': employee_ids,
          'date_from': date_from,
          'date_to': date_to,
          'shift_id': shiftId,
          'page': tableParams && tableParams.current ? tableParams.current : null,
          'size': tableParams && tableParams.pageSize ? tableParams.pageSize : null,
        })
      );
    },
    apiGetShiftAuthsGroupedByShifts(projectId, tableParams, paymentTypeId, associateId,
                                    withScheduleError, assignedToMe, authType, dayFrom, dayTo,
                                    sorter, onlyManualShifts) {
      const host = this.getApiCallAddress() + "get_project_authorizations_by_shift";

      return this.$http.post(host, JSON.stringify({
        'project_id': projectId,
        'page': tableParams && tableParams.current ? tableParams.current : null,
        'size': tableParams && tableParams.pageSize ? tableParams.pageSize : null,
        'payment_type_id': paymentTypeId,
        'associate_number': associateId,
        'with_schedule_error': withScheduleError,
        'assigned_to_me': assignedToMe,
        'auth_type': onlyManualShifts ? 'manually_shift_authorization' : authType,
        'start': dayFrom,
        'end': dayTo,
        'sorter': sorter
      }))
    },
    apiSetShiftAuthStatusForMultipleAuths(ids, status, comment, params) {
      const host = this.getApiCallAddress() + "tc_set_auth_status_for_multiple_auths";

      let data = {ids, status, comment};

      if (params) {
        data.ready_for_payment = params.readyForPayment;
        data.with_writeup = params.withWriteUp;
        data.suspension = params.suspend;
        data.terminated = params.terminated;
        data.shift_action_autocomplete = params.shiftActionAutocomplete;
      }

      return this.$http.post(host, JSON.stringify(data));
    },
    apiSetShiftAuthStatus(auth_id, status, comment) {
      const host = this.getApiCallAddress() + "set_shift_auth_status";
      return this.$http.post(host, JSON.stringify({
          'auth_id': auth_id,
          'status': status,
          'comment': comment
        })
      );
    },
    apiResetShiftSignature(id, comment, user) {
      const host = this.getApiCallAddress() + "tc_revoke_shift_sign";
      return this.$http.post(host, JSON.stringify({
          'shift_id': id,
          'noted_by': user,
          'note': comment
        })
      );
    },
    apiGetAssociate(employee_id) {
      const host = this.getApiCallAddress() + "tc_get_employee";
      return this.$http.post(host, JSON.stringify({
          'employee_id': employee_id
        })
      );
    },
    apiSetAssociate(employee_id, first_name, last_name, socialSecurityNumber, phone, address_line1,
                    country, city, state, zipcode, pay_status, email, birth_date, role_id, seniority_date,
                    hire_date, terminate_date, is_active, pay_method, schedule_type, rate, payment_type, new_employee_id,
                    agency, shift_action_autocomplete, require_shift_confirmation, auto_approve_payment_request,
                    time_tracking_on_terminal, shifts_from_scheduler
    ) {
      if (rate === null) rate = 0;

      const host = this.getApiCallAddress() + "tc_set_employee";
      return this.$http.post(host, JSON.stringify({
          'employee_id': employee_id,
          'new_employee_id': new_employee_id,
          'first_name': first_name,
          'last_name': last_name,
          'email': email,
          'birth_date': birth_date,
          'social_security_number': socialSecurityNumber,
          'phone': phone,
          'address_line1': address_line1,
          'country': country,
          'city': city,
          'state': state,
          'zipcode': zipcode,
          'pay_status': pay_status,
          'role_id': role_id,
          'schedule_type': schedule_type,
          'seniority_date': seniority_date,
          'hire_date': hire_date,
          'terminate_date': terminate_date,
          'is_active': is_active,
          'pay_method': pay_method,
          'rate': rate,
          'payment_type': payment_type,
          'legal_entity': agency,
          'shift_action_autocomplete': shift_action_autocomplete,
          'require_shift_confirmation': require_shift_confirmation,
          'auto_approve_payment_request': auto_approve_payment_request,
          'time_tracking_on_terminal': time_tracking_on_terminal,
          'shifts_from_scheduler': shifts_from_scheduler
        })
      );
    },
    apiGetShifts() {
      const host = this.getApiCallAddress() + "tc_get_shifts";
      return this.$http.post(host, JSON.stringify({})
      );
    },
    apiAddShift(code, description) {
      const host = this.getApiCallAddress() + "tc_add_shift";
      return this.$http.post(host, JSON.stringify({
          'code': code,
          'description': description
        })
      );
    },
    apiGetPayments(filters, pagination, sorter) {
      return this.$http.post(this.getApiCallAddress() + "get_admin_payment_requests", JSON.stringify({
        page: pagination.current,
        size: pagination.pageSize,
        filters: {...filters, 'associate__project': this.$store.state.applicationState.currentProject.id },
        sorter: sorter
      }))
    },
    apiGetPaymentStatuses() {
      return this.$http.post(this.getApiCallAddress() + 'get_payment_requests_statuses')
    },
    apiGetPayment(payment_id) {
      return this.$http.post(this.getApiCallAddress() + "get_admin_payment_request", {"payment_id": payment_id})
    },
    apiApprovePayment(payment_id, data) {
      return this.$http.post(this.getApiCallAddress() + `get_payment_requests/${payment_id}/set_approve`, data)
    },
    apiCancelPayment(payment_id, reason) {
      return this.$http.post(this.getApiCallAddress() + `cancel_payment_requests/${payment_id}`, {"reason": reason})
    },
    apiGetRoles() {
      const host = this.getApiCallAddress() + "tc_get_roles";
      return this.$http.post(host, JSON.stringify({}));
    },
    apiGetNotAdmRoles() {
      const host = this.getApiCallAddress() + "tc_get_not_adm_roles";
      return this.$http.post(host, JSON.stringify({})
      );
    },
    apiGetNowWorkedShifts(employee_id, role_id, is_finished, date_from, date_to) {
      const host = this.getApiCallAddress() + "tc_get_working_history";
      return this.$http.post(host, JSON.stringify({
          'employee_id': employee_id,
          'role_id': role_id,
          'is_finished': is_finished,
          'date_from': date_from,
          'date_to': date_to
        })
      );
    },
    apiAddNotAdmRole(name, code, role_properties) {
      const host = this.getApiCallAddress() + "tc_add_not_adm_role";
      return this.$http.post(host, JSON.stringify({
          'name': name,
          'code': code,
          'role_properties': role_properties,
        })
      );
    },
    apiUpdateRole(id, name, code, role_properties) {
      const host = this.getApiCallAddress() + "tc_update_role";
      return this.$http.post(host, JSON.stringify({
          'role': {
            'id': id,
            'name': name,
            'code': code,
            'role_properties': role_properties,
          }
        })
      );
    },
    apiDeleteRole(id) {
      const host = this.getApiCallAddress() + "tc_delete_role";
      return this.$http.post(host, JSON.stringify({
          'role': {
            'id': id,
          }
        })
      );
    },

    apiGetLegalEntities(agencyType) {
      const host = this.getApiCallAddress() + "tc_get_legal_entities";
      return this.$http.post(host, JSON.stringify({
        'type': agencyType
      }))
    },

    apiDeleteLegalEntity(id) {
      const host = this.getApiCallAddress() + "tc_delete_legal_entity";
      return this.$http.post(host, JSON.stringify({
        "legal_entity": {
          "id": id,
        }
      }))
    },

    apiCreateLegalEntity(legalEntity) {
      const host = this.getApiCallAddress() + "tc_create_legal_entity"
      return this.$http.post(host, JSON.stringify({
        "legal_entity": {
          "name": legalEntity.name,
          "code": legalEntity.code,
          "type": legalEntity.type,
          "address": legalEntity.address
        }
      }))
    },

    apiUpdateLegalEntity(legalEntity) {
      const host = this.getApiCallAddress() + "tc_update_legal_entity"
      return this.$http.post(host, JSON.stringify({
        "legal_entity": {
          "id": legalEntity.id,
          "name": legalEntity.name,
          "code": legalEntity.code,
          "type": legalEntity.type,
          "address": legalEntity.address
        }
      }))
    },

    apiSearchUsers(search_string) {
      return this.$http.post(this.getApiCallAddress() + 'search_users', {'query': search_string})
    },
    apiGetAssociateNotInWorkAreaReport(employee_ids, date_from, date_to) {
      const host = this.getApiCallAddress() + "tc_get_employee_not_in_wa";
      return this.$http.post(host, JSON.stringify({
          'employee_ids': employee_ids,
          'date_from': date_from,
          'date_to': date_to,
        })
      );
    },
    apiGetShiftCovidQuestionary(employee_id, date_from, date_to) {
      const host = this.getApiCallAddress() + "tc_get_shift_covid_questionary";
      return this.$http.post(host, JSON.stringify({
          'employee_id': employee_id,
          'date_from': date_from,
          'date_to': date_to
        })
      );
    },
    apiGetAttendanceReport(employee_ids, date_from, date_to, show_unsigned) {
      const host = this.getApiCallAddress() + "tc_get_attendance_report";
      return this.$http.post(host, JSON.stringify({
        'employee_ids': employee_ids,
        'date_from': date_from,
        'date_to': date_to,
        'show_unsigned': show_unsigned,
      }))
    },
    apiGetAssociateReport(employee_ids, show_inactive) {
      const host = this.getApiCallAddress() + "tc_get_employee_report";
      return this.$http.post(host, JSON.stringify({
        'employee_ids': employee_ids,
        'show_inactive': show_inactive
      }))
    },

    apiGetOvertimeReport(employee_ids, date_from, date_to) {
      const host = this.getApiCallAddress() + "tc_get_overtime_report";

      return this.$http.post(host, JSON.stringify({
        'employee_ids': employee_ids,
        'date_from': date_from,
        'date_to': date_to
      }));
    },

    apiGetMispunchReport(employee_ids, date_from, date_to, role) {
      const host = this.getApiCallAddress() + "tc_get_mispunch_report";

      return this.$http.post(host, JSON.stringify({
        'employee_ids': employee_ids,
        'date_from': date_from,
        'date_to': date_to,
        'role_id': role
      }));
    },

    apiGetActiveAssociatesReport(associate_number, date_from, date_to) {
      const host = this.getApiCallAddress() + "tc_get_active_associates_report";

      return this.$http.post(host, JSON.stringify({associate_number, date_from, date_to}))
    },

    apiGetProjectDocGroups() {
      const host = this.getApiCallAddress() + "tc_get_project_doc_groups";
      return this.$http.post(host)
    },

    apiAddDocument(document) {
      const host = this.getApiCallAddress() + "project_document_store/add_document";
      return this.$http.post(host, JSON.stringify({
        'document': document
      }))
    },

    apiGetSignedDocuments(employee_id) {
      const host = this.getApiCallAddress() + "project_document_store/get_signed_documents";
      return this.$http.post(host, JSON.stringify({
        'employee_id': employee_id
      }))
    },

    apiDownloadTextDocument(document_id, doc_export_type) {
      const host = this.getApiCallAddress() + "project_document_store/download_text_document";
      return this.$http.post(host, JSON.stringify({
        'document_id': document_id,
        'doc_export_type': doc_export_type
      }))
    },

    apiDownloadDocument(document_id, employee_id, doc_export_type) {
      const host = this.getApiCallAddress() + "project_document_store/download_document_with_sign";
      return this.$http.post(host, JSON.stringify({
        'document_id': document_id,
        'employee_id': employee_id,
        'doc_export_type': doc_export_type
      }))
    },

    apiUploadFiles(files) {
      const host = this.getApiCallAddress() + "upload_files";
      let formData = new FormData()

      files.forEach((file) => formData.append(file.name, file))

      return this.$http.post(host, formData)
    },

    apiGetInvoice(payment_request_id, type) {
      const host = this.getApiCallAddress() + "get_payment_invoice";

      return this.$http.post(host, JSON.stringify({
        'id': payment_request_id,
        'file_type': type
      }))
    },

    apiGetPushNotifications(user_ids, start_date, end_date, pagination, sorter) {
      const host = this.getApiCallAddress() + "get_push_notifications";

      return this.$http.post(host, JSON.stringify({
        'user_ids': user_ids,
        'start_date': start_date,
        'end_date': end_date,
        'pagination_settings': pagination,
        'sorter': sorter
      }))
    },

    apiSwitchCurrentProject(new_project_id) {
      const host = this.getApiCallAddress() + "switch_current_project";

      return this.$http.post(host, JSON.stringify({
        "project_id": new_project_id
      }))
    },

    apiGetTimeAttendanceDetailReport(
      associateNumber, dateFrom,
      dateTo, role, status,
      headerContentOptions,
      reportContentOptions,
      ids, groupBy, divideByDays, withoutPayCodes
    ) {
      const host = this.getApiCallAddress() + "tc_get_time_attendance_detail_report";

      return this.$http.post(host, JSON.stringify({
        'associate_number': associateNumber,
        'date_from': dateFrom,
        'date_to': dateTo,
        'role': role,
        'status': status,
        'header_content_options': headerContentOptions,
        'report_content_options': reportContentOptions,
        'ids': ids,
        'group_by': groupBy,
        'divide_by_days': divideByDays,
        'without_pay_codes': withoutPayCodes
      }))
    },

    apiGetTimeAttendanceDetailReportByShiftIds(shiftIds) {
      const host = this.getApiCallAddress() + "tc_get_time_attendance_detail_report";

      const headerContentOptions = [
        {key: 'reportGenerationDate', title: 'Report Generation Date', checked: true},
        {key: 'role', title: 'Job classification', checked: true},
        {key: 'status', title: 'Status', checked: true}
      ];

      const reportContentOptions = [
        {key: 'role', title: 'Job classification', checked: true},
        {key: 'date', title: 'Date', checked: true},
        {key: 'start', title: 'Start', checked: true},
        {key: 'end', title: 'End', checked: true},
        {key: 'signedHours', title: 'Regular', checked: true},
        {key: 'firstOverTime', title: 'OT 1', checked: true},
        {key: 'secondOverTime', title: 'OT 2', checked: true},
        {key: 'dailyTotalHours', title: 'Daily Total', checked: true},
      ];

      return this.$http.post(host, JSON.stringify({
        'header_content_options': headerContentOptions,
        'report_content_options': reportContentOptions,
        'ids': shiftIds,
        'group_by': 0 // associate
      }))
    },

    apiSendShiftsReport(associateNumber, dateFrom, dateTo, groupBy) {
      const host = this.getApiCallAddress() + "tc_send_shifts_report";

      return this.$http.post(host, JSON.stringify({
        'associate_number': associateNumber,
        'start': dateFrom,
        'end': dateTo,
        'group_by': groupBy
      }))
    },

    apiSendShiftsReportByShiftIds(ids) {
      const host = this.getApiCallAddress() + "tc_send_shifts_report";

      return this.$http.post(host, JSON.stringify({
        'associate_number': null,
        'start': null,
        'end': null,
        'ids': ids
      }))
    },

    apiGetAssociatePayCodeByShift(shift_id) {
      const host = this.getApiCallAddress() + "shift/pay_code/get";

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'shift_id': shift_id,
      }));
    },

    apiGetAssociatePayCodes(associate_number, date_from, date_to, page, size, sorter) {
      const host = this.getApiCallAddress() + "shift/pay_code/get_all";

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'associate_number': associate_number ?? null,
        'start': date_from,
        'end': date_to,
        'page': page,
        'size': size,
        'sorter': sorter
      }))
    },

      apiAddAssociatePayCodes(associate_numbers, pay_code, dates) {
        const host = this.getApiCallAddress() + "shift/pay_code/create";

        return this.$http.post(host, JSON.stringify({
          'project_id': this.$store.state.applicationState.currentProject.id,
          'pay_code_shift_form': {
            'associates': associate_numbers,
            'pay_code': pay_code,
            'dates': dates
          }
        }));
      },

      apiEditAssociatePayCode(shift_id, pay_code) {
        const host = this.getApiCallAddress() + "shift/pay_code/update";

        return this.$http.post(host, JSON.stringify({
          'project_id': this.$store.state.applicationState.currentProject.id,
          'shift_id': shift_id,
          'pay_code': pay_code
        }));
      },

      apiDeleteAssociatePayCode(shift_id) {
        const host = this.getApiCallAddress() + "tc_delete_associate_pay_code";

      return this.$http.post(host, JSON.stringify({shift_id}));
    },

    apiSetAdminPaymentType(paymentTypeId) {
      const host = this.getApiCallAddress() + "tc_set_admin_payment_type";

      return this.$http.post(host, JSON.stringify({payment_type_id: paymentTypeId}));
    },

    apiAddAttachmentsToShift(shiftId, fileIds) {
      const host = this.getApiCallAddress() + "tc_add_attachments_to_shift";

      return this.$http.post(host, JSON.stringify({shift_id: shiftId, file_ids: fileIds}));
    },

    apiCreatePayroll(name, startDate, endDate, files) {
      const host = this.getApiCallAddress() + "projects/payrolls/create";
      let formData = new FormData();

      formData.append('name', name);
      formData.append('start_date', startDate);
      formData.append('end_date', endDate);

      files.forEach((file) => formData.append(file.name, file))

      return this.$http.post(host, formData);
    },
    apiGetAllPayrolls() {
      const host = this.getApiCallAddress() + "projects/payrolls/get_all";

      return this.$http.post(host);
    },
    apiGetPayroll(id) {
      const host = this.getApiCallAddress() + "projects/payrolls/get";

      return this.$http.post(host, JSON.stringify({id}));
    },
    apiUpdatePayroll(id, name, startDate, endDate, files) {
      const host = this.getApiCallAddress() + "projects/payrolls/update";

      let formData = new FormData();

      formData.append('id', id);
      formData.append('name', name);
      formData.append('start_date', startDate);
      formData.append('end_date', endDate);

      files.forEach((file) => formData.append(file.name, file))

      return this.$http.post(host, formData);
    },
    apiDeletePayroll(id) {
      const host = this.getApiCallAddress() + "projects/payrolls/delete";

      return this.$http.post(host, JSON.stringify({id}));
    },
    apiAddPayrollDocuments(payrollId, files) {
      const host = this.getApiCallAddress() + "projects/payrolls/documents/add";
      let formData = new FormData();

      formData.append('payroll_id', payrollId);
      files.forEach((file) => formData.append(file.name, file));

      return this.$http.post(host, files);
    },
    apiDeletePayrollDocument(id) {
      const host = this.getApiCallAddress() + "projects/payrolls/documents/delete";

      return this.$http.post(host, JSON.stringify({id}));
    },
    apiDeletePayrollDocumentFile(id) {
      const host = this.getApiCallAddress() + "projects/payrolls/documents/delete_file";

      return this.$http.post(host, JSON.stringify({id}));
    },

    apiGetEstimatedLunches(associateNumber, start, end, lunchWaived) {
      const host = this.getApiCallAddress() + "get_estimated_lunch_time";

      return this.$http.post(host, JSON.stringify({
        associate_number: associateNumber,
        shift_start: start,
        shift_end: end,
        lunch_waived: lunchWaived ?? false
      }));
    },

    apiIsAssociateNumberBusy(number) {
      const host = this.getApiCallAddress() + "tc_is_associate_number_busy";

      return this.$http.post(host, JSON.stringify({associate_number: number}));
    },

    apiCreateUserWithAssociate(personalInformation, projectInformation, paymentInformation) {
      const host = this.getApiCallAddress() + "tc_create_user_with_associate";

      return this.$http.post(host, JSON.stringify({
        personal_information: personalInformation,
        project_information: projectInformation,
        payment_information: paymentInformation
      }));
    },

    apiGeneratePbjReport(startDate, endDate, selectedPayTypes, ignoreAuthsIssues) {
      const host = this.getApiCallAddress() + "tc_generate_pbj_report";

      return this.$http.post(host, JSON.stringify({
        start_date: startDate,
        end_date: endDate,
        selected_pay_types: selectedPayTypes,
        ignore_auths_issues: ignoreAuthsIssues
      }))
    },

    apiCheckAuths(associateNumber, shift, lunchWaived) {
      const host = this.getApiCallAddress() + "tc_check_auths"

      return this.$http.post(host, JSON.stringify({
        'associate_number': associateNumber,
        'shift': shift,
        'lunch_waived': lunchWaived
      }))
    },

    apiSendNotificationToAssociate(
      associateNumber,
      title,
      message,
      withAlert,
      withSms,
      withEmail,
      fileIds
    ) {
      const host = this.getApiCallAddress() + "tc_send_notification_to_associates";

      return this.$http.post(host, JSON.stringify({
        'associates': [associateNumber],
        'title': title,
        'message': message,
        'with_alert': withAlert,
        'with_sms': withSms,
        'with_email': withEmail,
        'file_ids': fileIds
      }))
    },

    apiGetCDPH530(confirmationId) {
      const host = this.getApiCallAddress() + 'shift/confirmation/cdph530/get';

      return this.$http.post(host, JSON.stringify({'id': confirmationId}));
    },

    apiSetAssociateNote(associate) {
      const host = this.getApiCallAddress() + 'tc_set_note_to_associate';

      return this.$http.post(host, JSON.stringify({
        'id': associate.id,
        'note': associate.note,
      }))
    },

    apiGetSignedShiftsReport(dateFrom, dateTo, associateNumber, roleId, reportFormat) {
      const host = this.getApiCallAddress() + 'tc_get_signed_shifts_report';

      return this.$http.post(host, JSON.stringify({
        'date_from': dateFrom,
        'date_to': dateTo,

        'role_id': roleId,
        'associate_number': associateNumber,
        'report_format': reportFormat
      }));
    },

    apiSetOtherPayment(requestId, amount, explanation) {
      const host = this.getApiCallAddress() + 'payment_request/other_payment/set';

      return this.$http.post(host, JSON.stringify({
        'request_id': requestId,
        'amount': amount,
        'explanation': explanation
      }))
    },
    apiDeleteOtherPayment(requestId) {
      const host = this.getApiCallAddress() + 'payment_request/other_payment/delete';

      return this.$http.post(host, JSON.stringify({
        'request_id': requestId
      }))
    },

    apiMarkManualPaymentRequestPaid(requestId) {
      const host = this.getApiCallAddress() + 'payment_request/manual/paid';

      return this.$http.post(host, JSON.stringify({
        'request_id': requestId
      }))
    },

    apiSetSuspension(associateNumber, suspension) {
      const host = this.getApiCallAddress() + 'associate/suspension/set';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'associate_number': associateNumber,
        'suspension': suspension
      }))
    },

    apiReleaseFromSuspension(associateNumber) {
      const host = this.getApiCallAddress() + 'associate/suspension/release';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'associate_number': associateNumber
      }))
    },

    apiGetKpiData(associateNumber, start, end) {
      const host = this.getApiCallAddress() + 'associate/kpi/get';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'associate_number': associateNumber, start, end
      }));
    },

    apiGetProjectStatistics(start, end, role) {
      const host = this.getApiCallAddress() + 'statistic/project/get';

      let paymentType = null;
      if (this.$store.state.applicationState.currentPaymentType) {
        paymentType = this.$store.state.applicationState.currentPaymentTypeName.toLowerCase();
      }

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'payment_type': paymentType,
        'start': start ?? null,
        'end': end ?? null,
        'role': role ? {id: role} : null
      }))
    },

    apiGetMainProjectStatistics(start, end, role) {
      const host = this.getApiCallAddress() + 'statistic/project/main_info/get';

      let paymentType = null;
      if (this.$store.state.applicationState.currentPaymentType) {
        paymentType = this.$store.state.applicationState.currentPaymentTypeName.toLowerCase();
      }

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'payment_type': paymentType,
        'start': start ?? null,
        'end': end ?? null,
        'role': role ? {id: role} : null
      }));
    },

    apiCloneAssociate(newAssociateNumber, associateInfo, withDisablePrevious) {
      const host = this.getApiCallAddress() + 'associate/clone';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'new_associate_number': newAssociateNumber,
        'associate': associateInfo,
        'disable_previous_associate': withDisablePrevious ?? false
      }))
    },

    apiSetProject(projectData, logoFileId) {
      const host = this.getApiCallAddress() + 'project/update';

      return this.$http.post(host, JSON.stringify({
        'project': projectData,
        'logo_file_id': logoFileId ?? undefined
      }))
    },

    apiGetAssociateShiftStatus(shiftId) {
      const host = this.getApiCallAddress() + 'tc_get_associate_shift_status';

      return this.$http.post(host, JSON.stringify({
        'shift_id': shiftId
      }))
    },

    apiGetTotalHoursDetailReport(dateFrom, dateTo, roleId) {
      const host = this.getApiCallAddress() + 'tc_get_total_hours_details_report';

      return this.$http.post(host, JSON.stringify({
        'date_from': dateFrom,
        'date_to': dateTo,
        'role_id': roleId
      }))
    },
    apiGetShiftLocations(shiftsIds) {
      const host = this.getApiCallAddress() + 'shift/action/get_all';

      return this.$http.post(host, JSON.stringify({
        'shift_ids': shiftsIds
      }))
    },

    apiGetGeneralDailyReport(date, filters) {
      const ptName = this.$store.state.applicationState.currentPaymentTypeName;
      const pt = ptName && ptName.toLowerCase() != 'all' ? ptName.toLowerCase() : undefined;
      const host = this.getApiCallAddress() + 'report/project/general/daily/get';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'date': date,
        'filter': {
          'associates': filters.associates,
          'role': filters.role,
          'payment_type': pt,
          'legal_entity': filters.legalEntity,
          'shift_status': filters.shiftStatus,
          'shift_payment_status': filters.shiftPaymentStatus
        }
      }));
    },

    apiAddSeveralShifts(shifts, autoSign) {
      const host = this.getApiCallAddress() + 'shift/manually_entered/several/create';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'position': {
          'latitude': 0,
          'longitude': 0,
          'altitude': 0
        },
        'associate_shifts': shifts,
        'auto_sign': autoSign
      }));
    },

    apiCreateAssociate(data) {
      const host = this.getApiCallAddress() + 'associate/create';

      return this.$http.post(host, JSON.stringify({
        'user_info': {
          'first_name': data.first_name,
          'last_name': data.last_name,
          'email': data.email,
          'password': data.password ?? '111111',
          'phone': data.phone
        },
        'associate_info': {
          'associate_number': data.associate_number,
          'project_id': this.$store.state.applicationState.currentProject.id
        },
        'payment_info': {
          'type': data.payment_type,
          'status': data.pay_status,
          'method': data.pay_method,
          'rate': data.rate
        }
      }));
    },

    apiGetAssociateOverviewInformation(date, filters) {
      const host = this.getApiCallAddress() + 'project/activity/get';
      const ptName = this.$store.state.applicationState.currentPaymentTypeName;
      const pt = ptName && ptName.toLowerCase() != 'all' ? ptName.toLowerCase() : undefined;

      return this.$http.post(host, JSON.stringify({
        'project_id': String(this.$store.state.applicationState.currentProject.id),
        'date': date,
        'role': filters && filters.role ? {
          id: filters.role
        } : undefined,
        'status': filters.status,
        'payment_type': pt
      }))
    },

    apiGetAllProjectAlerts(type, severity, dateFrom, dateTo) {
      const host = this.getApiCallAddress() + 'alert/project/get_all';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'filter': {
          'type': type,
          'severity': severity,
          'date_range': {
            'start': dateFrom,
            'end': dateTo
          }
        }
      }))
    },

    apiGetAllTimeOffRequests(associateNumber, type, status, dateFrom, dateTo) {
      const host = this.getApiCallAddress() + 'report/time_off/get_all';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'associate': associateNumber,
        'date_range': {
          'start': dateFrom,
          'end': dateTo
        },
        'type': type,
        'status': status ?? 'pending'
      }))
    },

    apiAcceptTimeOffRequest(requestId) {
      const host = this.getApiCallAddress() + 'report/time_off/accept';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'request_id': requestId
      }))
    },
    apiDeclineTimeOffRequest(requestId) {
      const host = this.getApiCallAddress() + 'report/time_off/accept';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'request_id': requestId
      }))
    },

    apiCheckShiftConflict(associateNumber, start, end, shiftId) {
      const host = this.getApiCallAddress() + 'shift/conflict/check';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'associate_number': associateNumber,
        'start': start,
        'end': end,
        'shift_id': shiftId,
        'check_pay_code': true
      }))
    },
    apiSetAssociateActivityStatus(associateNumber, status) {
      const host = this.getApiCallAddress() + 'tc_set_associate_activity_status';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'associate_number': associateNumber,
        'status': status ?? 'activate'
      }))
    },
    apiMarkShiftAsPaid(shiftIds) {
      const host = this.getApiCallAddress() + "tc_mark_shift_as_paid";

      return this.$http.post(host, JSON.stringify({
        'shifts': shiftIds
      }))
    },
    apiMarkShiftAsPaidOnRange(dateFrom, dateTo, associates, role) {
      const host = this.getApiCallAddress() + "tc_mark_shift_as_paid_for_range";

      return this.$http.post(host, JSON.stringify({
        'date_from': dateFrom,
        'date_to': dateTo,
        'associate_numbers': associates,
        'role': role
      }))
    },

    apiDownloadPayrollReport(payrollId, fileType) {
      const host = this.getApiCallAddress() + `payroll/${payrollId}/download`;

      return this.$http.post(host, JSON.stringify({
        'report_format': fileType
      }))
    },
    apiDownloadPayrollReportBasedOnRange(dateFrom, dateTo, fileType) {
      const host = this.getApiCallAddress() + `payroll/${dateFrom}/${dateTo}/download`;

      return this.$http.post(host, JSON.stringify({
        'report_format': fileType
      }))
    },
    apiDownloadWorkedShiftsReport(dateFrom, dateTo, role, status) {
      const host = this.getApiCallAddress() + 'report/worked_shifts/get';

      return this.$http.post(host, JSON.stringify({
        'date_from': dateFrom,
        'date_to': dateTo,
        'role': role,
        'status': status
      }));
    },
    apiSetPayrollSystemId(associateId, payrollSystemId) {
      const host = this.getApiCallAddress() + 'tc_set_payroll_system_id';

      return this.$http.post(host, JSON.stringify({
        'associate_id': associateId,
        'payroll_system_id': payrollSystemId
      }))
    },
    apiCreateWriteUp(
      associateNumber,
      date,
      followUpReviewDays,
      disciplinaryActionType,
      violationCategoryType,
      reasonForAction,
      expectedLevelOfPerformance
    ) {
      const host = this.getApiCallAddress() + 'associate/writeup/create';

      return this.$http.post(host, JSON.stringify({
        'project_id': this.$store.state.applicationState.currentProject.id,
        'writeup': {
          'author': {
            'project_id': this.$store.state.applicationState.currentProject.id,
            'associate_number': this.$store.state.applicationState.associate.associate_number
          },
          'consumer': {
            'project_id': this.$store.state.applicationState.currentProject.id,
            'associate_number': associateNumber
          },
          'create_date': date,
          'follow_up_review_date_days': followUpReviewDays,
          'disciplinary_action_type': disciplinaryActionType,
          'violation_category': violationCategoryType,
          'reason_for_action': reasonForAction,
          'expected_level_of_performance': expectedLevelOfPerformance,
          'status': 'not_signed',
          'authorization': null
        }
      }));
    }
  }
}
