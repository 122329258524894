<template>
  <div>
    <h2 class="view-header">Documents Upload</h2>
    <div class="ui segment">
      <a-form
        :form="form"
        class="upload_form"
        layout="vertical"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-item
          v-for="field in input_fields"
          :key="field.label"
          :label="field.label"
        >
          <a-input
            v-decorator="field.decorator"
            :placeholder="field.placeholder"
          />
        </a-form-item>
        <a-form-item
          v-for="field in select_fields"
          :style="[field.disabled ? {'display': 'none'} : {}]"
          :key="field.label"
          :label="field.label"
        >
          <a-select
            v-decorator="field.decorator"
            :placeholder="field.placeholder"
            :mode="field.mode"
          >
            <a-select-option
              v-for="option in field.options"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 24 }">
          <a-upload-dragger
            :file-list="file_list"
            :before-upload="beforeUpload"
            :remove="handleRemove"
            :multiple="true"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox"/>
            </p>
            <p class="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p class="ant-upload-hint">
              Support for upload of .pdf files
            </p>
          </a-upload-dragger>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 24 }">
          <a-button
            icon="upload"
            :disabled="upload_disabled"
            :loading="uploading"
            @click="upload"
          >
            {{ uploading ? 'Uploading' : 'Upload' }}
          </a-button>
          <a-button
            icon="delete"
            @click="reset"
            style="margin-left: 5px"
          >
            Clear
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<style scoped>
.upload_form {
  width: 80%;
  margin: 30px auto auto;
}

.ui.segment {
  margin: 0 auto;
  max-width: 500px;
}
</style>

<script>
import {Icon, Upload, Form, Input, Select, Button, notification} from "ant-design-vue";
import api from "@/api";

export default {
  name: "Documents",
  components: {
    'a-form': Form,
    'a-form-item': Form.Item,
    'a-input': Input,
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-icon': Icon,
    'a-button': Button,
    'a-upload-dragger': Upload.Dragger,
  },
  data() {
    return {
      file_list: [],
      uploading: false,


      selected_roles: [],

      form: this.$form.createForm(this, {}),
      select_fields: [
        {
          label: 'Group',
          decorator: ['group', {rules: [{required: true, message: 'Please select document group'}]}],
          placeholder: 'Please select a group',
          options: [],
          mode: 'default'
        },
        {
          label: 'Job classifications',
          decorator: ['roles', {rules: [{required: false}]}],
          placeholder: 'Please select job classifications',
          options: [],
          mode: 'multiple',
          disabled: true
        },
        {
          label: 'Payment types',
          decorator: ['payment_types', {rules: [{required: false}]}],
          placeholder: 'Please select payment types',
          options: [],
          mode: 'multiple',
        },
      ],

      roles: [],
    }
  },

  mixins: [api],

  computed: {
    upload_disabled() {
      return this.file_list.length < 1;
    },
    projectId() {
      return this.$store.state.applicationState.currentProject.id
    }
  },
  methods: {
    loadData() {
      this.apiGetProjectDocGroups()
        .then((response) => {
          if (response.data && response.data.groups) {
            this.select_fields.find(field => field.label.toLowerCase() == 'group').options = response.data.groups;
          }
        })
        .catch((err) => console.error(err))
      this.apiGetPaymentTypes()
        .then((response) => {
          if (response.data && response.data.payment_types) {
            this.select_fields.find(field => field.label.toLowerCase() == 'payment types').options = response.data.payment_types;
          }
        })

      const roles_object = this.select_fields.find(field => field.label.toLowerCase() == 'job classifications');
      roles_object.options = []

      this.apiGetRoles(this.projectId)
        .then((response) => {
          if (response.data && response.data.roles && response.data.roles.length > 0) {
            roles_object.options = response.data.roles;
            roles_object.disabled = false;
          } else {
            roles_object.disabled = true
          }
        })
    },
    beforeUpload(file) {
      if (file.type != 'application/pdf') {
        notification['error']({
          message: 'Error',
          description: 'You can only upload PDF files',
        })
      } else {
        this.file_list = [...this.file_list, file];
      }
      return false;
    },
    upload() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.uploading = true;

          if (this.file_list.length < 1) {
            notification['error']({
              message: 'Error',
              description: 'Add file(s) to upload and try again',
            })
            this.uploading = false;
            return;
          }

          this.apiUploadFiles(this.file_list)
            .then((response) => {
              if (response.data && response.data.ids && response.data.ids.length > 0) {
                const ids = response.data.ids
                let document_props = {
                  'group': values.group,
                  'roles': values.roles,
                  'payment_types': values.payment_types,
                }
                ids.forEach(id => {
                  const document = {
                    ...document_props,
                    'pdf_file_id': id
                  }
                  this.apiAddDocument(document)
                    .then((response) => {
                      if (response.data.error_code == 0) {
                        const id = response.data.document.id
                        const name = response.data.document.name

                        notification['success']({
                          message: 'Success',
                          description: 'The document with id #' + id + ', name \"' + name + '\" was uploaded'
                        })
                        this.uploading = false;
                        this.reset();
                      } else {
                        notification['error']({
                          message: 'Error',
                          description: response.data.msg
                        })
                      }
                    })
                    .catch((err) => {
                      notification['error']({
                        message: 'Error',
                        description: 'An error occurred while uploading the document. Please try again.'
                      })
                    })
                })
                this.uploading = false;
              } else {
                notification['error']({
                  message: 'Error',
                  description: 'An error occurred while uploading files. Please try again.'
                })
                this.uploading = false;
              }
            })
            .catch(() => {
              notification['error']({
                message: 'Error',
                description: 'An error occurred while uploading files. Please try again.'
              })
              this.uploading = false;
            })
        } else {
          notification['error']({
            message: 'Error',
            description: 'Fill in all the required fields and try again'
          })
          this.uploading = false;
        }
      })
    },

    handleRemove(file) {
      const index = this.file_list.indexOf(file);
      const newFileList = this.file_list.slice();
      newFileList.splice(index, 1);
      this.file_list = newFileList;
    },

    reset() {
      this.form.resetFields();
      this.file_list = [];
    },
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>
