var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('reset-password',{key:_vm.resetPasswordKey,attrs:{"visible":_vm.resetPasswordModalVisible},on:{"cancel":_vm.closeResetPasswordModal}}),_c('div',{staticClass:"sign-in-form"},[_vm._m(0),_c('transition',{attrs:{"name":"bounce"}},[(_vm.authErrorMsg)?_c('a-alert',{attrs:{"type":"error","message":_vm.authErrorMsg}}):_vm._e()],1),_c('a-form',{staticClass:"sign-in-form-fields",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [
                { required: true, message: 'Please enter your username' },
                { type: 'email', message: _vm.usernameErrorMsg }
              ],
              validateTrigger: ['blur', 'change']
            }
          ]),expression:"[\n            'username',\n            {\n              rules: [\n                { required: true, message: 'Please enter your username' },\n                { type: 'email', message: usernameErrorMsg }\n              ],\n              validateTrigger: ['blur', 'change']\n            }\n          ]"}],attrs:{"disabled":_vm.loading,"placeholder":"Username","allow-clear":true}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [{ required: true, message: 'Please enter your password' }],
              validateTrigger: ['blur', 'change']
            }
          ]),expression:"[\n            'password',\n            {\n              rules: [{ required: true, message: 'Please enter your password' }],\n              validateTrigger: ['blur', 'change']\n            }\n          ]"}],attrs:{"type":"password","placeholder":"Password","disabled":_vm.loading}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('div',{staticClass:"flex flex-column gap-2 justify-content-center mt-4"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","disabled":_vm.hasErrors(_vm.form.getFieldsError()) || _vm.loading}},[_vm._v(" Login ")]),_c('a-button',{attrs:{"disabled":_vm.loading},on:{"click":_vm.startResetPassword}},[_vm._v(" Forgot password? ")])],1)])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"epeople-logo-with-header"},[_c('img',{staticClass:"epeople-logo",attrs:{"src":require("@/assets/logo.png"),"alt":"ePeople Logo"}}),_c('h3',{staticClass:"epeople-header"},[_vm._v("ePeople Time")])])
}]

export { render, staticRenderFns }