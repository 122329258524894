<template>
  <div>
    <h2 class="view-header">Shift Authorizations</h2>

    <project-shift-auths-filters
      :current-filters="filters"
      :visible="filtersModalVisible"
      :key="filtersModalKey"
      @apply="applyFilters"
    />
    <v-tour name="authsTour" :steps="tourSteps" :callbacks="callbacks" :options="vueTourOptions"/>

    <send-notification :associate="associateProfileToSendSms" @close="closeSmsModal"/>
    <a-modal
      title="Authorizations List"
      width="1200px"
      v-model="authorizationsListVisible"
      @cancel="handleAuthListCancel"
    >
      <shift-auth-list-modal
        ref="shiftAuthListModal"
        :auths-list="currentShiftAuthsList"
        @change="handleSelectedAuthListChange"
      />
      <template slot="footer">
        <a-button
          id="accept-auths-btn"
          type="primary"
          :disabled="selectedCurrentShiftAuthsList.length == 0"
          @click="initAcceptSelectedAuthList"
        >
          Accept
        </a-button>
        <a-button
          id="deny-auths-btn"
          type="danger"
          :disabled="selectedCurrentShiftAuthsList.length == 0"
          @click="initDenySelectedAuthList"
        >
          Deny
        </a-button>
        <a-button
          @click="handleAuthListCancel"
        >
          Cancel
        </a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="visibleAssociateShiftModal"
      width="950px"
      :footer="null"
      @cancel="closeAssociateShiftModal"
    >
      <associate-shift
        :key="associateShiftComponentKey"
        :shift-id="editingAssociateShiftId"
        :associate-number="employee_id"
        @on-add-or-edit="closeAssociateShiftModalWithChanges"
        @on-shift-deletion="openShiftDeletionConfirmationModal"
        @shift-exists="handleShiftExists"
      />
    </a-modal>
    <a-modal
      title="Associate Profile Edit"
      width="80%"
      :footer="null"
      v-model="associateProfileModalVisible"
      @ok="endEditAssociateProfile"
      @cancel="endEditAssociateProfile"
    >
      <div style="overflow-x: auto; width: 100%">
        <associate-profile :associate-number="editingAssociateNumber" @edited="handleEditedAssociateProfile"/>
      </div>
    </a-modal>
    <a-modal
      width="600px"
      title="Reason of Deny Authorization"
      v-model="denyReasonVisible"
      @ok="confirmDeny"
    >
      <shift-auth-deny-form
        ref="shiftAuthDenyForm"
        :decline-reasons="filteredDeclineReasons"
        @reason="setResolveComment"
        @params="setDenyParams"
      />
    </a-modal>
    <a-modal
      width="600px"
      title="Accept Authorization Comment"
      v-model="acceptCommentVisible"
      @ok="confirmAccept"
    >
      <a-input placeholder="Optional" v-model="resolveComment"/>
    </a-modal>

    <div class="flex justify-content-end align-items-center gap-2">
      <a-button-group>
        <a-button
          icon="filter"
          :disabled="loading"
          @click="showFilterModal"
        />
        <a-button
          icon="undo"
          :disabled="loading || !enableResetButton"
          @click="resetFilters"
        />
      </a-button-group>

      <a-date-picker
        v-model="dayFrom"
        :disabled-date="disabledStartDate"
        :disabled="loading"
        format="MM/DD/YYYY"
        placeholder="Start Date"
        style="width: 100px"
      />
      <a-date-picker
        v-model="dayTo"
        :disabled-date="disabledEndDate"
        :disabled="loading"
        format="MM/DD/YYYY"
        placeholder="End Date"
      />
      <div class="flex flex-column">
        <div>Total Auths: {{ total_auths || '...' }}</div>
        <div>Shifts With Auths: {{ total_shifts_with_auths || '...' }}</div>
      </div>
    </div>
    <a-table
      size="small"
      rowKey="shift.id"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="pagination"
      :expanded-row-render="expandedRowRender"
      :expand-row-by-click="true"
      @change="handleTableChange"
    >
      <span slot="authorizations" slot-scope="text, record">
        <a-tooltip v-for="auth in record.authorizations" :key="auth.id">
          <template slot="title">
            {{ authTypeDescriptions[auth.type] ?? 'Description is not available' }}
          </template>
          <a-tag class="auth-tag" :color="getAuthStatusTagColor(auth)">
            {{ convertType(auth.type) }}
          </a-tag>
        </a-tooltip>
      </span>
      <span slot="date" slot-scope="text, record">
        {{ formatDateString(record.shift.date) }}
      </span>
      <span slot="associate" slot-scope="text, record" :key="record.photo_url">
        <grouped-associate-info
          :first-name="record.associate.first_name"
          :last-name="record.associate.last_name"
          :photo-url="record.associate.photo_url"
        />
      </span>
      <span slot="providers" slot-scope="text, record" style="white-space: pre-wrap">
        <template v-if="!record.providersList.length">
          No Providers
        </template>
        <template v-else>
          <div v-for="name in record.providersList">
            {{ name }}
          </div>
        </template>
      </span>
      <span slot="confirmation" slot-scope="text, record">
        <template v-if="!record.confirmation">
          -
        </template>
        <template v-else>
          <a-tag class="auth-tag" :color="getConfirmationStatusColor(record.confirmation)">
            {{ convertConfirmationStatus(record.confirmation) }}
          </a-tag>
        </template>
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-button-group size="small">
          <a-button
            id="show-auths-btn"
            title="Show Authorizations"
            @click="showShiftAuthorizations(record)"
          >
            Show
          </a-button>
          <a-button
            id="edit-shift-btn"
            title="Adjust Shift"
            @click="editShift(record.shift.id)"
          >
            Adjust
          </a-button>
          <a-button
            id="edit-profile-btn"
            title="Edit Profile"
            @click="startEditAssociateProfile(record.associate.associate_number)"
          >
            Profile
          </a-button>
          <a-button
            id="send-msg-btn"
            :disabled="!record.associate.phone"
            @click="openSmsSendModal(record.associate)"
          >
            Message
          </a-button>
        </a-button-group>
      </span>
    </a-table>
  </div>
</template>

<script>

import moment from 'moment-timezone';
import ShiftAuthDenyForm from "@/components/shift-auth-deny-form.vue";
import ShiftAuthListModal from "@/components/shift-auth-list-modal.vue";
import SendNotification from "@/components/send-notification.vue";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Icon,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Select,
  Table,
  Tag,
  Switch,
  Tooltip
} from "ant-design-vue";
import api from "@/api";
import shiftsHelper from "@/helpers/shifts";
import store from "@/store";
import Util from '@/util';
import shiftAuths from "@/helpers/shift-auths";
import AssociateShift from "@/components/associate-shift.vue";
import AssociateProfile from "@/components/associate-profile.vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import ProjectShiftAuthsFilters from "@/components/project-shift-auths-filters.vue";
import {shiftAuthDescriptions, statusTagColors, vueTourOptions} from "@/const";

const AuthStatus = Object.freeze({
  INITIAL: 'initial',
  ACCEPTED: 'accepted',
  DECLINED: 'declined'
});

const initialPagination = {
  showSizeChanger: true,
  pageSizeOptions: ['10', '50', '100'],
  pageSize: 50,
  total: 0,
  current: 1
};

const filtersBaseState = Object.freeze({
  associateNumber: undefined,
  authType: undefined,
  withScheduleError: false,
  assignedToMe: false,
});

export default {
  components: {
    SendNotification,
    'a-date-picker': DatePicker,
    'a-row': Row,
    'a-col': Col,
    'a-input': Input,
    'a-button': Button,
    'a-modal': Modal,
    'a-button-group': Button.Group,
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-checkbox': Checkbox,
    'a-pagination': Pagination,
    'a-table': Table,
    'a-tag': Tag,
    'a-tooltip': Tooltip,
    'a-icon': Icon,
    'a-switch': Switch,
    'shift-auth-deny-form': ShiftAuthDenyForm,
    'shift-auth-list-modal': ShiftAuthListModal,
    'associate-shift': AssociateShift,
    'associate-profile': AssociateProfile,
    'grouped-associate-info': GroupedAssociateInfo,
    'project-shift-auths-filters': ProjectShiftAuthsFilters
  },
  data() {
    return {
      tableData: [],

      workDateFormat: store.state.applicationState.dateFormat,
      projectId: store.state.applicationState.currentProject.id,

      loading: false,
      authorizationsListVisible: false,

      currentShiftRecord: undefined,
      currentShiftAuthsList: [],
      selectedCurrentShiftAuthsList: [],

      showOnlyWithScheduleError: false,
      showOnlyAssignedToUser: false,

      dayFrom: null,
      dayTo: null,

      found_employees: [],
      employee_id: undefined,

      resolveComment: undefined,

      acceptCommentVisible: false,
      denyReasonVisible: false,

      selectedAuthType: undefined,

      shiftAuthTypes: [],

      editingAssociateShiftId: undefined,
      associateShiftComponentKey: 0,
      visibleAssociateShiftModal: false,

      editingAssociateNumber: undefined,

      associateProfileModalVisible: false,

      total_auths: 0,
      total_shifts_with_auths: 0,

      associateProfileToSendSms: undefined,

      params: {
        readyForPayment: false,
        withWriteUp: false,
        suspend: 0,
        terminated: false
      },

      pagination: {
        pageSizeOptions: ['10', '50', '100'],
        pageSize: 50,
        current: 1,
        total: 0,
        showSizeChanger: true,
      },
      columns: [
        {
          title: 'Shift ID',
          dataIndex: 'shift.id',
          key: 'shiftId',
          align: 'center',
          sorter: true
        },
        {
          title: 'Date',
          key: 'date',
          scopedSlots: {customRender: 'date'},
          align: 'center',
          sorter: true
        },
        {
          title: 'Associate',
          key: 'associate',
          scopedSlots: {customRender: 'associate'},
          align: 'center',
          sorter: true
        },
        {
          title: 'Providers',
          key: 'providers',
          scopedSlots: {customRender: 'providers'},
          align: 'center'
        },
        {
          title: 'Confirmation',
          key: 'confirmation',
          scopedSlots: {customRender: 'confirmation'},
          align: 'center'
        },
        {
          title: 'Authorizations',
          key: 'authorizations',
          scopedSlots: {customRender: 'authorizations'},
          align: 'center'
        },
        {
          title: 'Actions',
          key: 'actions',
          scopedSlots: {customRender: 'actions'},
          align: 'center'
        }
      ],

      nestedColumns: [
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          className: 'column-wrap'
        },
        {
          title: 'Start',
          dataIndex: 'start',
          key: 'start',
        },
        {
          title: 'End',
          dataIndex: 'end',
          key: 'end',
        },
        {
          title: 'Work Hrs',
          dataIndex: 'work_hours'
        },
        {
          title: 'Pay Hrs',
          dataIndex: 'pay_hours'
        },
        {
          title: 'L1 Start',
          dataIndex: 'first_lunch_start',
          key: 'first_lunch_start',
        },
        {
          title: 'L1 End',
          dataIndex: 'first_lunch_end',
          key: 'first_lunch_end',
        },
        {
          title: 'L1 Dur',
          dataIndex: 'lunch_duration',
          key: 'lunch_duration'
        },
        {
          title: 'L2 Start',
          dataIndex: 'second_lunch_start',
          key: 'second_lunch_start',
        },
        {
          title: 'L2 End',
          dataIndex: 'second_lunch_end',
          key: 'second_lunch_end',
        },
        {
          title: 'L2 Dur',
          dataIndex: 'second_lunch_duration',
          key: 'second_lunch_duration'
        }
      ],

      filters: {...filtersBaseState},
      filtersModalVisible: false,
      filtersModalKey: 0,

      tourSteps: [
        {
          target: '.ant-table-row',
          header: {
            title: "Shift Authorizations"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: this.authsTourBeforeStartCallback,
          content: `
            <p>Authorizations occur when associates perform actions during their shifts that contradict company policies. You must review these actions to determine if they were justified or if disciplinary action is needed.</p>
            <p>This section shows details about the authorization, including the shift it occurred in, the date, and the associate involved. This helps you understand the context and the individual responsible.</p>
          `
        },
        {
          target: '#shift-table',
          header: {
            title: "Shift Info"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: this.beforeShiftTableStepCallback,
          content: "This section provides detailed information about the shift during which the violation occurred, helping you review the context of the authorization."
        },
        {
          target: '.ant-table-row td:nth-child(5)',
          header: {
            title: "Authorizations Info"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: `
          <p>This section shows the authorizations that occurred as a result of signing the shift. There are various types of authorizations:</p>
          <ul style="text-align: left">
            <li>Early Start</li>
            <li>Late Start</li>
            <li>No Meal Break</li>
            <li>Early Meal Break End</li>
            <li>No Second Meal Break</li>
            <li>Early Second Meal Break End</li>
            <li>Early End</li>
            <li>Late End</li>
            <li>Edit Shift</li>
            <li>Overtime</li>
            <li>Outside of Work Area</li>
            <li>Absence on Shift</li>
            <li>Manually Shift</li>
            <li>Signed Actual Time Discrepancy</li>
            <li>No Rest Break</li>
          </ul>
        `
        },
        {
          target: '.ant-table-row td:nth-child(6)',
          header: {
            title: "Actions"
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: `
          <p style="text-align: left">This section allows you to:</p>
          <ul style="text-align: left">
            <li>
              View authorisations and approve/decline it
            </li>
            <li>
              Adjust shift
            </li>
            <li>
              View user profile
            </li>
            <li>
              Send a message to associate
            </li>
          </ul>
          `
        },
        {
          target: '#show-auths-btn',
          header: {
            title: "Show Authorizations",
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: "Clicking 'Show Authorizations' opens a detailed view where you can see the history of authorizations, who approved them, explanations for the actions, and their current status."
            + " When selecting authorisations from the list, you can accept or deny them"
        },
        {
          target: '#accept-auths-btn',
          header: {
            title: "Accept Authorizations",
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: this.beforeAuthsWindowStepCallback,
          content: "Click 'Accept' to approve the authorization, indicating that the action was justified."
        },
        {
          target: '#deny-auths-btn',
          header: {
            title: "Deny Authorizations",
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: `
            <p style="text-align: left">Click 'Deny' to reject the authorization, stating that the action was unjustified. In the modal window, you will need to:</p>
            <ul style="text-align: left">
              <li>Step 1: Select a reason for denial.</li>
              <li>Step 2: Optionally issue a disciplinary action if necessary.</li>
            </ul>
          `
        },
        {
          target: '#edit-shift-btn',
          header: {
            title: "Adjust Shift",
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          before: this.beforeEditShiftButtonStepCallback,
          content: "You can also edit the shift to address the violation, modifying details\n" +
            "as necessary."
        },
        {
          target: '#edit-profile-btn',
          header: {
            title: "View Associate Profile",
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: "Review the associate's profile to evaluate their overall performance and consider any necessary adjustments to their role or responsibilities."
        },
        {
          target: '#send-msg-btn',
          header: {
            title: "Send Message Button",
          },
          params: {
            placement: "bottom",
            enableScrolling: false
          },
          content: "Use the message button to send a message to the associate, advising them on what they need to improve and ensuring they understand the company's policies."
        },
      ],
      callbacks: {
        onSkip: () => localStorage.setItem('authsTourPassed', true),
        onFinish: () => localStorage.setItem('authsTourPassed', true)
      }
    }
  },
  mixins: [api, shiftsHelper],
  watch: {
    dayFrom() {
      this.loadData()
    },
    dayTo() {
      this.loadData()
    },
    selectedAuthType() {
      this.loadData()
    },
    filters: {
      handler() {
        this.loadData();
      },
      deep: true
    }
  },
  computed: {
    vueTourOptions() {
      return vueTourOptions
    },
    user() {
      return this.$store.state.applicationState.user;
    },
    enableResetButton() {
      return JSON.stringify(filtersBaseState) != JSON.stringify(this.filters) || this.dayFrom || this.dayFrom;
    },
    fullSelectedAuths() {
      if (this.currentShiftAuthsList.length == 0 || this.currentShiftAuthsList.length == 0) {
        return [];
      }

      return this.currentShiftAuthsList.filter(auth =>
        this.selectedCurrentShiftAuthsList.includes(auth.id)
      );
    },
    dateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    timeFormat() {
      return this.$store.state.applicationState.timeFormat;
    },
    filteredDeclineReasons() {
      const reasons = {};

      if (this.fullSelectedAuths.length > 0) {
        this.fullSelectedAuths.forEach(async (auth) => {
          const declineReasons = await this.getDeclineReasons();
          const authTypeReason = declineReasons[auth.type];
          if (!authTypeReason) {
            return;
          }

          reasons[auth.type] = authTypeReason;
        })
      }

      return reasons;
    },
    authTypeDescriptions() {
      return shiftAuthDescriptions;
    }
  },
  methods: {
    convertType: shiftAuths.convertType,
    updateTotal(newTotal) {
      this.pagination = {...this.pagination, total: newTotal};
    },
    loadData(sorter) {
      if (this.loading) {
        return;
      }

      this.tableData = [];
      this.loading = true;

      this.apiGetShiftAuthsGroupedByShifts(
        this.projectId,
        this.pagination,
        this.$store.state.applicationState.currentPaymentType,
        this.filters.associateNumber,
        this.filters.withScheduleError,
        this.filters.assignedToMe,
        this.filters.authType,
        this.dayFrom,
        this.dayTo,
        sorter)
        .then((response) => {
          this.tableData = response.body.authorizations_by_shift.map((record) => {
            return {
              ...record,
              providersList: this.getAuthProviders(record),
              confirmation: this.getConfirmationStatus(record)
            }
          });
          this.total_auths = response.body.total_auths;
          this.total_shifts_with_auths = response.body.total_shifts_with_auths;
          this.updateTotal(response.body.total_shifts_with_auths);
        })
        .finally(() => this.loading = false)
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination = {...pagination}
      this.loadData(sorter)
    },
    formatDateString(dateString) {
      return moment(dateString).format(this.workDateFormat);
    },

    showShiftAuthorizations(authRecord) {
      if (this.$refs.shiftAuthListModal) {
        this.$refs.shiftAuthListModal.clearData();
      }
      this.currentShiftRecord = authRecord;
      this.currentShiftAuthsList = authRecord.authorizations;
      this.authorizationsListVisible = true;
    },

    handleSelectedAuthListChange(authList) {
      this.selectedCurrentShiftAuthsList = authList;
    },

    acceptSelectedAuthList() {
      this.apiSetShiftAuthStatusForMultipleAuths(this.selectedCurrentShiftAuthsList, AuthStatus.ACCEPTED, this.resolveComment)
        .then((response) => {
          if (response.body.error_code != '0') {
            this.showNotification('error', 'Error', 'Authorization statuses were not set due to an error. Try again');
            this.closeCurrentShiftAuthsModalWithListClean();
            return;
          }

          this.setShiftAuthStatusesLocally(AuthStatus.ACCEPTED);
          this.updateTotalAuths()

          this.showNotification('success', 'Success', 'Authorization statuses were set successfully');
        })
        .finally(() => this.closeCurrentShiftAuthsModalWithListClean());
    },
    denySelectedAuthList() {
      this.apiSetShiftAuthStatusForMultipleAuths(this.selectedCurrentShiftAuthsList, AuthStatus.DECLINED, this.resolveComment, this.params)
        .then((response) => {
          if (response.body.error_code != '0') {
            this.showNotification('error', 'Error', 'Authorization statuses were not set due to an error. Try again');
            this.cleanShiftAuthsEditModalData();
            return;
          }

          this.setShiftAuthStatusesLocally(AuthStatus.DECLINED);

          this.showNotification('success', 'Success', 'Authorization statuses were set successfully');
        })
        .finally(() => this.cleanShiftAuthsEditModalData());
    },

    setShiftAuthStatusesLocally(status) {
      this.currentShiftRecord.authorizations.forEach(auth => {
        if (this.selectedCurrentShiftAuthsList.includes(auth.id)) {
          auth.status = status;
          auth.reviewed_by = {
            first_name: this.user.first_name,
            last_name: this.user.last_name
          };
          auth.review_date = moment();
        }
      })

      if (!this.currentShiftRecord.authorizations.some(auth => auth.status == AuthStatus.INITIAL)) {
        this.tableData = this.tableData.filter(item => item.shift.id !== this.currentShiftRecord.shift.id);
      }
    },

    updateTotalAuths() {
      this.total_auths -= this.selectedCurrentShiftAuthsList.length
      if (!this.currentShiftRecord.authorizations.some(auth =>
        auth.status == AuthStatus.INITIAL || auth.status == AuthStatus.DECLINED)) {
        this.total_shifts_with_auths -= 1;
      }
    },

    initAcceptSelectedAuthList() {
      this.closeCurrentShiftAuthsModal();
      this.acceptCommentModalOpen();
    },
    initDenySelectedAuthList() {
      this.closeCurrentShiftAuthsModal();
      this.denyReasonSelectorOpen();
    },

    acceptCommentModalOpen() {
      this.acceptCommentVisible = true;
    },
    denyReasonSelectorOpen() {
      const fullSelectedAuths = this.currentShiftAuthsList.filter(auth =>
        this.selectedCurrentShiftAuthsList.includes(auth.id)
      );

      this.denyReasonVisible = true;
      this.$nextTick(() => {
        this.$refs.shiftAuthDenyForm.setAuths(fullSelectedAuths);
      });
    },
    setResolveComment(reason) {
      this.resolveComment = reason;
    },
    setDenyParams(params) {
      this.params = params;
    },
    confirmDeny() {
      this.denyReasonVisible = false;
      if (this.resolveComment && this.selectedCurrentShiftAuthsList && this.selectedCurrentShiftAuthsList.length > 0) {
        this.denySelectedAuthList();
      } else {
        notification['warning']({
          message: 'Error',
          description: 'Please fill deny reason and try again'
        })
      }
    },
    confirmAccept() {
      this.acceptCommentVisible = false;

      if (this.selectedCurrentShiftAuthsList && this.selectedCurrentShiftAuthsList.length > 0) {
        this.acceptSelectedAuthList();
      }
    },
    closeCurrentShiftAuthsModal() {
      this.authorizationsListVisible = false;
    },
    cleanShiftAuthsEditModalData() {
      this.currentShiftAuthsList = [];
      this.currentShiftRecord = undefined;
      this.selectedCurrentShiftAuthsList = [];
      this.resolveComment = "";
      this.params = {
        readyForPayment: false,
        withWriteUp: false,
        suspend: 0,
        terminated: false
      };
    },
    closeCurrentShiftAuthsModalWithListClean() {
      this.closeCurrentShiftAuthsModal();
      this.cleanShiftAuthsEditModalData();
    },

    handleAuthListCancel() {
      this.closeCurrentShiftAuthsModalWithListClean();
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    expandedRowRender(record) {
      const formattedDurations = this.formatShiftAuthDurations(record.shift);

      const data = [
        {
          key: record.id,
          type: 'Scheduled',
          date: formattedDurations.scheduled.date,
          start: formattedDurations.scheduled.start,
          end: formattedDurations.scheduled.end,
          first_lunch_start: '-',
          first_lunch_end: '-',
          second_lunch_start: '-',
          second_lunch_end: '-',
          duration: record.shift.scheduled_duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(record.shift.scheduled_duration) : '-',
          work_hours: '-',
          pay_hours: '-',
          lunch_duration: '-',
          second_lunch_duration: '-'
        },
        {
          key: record.id,
          type: 'Actual',
          date: formattedDurations.actual.date,
          start: formattedDurations.actual.start,
          end: formattedDurations.actual.end,
          first_lunch_start: '-',
          first_lunch_end: '-',
          second_lunch_start: '-',
          second_lunch_end: '-',
          duration: record.shift.duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(record.shift.scheduled_duration) : '-',
          work_hours: '-',
          pay_hours: '-',
          lunch_duration: '-',
          second_lunch_duration: '-'
        },
        {
          key: record.id,
          type: 'Signed',
          date: formattedDurations.signed.date,
          start: formattedDurations.signed.start,
          end: formattedDurations.signed.end,
          first_lunch_start: formattedDurations.signed.first_lunch_start,
          first_lunch_end: formattedDurations.signed.first_lunch_end,
          second_lunch_start: formattedDurations.signed.second_lunch_start,
          second_lunch_end: formattedDurations.signed.second_lunch_end,
          duration: record.shift.duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(record.shift.duration) : '-',
          work_hours: this.formatWorkHours(
            record.shift.work_start ? moment.utc(record.shift.work_start) : null,
            record.shift.work_end ? moment.utc(record.shift.work_end) : null,
            record.shift.lunch_start ? moment.utc(record.shift.lunch_start) : null,
            record.shift.lunch_end ? moment.utc(record.shift.lunch_end) : null,
            record.shift.second_lunch_start ? moment.utc(record.shift.second_lunch_start) : null,
            record.shift.second_lunch_end ? moment.utc(record.shift.second_lunch_end) : null
          ),
          pay_hours: this.formatPayHours(
            record.shift.scheduled_start ? moment.utc(record.shift.scheduled_start) : null,
            record.shift.scheduled_end ? moment.utc(record.shift.scheduled_end) : null,
            record.shift.work_start ? moment.utc(record.shift.work_start) : null,
            record.shift.work_end ? moment.utc(record.shift.work_end) : null,
            record.shift.lunch_start ? moment.utc(record.shift.lunch_start) : null,
            record.shift.lunch_end ? moment.utc(record.shift.lunch_end) : null,
            record.shift.second_lunch_start ? moment.utc(record.shift.second_lunch_start) : null,
            record.shift.second_lunch_end ? moment.utc(record.shift.second_lunch_end) : null
          ),
          lunch_duration: record.shift.lunch_duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(record.shift.lunch_duration) : '-',
          second_lunch_duration: record.shift.second_lunch_duration != 0 ? Util.formatMinutesToHoursAndMinutesDuration(record.shift.second_lunch_duration) : '-',
        }
      ];

      return <a-table id="shift-table" size="small" columns={this.nestedColumns} dataSource={data} rowKey="key"
                      pagination={false}/>;
    },

    getAuthStatusTagColor(auth) {
      if (auth.status == AuthStatus.ACCEPTED) {
        return 'rgb(55, 138, 90)';
      } else if (auth.status == AuthStatus.DECLINED) {
        return 'red';
      }

      return '';
    },

    fetchUsers(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout)
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.apiSearchAssociate(value)
          .then((resp) => {
            this.found_employees = resp.data.associate_list.map(user => ({
              label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
              key: user.employee_id,
              value: user.employee_id,
            }))
          })
          .catch(err => console.error(err))
      }, 1000)
    },

    disabledStartDate(startValue) {
      const endValue = this.dayTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },

    disabledEndDate(endValue) {
      const startValue = this.dayFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    editShift(shiftId) {
      this.editingAssociateShiftId = shiftId;
      this.openAssociateShiftModal();
    },

    openAssociateShiftModal() {
      this.associateShiftComponentKey++;
      this.visibleAssociateShiftModal = true;
    },

    closeAssociateShiftModal() {
      this.visibleAssociateShiftModal = false;
      this.clearAssociateShiftModalData();

      this.associateShiftComponentKey++;
    },

    closeAssociateShiftModalWithChanges() {
      this.closeAssociateShiftModal();
      this.loadData();
    },

    openShiftDeletionConfirmationModal(shiftId) {
      this.deletingAssociateShiftId = shiftId;
      this.visibleAssociateShiftModal = false;
      this.visibleShiftDeletionConfirmationModal = true;
    },

    handleShiftExists(overlappingShifts) {
      this.visibleAssociateShiftModal = false;

      this.visibleShiftAlreadyExistsModal = true;
      this.overlappingShifts = overlappingShifts;
    },

    endEditAssociateProfile() {
      this.editingAssociateNumber = undefined;
      this.associateProfileModalVisible = false;
    },

    handleEditedAssociateProfile() {
      this.endEditAssociateProfile();
      this.loadData();
    },

    startEditAssociateProfile(associateNumber) {
      this.editingAssociateNumber = associateNumber;
      this.associateProfileModalVisible = true;
    },

    openSmsSendModal(associate) {
      this.associateProfileToSendSms = associate;
    },
    closeSmsModal() {
      this.associateProfileToSendSms = undefined;
    },

    applyFilters(filters) {
      if (JSON.stringify(filters) == JSON.stringify(this.filters)) {
        return;
      }

      this.filters = {...filters};
      this.closeFilterModal();
    },

    showFilterModal() {
      this.filtersModalKey++;
      this.filtersModalVisible = true;
    },
    closeFilterModal() {
      this.filtersModalVisible = false;
    },

    resetFilters() {
      this.filters = {...filtersBaseState};

      this.dayFrom = undefined;
      this.dayTo = undefined;
    },

    authsTourBeforeStartCallback() {
      return new Promise((resolve, reject) => {
        const checkCondition = () => {
          if (this.tableData && this.tableData.length > 0 && !this.loading) {
            resolve();
          } else {
            setTimeout(checkCondition, 100);
          }
        };
        checkCondition();
      });
    },
    beforeShiftTableStepCallback() {
      const firstRow = document.querySelector('tbody tr');
      if (firstRow) {
        const buttonDiv = firstRow.querySelector('.ant-table-row-expand-icon');

        if (buttonDiv) {
          buttonDiv.click();
        }
      }
    },
    beforeAuthsWindowStepCallback() {
      return new Promise((resolve) => {
        this.showShiftAuthorizations(this.tableData[0]);
        setTimeout(() => {
          resolve()
        }, 500);
      })
    },
    beforeEditShiftButtonStepCallback() {
      return new Promise((resolve) => {
        this.closeCurrentShiftAuthsModalWithListClean();
        setTimeout(() => {
          resolve()
        }, 500);
      })
    },

    getAuthProviders(record) {
      return record.authorizations
        .filter(auth => auth.provider !== null)
        .map(auth => `${auth.provider.first_name} ${auth.provider.last_name}`);
    },
    getConfirmationStatus(record) {
      return record.authorizations.find(auth => auth.confirmation != null);
    },
    convertConfirmationStatus(confirmation) {
      return Util.capitalize(confirmation.status);
    },
    getConfirmationStatusColor(confirmation) {
      switch (confirmation.status) {
        case 'confirmed':
          return statusTagColors.GREEN;
        case 'declined':
          return statusTagColors.RED;
        default:
          return statusTagColors.GRAY;
      }
    },

    async getDeclineReasons() {
      if (!this.currentShiftRecord) {
        return null;
      }

      const associate = this.currentShiftRecord.associate;
      const shift = this.currentShiftRecord.shift;

      const estimatedLunchTimes = await this.apiGetEstimatedLunches(
        associate.associate_number,
        shift.scheduled_start,
        shift.scheduled_end,
        shift.lunch_waived
      ).then((response) => response.data);

      const date = moment(shift.scheduled_start).format(this.dateFormat);

      const scheduledStartMoment = moment(shift.scheduled_start);
      const scheduledEndMoment = moment(shift.scheduled_end);
      const scheduledStart = scheduledStartMoment.format(this.timeFormat);
      const scheduledEnd = scheduledEndMoment.format(this.timeFormat);

      const signedStartMoment = moment(shift.signed_start);
      const signedEndMoment = moment(shift.signed_end);
      const signedStart = signedStartMoment.format(this.timeFormat);
      const signedEnd = signedEndMoment.format(this.timeFormat);

      const lunchEndMoment = moment(shift.lunch_end);
      const estimatedLunchStartMoment = moment(estimatedLunchTimes.estimated_lunch_start);
      const estimatedLunchEndMoment = moment(estimatedLunchTimes.estimated_lunch_end);

      const secondLunchEndMoment = moment(shift.second_lunch_end);
      const estimatedSecondLunchStartMoment = estimatedLunchTimes.estimated_second_lunch_start ? moment(estimatedLunchTimes.estimated_second_lunch_start) : undefined;
      const estimatedSecondLunchEndMoment = estimatedLunchTimes.estimated_second_lunch_end ? moment(estimatedLunchTimes.estimated_second_lunch_end) : undefined;

      const startDifference = Util.formatMinutesToHoursAndMinutesDuration(Math.abs(scheduledStartMoment.diff(signedStartMoment, 'minutes')));
      const endDifference = Util.formatMinutesToHoursAndMinutesDuration(Math.abs(scheduledEndMoment.diff(signedEndMoment, 'minutes')));
      const lunchEndDifference = Util.formatMinutesToHoursAndMinutesDuration(Math.abs(lunchEndMoment.diff(estimatedLunchEndMoment, 'minutes')));
      const secondLunchEndDifference = Util.formatMinutesToHoursAndMinutesDuration(Math.abs(secondLunchEndMoment.diff(estimatedSecondLunchEndMoment, 'minutes')));

      const firstLunchDuration = estimatedLunchStartMoment && estimatedLunchEndMoment ?
        Util.formatMinutesToHoursAndMinutesDuration(estimatedLunchEndMoment.diff(estimatedLunchStartMoment, 'minutes'))
        : undefined;
      const secondLunchDuration = estimatedSecondLunchStartMoment && estimatedSecondLunchEndMoment ?
        Util.formatMinutesToHoursAndMinutesDuration(estimatedSecondLunchEndMoment.diff(estimatedSecondLunchStartMoment, 'minutes'))
        : undefined;
      const scheduledFirstLunchPeriod = estimatedLunchStartMoment && estimatedLunchEndMoment ?
        estimatedLunchStartMoment.format(this.timeFormat) + ' - ' + estimatedLunchEndMoment.format(this.timeFormat)
        : undefined;
      const scheduledSecondLunchPeriod = estimatedSecondLunchStartMoment && estimatedSecondLunchEndMoment ?
        estimatedSecondLunchStartMoment.format(this.timeFormat) + ' - ' + estimatedSecondLunchEndMoment.format(this.timeFormat)
        : undefined;

      const signedFirstLunchEnd = lunchEndMoment.format(this.timeFormat);
      const signedSecondLunchEnd = secondLunchEndMoment.format(this.timeFormat);

      return {
        overtime_authorization: 'You do not have authorization to work overtime. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.',
        late_start_authorization: `On ${date}, your scheduled start was ${scheduledStart}, but your signed start is ${signedStart}. It's ${startDifference} later! You shouldn't sign shifts with late start unless you have authorization. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
        no_lunch_authorization: `On ${date}, you had scheduled ${firstLunchDuration ?? ''} unpaid meal break ${scheduledFirstLunchPeriod ?? ''}. You do not have authorization to not take the required meal break. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
        no_second_lunch_authorization: `On ${date}, you had two scheduled ${secondLunchDuration ?? ''} unpaid meal breaks ${scheduledSecondLunchPeriod ?? ''}. You do not have authorization to not take the required two meal breaks. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
        late_end_authorization: `On ${date}, your scheduled end was ${scheduledEnd}, but your signed end is ${signedEnd}. It's ${endDifference} later! You shouldn't sign shifts with a late end unless you have authorization. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
        early_start_authorization: `On ${date}, your scheduled start was ${scheduledStart}, but your signed start is ${signedStart}. It's ${startDifference} earlier! You shouldn't sign shifts with early start unless you have authorization. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
        outside_work_area_authorization: 'You do not have authorization to work and track activities outside of the assigned work area. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.',
        absence_on_shift_authorization: 'You do not have authorization to skip your scheduled shifts. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.',
        early_lunch_end_authorization: `On ${date}, you had scheduled ${firstLunchDuration ?? ''} unpaid meal break ${scheduledFirstLunchPeriod ?? ''}. Your signed meal break end is ${signedFirstLunchEnd}. It's ${lunchEndDifference} earlier! You do not have authorization to take a meal break shorter than ${firstLunchDuration ?? '30m'}. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
        early_second_lunch_end_authorization: `On ${date}, you had scheduled ${secondLunchDuration ?? ''} unpaid second meal break ${scheduledSecondLunchPeriod ?? ''}. Your signed second meal break end is ${signedSecondLunchEnd}. It's ${secondLunchEndDifference} earlier! You do not have authorization to take a second meal break shorter than ${secondLunchDuration ?? '30m'}. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
        early_end_authorization: `On ${date}, your scheduled end was ${scheduledEnd}, but your signed end is ${signedEnd}. It's ${endDifference} earlier! You shouldn't sign shifts with an early end unless you have authorization. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.`,
        manually_shift_authorization: 'You must not submit manually added shifts unless they are confirmed to have actually been worked. Manually adding shifts that did not occur violates company policies and may lead to disciplinary action.',
        signed_actual_time_discrepancy: 'Mismatch between your signed and tracked shift times violates company policy. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.',
        edit_shift_authorization: 'You do not have authorization to edit shifts unless approved. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.',
        shift_info: 'Your shift information should be accurate and reflect actual work hours. Incorrect shift data may lead to disciplinary actions.',
        employee_issue: 'Your actions during shifts are being monitored for compliance. Violations may result in removal of shifts from your schedule and / or disciplinary actions.',
        lunch_period_authorization: 'You must take the authorized lunch period as scheduled. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.',
        no_rest_break_authorization: 'Not taking scheduled rest breaks violates company policy. Continued non-compliance may result in removal of all your shifts from the schedule and / or disciplinary actions.'
      }
    }
  },

  mounted() {
    this.loadData();

    const passed = localStorage.getItem('authsTourPassed');
    if (!passed) {
      this.$tours['authsTour'].start();
    }

    this.shiftAuthTypes = this.$store.state.applicationState.shiftAuthTypes;

    const localPagination = localStorage.getItem('shiftAuthsPagination');
    this.pagination = localPagination ? JSON.parse(localPagination) : initialPagination;
  }
}
</script>

<style scoped>
.table_predefined_filters {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  flex-shrink: 1;
  align-items: center;
  justify-content: end;
}

.auth-tag {
  margin-top: 3px;
}
</style>
