<template>
  <a-modal
    title="Shift Overlap Detected"
    v-model="visible"
    :closable="false"
  >
    <div class="already-existing-shift-modal">
      <div>
        <span>
          This shift overlaps with an existing one.
          Please edit or delete the overlapping shifts, or adjust the timing of the new shift.
        </span>
      </div>
      <div>
        <a-table
          size="small"
          :columns="overlappedShiftsColumns"
          :data-source="overlappingShifts"
          :pagination="false"
        >
          <span slot="start" slot-scope="text, record">
            {{ formatOverlappingShiftsDateTime(record.start) }}
          </span>
          <span slot="end" slot-scope="text, record">
            {{ formatOverlappingShiftsDateTime(record.end) }}
          </span>
          <span slot="actions" slot-scope="text, record">
            <a-button type="primary" @click="editExistingShift(record.id)">Edit</a-button>
          </span>
        </a-table>
      </div>
    </div>
    <template slot="footer">
      <a-button @click="editNewShift">Edit New Shift</a-button>
    </template>
  </a-modal>
</template>

<script>
import Util from "@/util";

export default {
  data() {
    return {
      overlappedShiftsColumns: [
        {
          title: 'ID',
          dataIndex: 'id'
        },
        {
          title: 'Start',
          scopedSlots: {customRender: 'start'}
        },
        {
          title: 'End',
          scopedSlots: {customRender: 'end'}
        },
        {
          title: 'Actions',
          scopedSlots: {customRender: 'actions'}
        },
      ],
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    overlappingShifts: {
      type: Array,
      required: false
    }
  },
  methods: {
    formatOverlappingShiftsDateTime(utcString) {
      return Util.convertDateTimeToCurrentTimeZoneFormattedString(utcString);
    },
    editExistingShift(id) {
      this.$emit('edit-existing-shift', id);
    },
    editNewShift() {
      this.$emit('edit-new-shift');
    }
  }
};
</script>

<style scoped>
.already-existing-shift-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
